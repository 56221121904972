import Axios from 'axios';
import { getToken } from '../safeToken';

import endpoints from '../../config/endpoints';

const Deposit = Axios.create({
  baseURL: endpoints.wallet,
});

Deposit.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export function RequestAddress({ currency }) {
  return Deposit.post('/web/v1/client/address', { currency });
}

export function RequestDeposits({ page, per_page, query }) {
  return Deposit.post('/web/v1/client/deposits', { page, per_page, query });
}

export function CreateNewAddress({ currency }) {
  return Deposit.post('/web/v1/client/address', { currency });
}
