import styled from 'styled-components';

export const Container = styled.div`
  width:100%;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;

  & .card-not-found{
    width:250px;
    height:250px;
    background-color:${({ theme }) => theme.content_bg_color};
    justify-content:space-between;
    padding:10px;
    ${({ theme }) => theme.shadow};
  }

  & svg{
    width:80px;
    height:20px;
    align-self:center;
  }

  & h1{
    margin-top:10px;
    font-size:14px;
    align-self:center;
  }

  & button{
    width:120px;
    align-self:center;
  }
`;
