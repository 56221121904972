import Axios from 'axios';
import { getToken } from '../safeToken';

import endpoints from '../../config/endpoints';

const Withdraw = Axios.create({
  baseURL: endpoints.payment,
});

Withdraw.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export function RequestNewWithdraw({ amount, otp }) {
  return Withdraw.post('/client/withdraw', { amount, otp });
}
