import React from 'react';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import * as registerActions from '~/store/modules/register/actions';
import { Container } from './styles';
import { Input } from '~/styles/components/inputs';
import { Button } from '~/styles/components/buttons';
import StratumIcon from '~/assets/png/mazzera-logo.png';
import { LoadingAnimation } from '~/components/animation';

const changePasswordSchema = Yup.object().shape({
  username: Yup.string().required('required_field'),
  code: Yup.string().length(6, 'ERROR_INVALID_CODE').required('required_field'),
  new_password: Yup.string().min(6, 'ERROR_INVALID_CODE').required('required_field'),
});

export default function PageChangePassword({ match, history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { error, message, loading } = useSelector((state) => state.register);

  const formik = useFormik({
    validationSchema: changePasswordSchema,
    initialValues: {
      username: '',
      code: '',
      new_password: '',
    },
    initialErrors: {
      username: '-',
      code: '-',
      new_password: '-',
    },
    onSubmit: (values) => {
      dispatch(registerActions.requestChangePassword({ ...values }));
    },
  });

  function handleBackPress() {
    history.goBack();
  }

  if (loading) {
    return (
      <Container>
        <section>
          <Link to="/trade" className="link-to-book">
            <AiOutlineArrowLeft />
            {'      '}
            Go back to book
          </Link>
          <div className="container-card" style={{ justifyContent: 'center' }}>
            <LoadingAnimation width={100} height={100} />
          </div>
        </section>
      </Container>
    );
  }

  return (
    <Container>
      <section>
        <Link to="/trade" className="link-to-book">
          <AiOutlineArrowLeft />
          {'      '}
          Go back to book
        </Link>
        <div className="container-card">
          <header>
            <img src={StratumIcon} height="45" />
            <h1>
              {t('change_password')}
            </h1>
          </header>
          {!match.params.step && (
            <>
              <div className="container-input">
                <span>{t('put_your_user_id')}</span>
                <Input
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="error-message">
                  {' '}
                  {formik.touched.username && formik.errors.username && t(formik.errors.username)}
                </span>
              </div>
              <div className="container-input">
                <span>{t('put_your_code')}</span>
                <Input
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="error-message">
                  {' '}
                  {formik.touched.code && formik.errors.code && t(formik.errors.code)}
                </span>
              </div>
              <div className="container-input">
                <span>{t('put_your_new_password')}</span>
                <Input
                  name="new_password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="password"
                />
                <span className="error-message">
                  {' '}
                  {formik.touched.new_password && formik.errors.new_password && t(formik.errors.new_password)}
                </span>
              </div>
              <Button type="submit" className="btn btn-next" onClick={formik.handleSubmit}>
                {t('send')}
              </Button>
            </>
          )}
          {match.params.step === 'message' && (
            <>
              <div className="container-success">
                <FaCheckCircle />
              </div>
              <h2 style={{ textAlign: 'center' }}>{t(message)}</h2>
            </>
          )}
          {match.params.step === 'error' && (
            <>
              <div className="container-success">
                <FaTimesCircle className="svg-error" />
              </div>
              <h2>{t(error)}</h2>
            </>
          )}
          <div className="container-step">
            <button type="button" onClick={handleBackPress}>
              <AiOutlineArrowLeft style={{ marginTop: -15 }} />
            </button>
          </div>
        </div>
      </section>
    </Container>
  );
}

PageChangePassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
      code: PropTypes.string,
    }),
  }).isRequired,
};
