import produce from 'immer';

const INITIAL_STATE = {
  loadingHistory: false,
  loadingTotal: false,
  history: {
    data: [],
    page: 1,
    pages: 0,
    total: 0,
  },
  total: 0,
};

export default function deposit(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@swap/setInfo':
      return produce(state, (draft) => {
        draft[action.payload.key] = action.payload.value;
      });
    default:
      return state;
  }
}
