import produce from 'immer';

const INITIAL_STATE = {
  history: null,
  symbolConfig: null,
};

export default function wallet(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@tradingView/setSymbolConfig':
      return produce(state, (draft) => {
        draft.symbolConfig = action.payload.symbolConfig;
      });
    case '@tradingView/setHistory':
      return produce(state, (draft) => {
        draft.history = action.payload.history;
      });
    default:
      return state;
  }
}
