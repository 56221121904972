import styled from 'styled-components';

export const Container = styled.section`
  min-height: 500px;
  max-height: 500px;
  width: 100%;
  background-color: ${({ theme }) => theme.content_bg_color};
  padding: 10px;
  padding-bottom:20!important;
  overflow:auto;

  & header {
    margin-bottom: 10px;
  }
  & header h1 {
    font-size: 15px;
  }

  & .history-item.header-history {
    background-color: ${({ theme }) => theme.content_bg_color};
    font-weight: bold;
    cursor: unset;
    font-size: 14px;
  }

  & .history-item {
    /* cursor: pointer; */
    min-height: 30px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.default_bg_color};
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
  }
  & .history-item p {
    width: 20%;
    text-align: center;
    justify-content: flex-end;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* & .history-item p:nth-child(1) {
    text-align: left;
    margin-left: 5px;
  }
  & .history-item p:nth-child(5) {
    text-align: right;
    margin-right: 5px;
  } */

  /* & .history-item:hover {
    background-color: ${({ theme }) => theme.content_selected_bg_color};
  }
  & .history-item:hover.header-history {
    background-color: ${({ theme }) => theme.content_bg_color};
  } */

  & p.history_tolltip{
    text-decoration:underline;
    cursor:pointer;
    color: #1BAFFF;
    overflow:show !important;
    z-index:20;
  }
  & .history_tolltip:hover span{
    display:block;
    position:absolute;
    top:10px;
    border-radius:10px;
    background-color:#1BAFFF;
    color:#fff;
    padding:5px;
    min-height:30px;
  }
  & .history_tolltip span{
    max-width:150px;
    display:none;
  }

`;
