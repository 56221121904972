import { put, takeLatest, all, takeEvery, call, delay } from 'redux-saga/effects';
import * as withdrawActions from './actions';
import { send } from '../socket/actions';
import { commands } from '../../../config/environments';
import { ToastSuccess } from '~/utils/toast';
import * as WithdrawService from '~/services/api/withdraw.api';
import * as WithdrawRasService from '~/services/api/withdrawRas.api';
import history from '~/services/history';

function* requestWithdrawHistory(action) {
  try {
    const { currency } = action.payload;
    const query = {
      currency,
    };
    const { data } = yield call(WithdrawService.RequestWithdeawHistory, {
      page: 1,
      per_page: 100,
      query,
    });
    return yield put(withdrawActions.responseWithdrawHistory({
      success: true,
      ...data,
      history: data.data,
      error: '',
    }));
  } catch (error) {
    return yield put(withdrawActions.responseWithdrawHistory({
      success: false,
      history: [],
      error: error?.response?.data?.message,
    }));
  }
}

function* requestNewWithdraw(action) {
  try {
    const { currency, amount, otp, address } = action.payload;
    const { data } = yield call(WithdrawService.RequestNewWithdraw, { currency, amount, otp, address });
    yield put(withdrawActions.requestWithdrawHistory({ currency }));
    yield put(withdrawActions.responseNewWithdraw({
      success: true,
      message: 'withdraw_check_your_email_message',
      error: '',
    }));
    yield delay(2500);
    yield put(withdrawActions.setRequestedWithdraw({ withdrawRequested: data }));
    history.push('/wallet/withdraw/confirm');
  } catch (error) {
    return yield put(withdrawActions.responseNewWithdraw({
      success: false,
      message: '',
      error: error?.response?.data?.message,
    }));
  }
}

function* requestNewWithdrawRas(action) {
  try {
    const { amount, otp, callback } = action.payload;
    const { data } = yield call(WithdrawRasService.RequestNewWithdraw, { amount, otp });
    yield put(withdrawActions.requestWithdrawHistory({ currency: 'RAS' }));
    yield put(withdrawActions.responseNewWithdraw({
      success: true,
      message: '',
      error: '',
    }));
    if (callback) {
      callback(data.link);
    }
  } catch (error) {
    return yield put(withdrawActions.responseNewWithdraw({
      success: false,
      message: '',
      error: error?.response?.data?.message,
    }));
  }
}

function* requestConfirmOrCancelWithdraw(action) {
  try {
    const { confirm, withdraw_id, code } = action.payload;
    const request = confirm ? WithdrawService.ConfirmWithdraw : WithdrawService.CancelWithdraw;
    yield call(request, { withdraw_id, code });
    return yield put(withdrawActions.responseConfirmOrCancelWithdraw({
      success: true,
      message: confirm ? 'withdraw_approve_success' : 'withdraw_canceled_success',
      error: '',
    }));
  } catch (error) {
    return yield put(withdrawActions.responseConfirmOrCancelWithdraw({
      success: false,
      message: '',
      error: error?.response?.data?.message,
    }));
  }
}

export function* withdrawGetInfoToConfirm(action) {
  const command = commands.GET_WITHDRAW;
  const { code } = action.payload;
  yield put(send({ data: code, cmd: command }));
  // while (true) {
  //   const { payload } = yield take('@socket/response');
  //   if (payload.cmd === commands.GET_WITHDRAW) {
  //     const { data, success } = payload.data;
  //     if (success) {
  //       yield put(withdrawActions.setRequestedWithdraw({ withdrawRequested: JSON.parse(data) }));
  //     }
  //   }
  // }
}

// data: "{"Address":"NCYTV3UMZKBCIXXWGQPPH2QMSPL6L4FX67IGIP2J_13869478","Currency":"BLU","Code":"158277","Amount":1000}"
export function* withdrawCreate(action) {
  const command = commands.WITHDRAWAL_CREATE;
  const dataToSend = {
    Address: action.payload.address,
    Currency: action.payload.currency,
    Code: action.payload.code,
    Amount: Math.round(action.payload.amount * 100000000),
  };
  yield put(send({ data: dataToSend, cmd: command }));

  // while (true) {
  //   const { payload } = yield take('@socket/response');
  //   if (payload.cmd === commands.WITHDRAWAL_CREATE) {
  //     const { data, success } = payload.data;
  //     if (success) {
  //       yield put(withdrawActions.setMessage({ message: 'withdraw_check_your_email_message' }));
  //     } else {
  //       yield put(withdrawActions.setError({ error: data }));
  //     }
  //   }
  // }
}

export function* withdrawConfirm(action) {
  const command = commands.CONFIRM_WITHDRAW;
  const { code } = action.payload;
  yield put(send({ data: code, cmd: command }));

  // while (true) {
  //   const { payload } = yield take('@socket/response');
  //   if (payload.cmd === commands.CONFIRM_WITHDRAW) {
  //     const { data, success } = payload.data;
  //     if (success) {
  //       yield put(withdrawActions.setMessage({ message: 'withdraw_approve_success' }));
  //     } else {
  //       yield put(withdrawActions.setError({ error: data }));
  //     }
  //   }
  // }
}

export function* withdrawCancel(action) {
  const command = commands.CANCEL_WITHDRAW;
  const { code } = action.payload;
  yield put(send({ data: code, cmd: command }));
}

// export function* withdrawResponse(action) {
//   const { data } = action.payload;
//   // OK
//   if (data && data.success) {
//     if (data.cmd === commands.WITHDRAWAL) {
//       const history = JSON.parse(data.data);
//       yield put(withdrawActions.setHistory({ history }));
//     }
//     if (data.cmd === commands.WITHDRAWAL_CREATE) {
//       return ToastSuccess('withdraw success');
//     }
//   }

//   // ERRORS
//   if (data && !data.success) {
//     yield put(withdrawActions.setError({ error: data.data }));
//   }
// }

function* listenSocketResponse(action) {
  const { cmd, data, success } = action.payload.data;

  if (cmd === commands.WITHDRAWAL) {
    if (success) {
      const parsedHistory = JSON.parse(data);
      yield put(withdrawActions.setHistory({ history: parsedHistory }));
    } else {
      yield put(withdrawActions.setError({ error: data }));
    }
  }

  if (cmd === commands.WITHDRAWAL_CREATE) {
    if (success) {
      ToastSuccess('withdraw success');
      yield put(withdrawActions.setMessage({ message: 'withdraw_check_your_email_message' }));
    }
  }

  if (cmd === commands.CONFIRM_WITHDRAW) {
    if (success) {
      yield put(withdrawActions.setMessage({ message: 'withdraw_approve_success' }));
    } else {
      yield put(withdrawActions.setError({ error: data }));
    }
  }

  if (cmd === commands.WITHDRAWAL_CREATE) {
    if (success) {
      yield put(withdrawActions.setMessage({ message: 'withdraw_check_your_email_message' }));
    } else {
      yield put(withdrawActions.setError({ error: data }));
    }
  }

  if (cmd === commands.GET_WITHDRAW) {
    if (success) {
      yield put(withdrawActions.setRequestedWithdraw({ withdrawRequested: JSON.parse(data) }));
    }
  }
}
export default all([
  takeEvery('@socket/response', listenSocketResponse),
  takeLatest('@withdraw/requestWithdrawHistory', requestWithdrawHistory),
  takeLatest('@withdraw/requestNewWithdraw', requestNewWithdraw),
  takeLatest('@withdraw/requestNewWithdrawRas', requestNewWithdrawRas),
  takeLatest('@withdraw/requestConfirmOrCancelWithdraw', requestConfirmOrCancelWithdraw),
  takeLatest('@withdraw/withdrawCreate', withdrawCreate),
  takeLatest('@withdraw/withdrawConfirm', withdrawConfirm),
  takeLatest('@withdraw/withdrawCancel', withdrawCancel),
  takeLatest('@withdraw/withdrawGetInfoToConfirm', withdrawGetInfoToConfirm),
]);
