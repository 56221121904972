import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as WithdrawActions from '~/store/modules/withdraw/actions';
import { Input } from '~/styles/components/inputs';

import { LoadingAnimation } from '~/components/animation';
import { Svg } from '~/components/iconSet';

import { Container, LightButton } from './styles';

import { ReactComponent as WithdrawIcon } from '~/assets/svg/withdraw.svg';
/**
 * withdraw request
 * cmd: 16
 * data: "{"Address":"NCYTV3UMZKBCIXXWGQPPH2QMSPL6L4FX67IGIP2J_13869478","Currency":"BLU","Code":"000192","Amount":1000}"
 *
 * confirm withdraw
 * cmd: 22
 * data: "5a59ac796fc19ce41d51f23f81feaccedbbdf32eeaf5056e66beaa6cb9b1280b66328c9609c806c1a1d8dd1013601de6caa294408c05155c79fe9e6c97b7fcbfacafe94f1ebcb3c3a2226c06"
 * url: https://app.mazzera.trade/#/wallet/withdraw?data=5a59ac796fc19ce41d51f23f81feaccedbbdf32eeaf5056e66beaa6cb9b1280b66328c9609c806c1a1d8dd1013601de6caa294408c05155c79fe9e6c97b7fcbfacafe94f1ebcb3c3a2226c06
 */

function ConfirmWithdrawComponent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [mailCode, setMailCode] = useState('');
  const { t } = useTranslation();
  // const [loading, setLoading] = useState(true);
  const { loadingConfirm, errorConfirm, messageConfirm, withdrawRequested } = useSelector((state) => state.withdraw);

  useEffect(() => () => {
    dispatch(WithdrawActions.clearMessages());
  }, [dispatch]);

  useEffect(() => {
    if (!withdrawRequested) {
      history.push('/wallet');
    }
  }, [history, withdrawRequested]);

  function handleConfirmOrCancel(confirm) {
    if (!mailCode && confirm) return;
    const withdraw_id = withdrawRequested.id;
    dispatch(WithdrawActions.requestConfirmOrCancelWithdraw({ confirm, withdraw_id, code: mailCode }));
  }

  return (
    <Container title="withdraw">
      <div className="flag_top_left">
        <WithdrawIcon width={30} />
      </div>
      <div className="header_qrcode">
        <Svg name={withdrawRequested?.currency} size={40} />
        <div>
          <h1>{withdrawRequested?.currency}</h1>
          <h4>{t('withdraw')}</h4>
        </div>
      </div>
      <div className="container-info-withdraw">
        {(!withdrawRequested || loadingConfirm) && <LoadingAnimation width={100} height={100} />}
        {withdrawRequested && !loadingConfirm && (
          <>
            <div className="withdraw_amount">
              <h2>
                {t('amount')}
              </h2>
              <h1>
                {withdrawRequested?.amount}
              </h1>
            </div>
            {/* <div className="withdraw_amount">
              <h2>
                {t('address')}
              </h2>
              <h1>
                {withdrawRequested?.address}
              </h1>
            </div> */}
            {!messageConfirm && (
            <div>
              <span style={{ marginBottom: 10 }}>{t('code_mail')}</span>
              <Input name="input2fa" value={mailCode} onChange={({ target }) => setMailCode(target.value)} />
              <span className="error-message">
                {t(errorConfirm)}
              </span>
            </div>
            )}
            {messageConfirm ? (
              <div className="withdraw-message">{t(messageConfirm)}</div>
            ) : (
              <div className="confirm_withdraw_buttons">
                <LightButton onClick={() => handleConfirmOrCancel(false)}>
                  {t('cancel')}
                </LightButton>
                <LightButton onClick={() => handleConfirmOrCancel(true)} approve>
                  {t('approve')}
                </LightButton>
              </div>
            )}

            <div className="withdraw_container_info">
              <Trans i18nKey="withdraw_ras_note" />
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

export default ConfirmWithdrawComponent;
