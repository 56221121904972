let currentAuthToken = '';

export function setToken(token) {
  currentAuthToken = token;
}

export function getToken() {
  return currentAuthToken;
}

// Middleware do redux obtem o token, é necessário para prevenir erro de ciclo de vida
export const middlewareToken = (store) => (next) => (action) => {
  const token = store.getState()?.auth?.token;
  setToken(token);
  return next(action);
};
