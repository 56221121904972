import { put, delay, all, takeLatest, call } from 'redux-saga/effects';
import md5 from 'blueimp-md5';
import * as authActions from './actions';
import history from '~/services/history';
import * as AuthService from '../../../services/api/auth.api';
import { ToastSuccess } from '~/utils/toast';

function* requestLogin(action) {
  try {
    const { nickname, password } = action.payload;
    const { data } = yield call(AuthService.Login, { username: nickname, password: md5(password) });

    if (data.status === 'wait-2fa') {
      history.push('/signin/2fa');
    }

    if (data.status === 'confirm-account') {
      history.push('/validateemail');
    }

    yield put(authActions.responseLogin({ ...data, success: true }));

    if (data.status === 'confirm-2fa') {
      yield put(authActions.requestSecret());
      history.push('/signin/2fa');
    }
  } catch (error) {
    history.push('/signin/error');
    yield put(authActions.responseLogin({ success: false, error: error?.response?.data?.message }));
  }
}

function* requestSecret() {
  try {
    const { data } = yield call(AuthService.RequestSecret);
    yield put(authActions.responseSecret({ ...data, success: true }));
  } catch (error) {
    yield put(authActions.responseSecret({
      success: false,
      error: error?.response?.data?.message,
    }));
  }
}

function* requestCheck2fa(action) {
  try {
    const { code, confirmation } = action.payload;
    const request = confirmation ? AuthService.ConfirmSecretCode : AuthService.ConfirmOtpLogin;
    const { data } = yield call(request, { code });
    yield put(authActions.responseCheck2fa({
      ...data,
      message: 'login_successfully_redirecting',
      success: true,
    }));
    history.push('/signin/message');
    yield delay(5000);
    return history.replace('/trade');
  } catch (error) {
    history.push('/signin/error');
    return yield put(authActions.responseCheck2fa({
      success: false,
      error: error?.response?.data?.message,
    }));
  }
}

function* requestRecovery(action) {
  try {
    const { type, email, userId, onlyToastMessage } = action.payload;
    const serviceName = type === 'password' ? 'RecoveryPassword' : 'RecoveryUserName';
    yield call(AuthService[serviceName], { email, username: userId });
    yield put(authActions.responseRecovery({ success: true, message: 'we_send_email_for_you' }));
    if (!onlyToastMessage) {
      history.push('/signin/message');
      yield delay(3000);
      history.push('/newpassword');
    } else {
      ToastSuccess('we_send_email_for_you');
      yield delay(3000);
      history.push('/newpassword');
    }
  } catch (error) {
    return yield put(authActions.responseRecovery({
      success: false,
      error: error?.response?.data?.message,
    }));
  }
}

function* cleanUnecessary() {
  yield put(authActions.cleanUnecessary());
  localStorage.removeItem('persist:stratum');
}

export default all([
  call(cleanUnecessary),
  takeLatest('@auth/requestLogin', requestLogin),
  takeLatest('@auth/requestCheck2fa', requestCheck2fa),
  takeLatest('@auth/requestSecret', requestSecret),
  takeLatest('@auth/requestRecovery', requestRecovery),
]);
