import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  BookWallet,
  BookPriceComponent,
  BookSelectCurrency,
} from '~/components/book';

import { Container } from './styles';
import * as BookActions from '~/store/modules/book/actions';

export default function PageBook() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(BookActions.requestBook());
  }, [dispatch]);

  return (
    <Container name="book" className="container-book">
      <div className="container-header-informations">
        <div className="hide-when-larger">
          <BookWallet />
        </div>
        <div className="container-price-currency">
          <div className="show-when-larger">
            <BookWallet />
          </div>
          <BookSelectCurrency />
          <BookPriceComponent />
        </div>
      </div>
    </Container>
  );
}
