import styled from 'styled-components';

export const Container = styled.div`
  width:100%;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;

  & .card-not-found{
    justify-content: center;
    align-items: center;
    width:250px;
    height:250px;
    background-color:${({ theme }) => theme.content_bg_color};
    padding:10px;
    ${({ theme }) => theme.shadow};
  }

  & svg{
    width:80px;
    height:20px;
    align-self:center;
  }

  & h1{
    margin-top:50px;
    font-size: 22px;
    text-align: center;
  }

  & button{
    width:120px;
    align-self:center;
  }
`;
