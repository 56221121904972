export function requestRegister({ name, email, phone, password }) {
  return { type: '@register/requestRegister', payload: { name, email, phone, password } };
}

export function responseRegister({ success, message, error }) {
  return { type: '@register/responseRegister', payload: { success, message, error } };
}

export function requestEmailValidation({ code }) {
  return { type: '@register/requestEmailValidation', payload: { code } };
}

export function responseEmailValidation({ success, message, error }) {
  return { type: '@register/responseEmailValidation', payload: { success, message, error } };
}

export function requestChangePassword({ username, code, new_password }) {
  return { type: '@register/requestChangePassword', payload: { username, code, new_password } };
}

export function responseChangePassword({ password, code }) {
  return { type: '@register/responseChangePassword', payload: { password, code } };
}

export function registerResponse(data) {
  return { type: '@register/registerResponse', payload: { data } };
}

export function setError(error) {
  return { type: '@register/setError', payload: { error } };
}

export function setMessage(message) {
  return { type: '@register/setMessage', payload: { message } };
}

export function change({ property, value }) {
  return { type: '@register/change', payload: { property, value } };
}
