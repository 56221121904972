import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';
import { dateFormat } from '~/utils/format';
import * as TradeActions from '~/store/modules/trade/actions';
import { LoadingAnimation } from '~/components/animation';
import ReactTooltip from 'react-tooltip';

export default function BookTradesComponent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { trades, loading } = useSelector((state) => state.trade);
  const { baseCurrency } = useSelector((state) => state.product);

  useEffect(() => {
    if (baseCurrency?.id) {
      dispatch(TradeActions.requestTrades({ pairId: baseCurrency.id }));
    }
  }, [baseCurrency, dispatch]);

  if (loading) {
    return (
      <Container className="bg-content menu-bg">
        <h1>
        {t('trades')}
          <span data-tip data-for='tradeDecription'> (?) </span>
          <ReactTooltip id='tradeDecription'>
            <span>{t('trades_description')}</span>
          </ReactTooltip>
        </h1>
        <div style={{ height: '100%', justifyContent: 'center' }}>
          <LoadingAnimation width={100} height={100} />
        </div>
      </Container>
    );
  }

  return (
    <Container className="bg-content">
      <h1>
        {t('trades')}
          <span data-tip data-for='tradeDecription'> (?) </span>
          <ReactTooltip id='tradeDecription'>
            <span>{t('trades_description')}</span>
          </ReactTooltip>
        </h1>
      {trades?.map((trade) => (
        <div key={trade.tradeId} className="trade-item">
          <p className={` ${trade.side === 'buy' ? 'cl-buy' : 'cl-sell'}`}>{parseFloat(trade.price).toFixed(baseCurrency.quoteScale)}</p>
          <p className="">{parseFloat(trade.size).toFixed(baseCurrency.baseScale)}</p>
          <p className="">
            {dateFormat({ date: trade.time, format: 'HH:mm:ss' })}
          </p>
        </div>
      ))}
    </Container>
  );
}
