import styled from 'styled-components';

export const Container = styled.section`

  & .container-videos{
    display:block;
    padding-bottom:50px;
  }

  & .videos-title{
    margin:20px;
  }

  & .container-horizontal-videos{
    flex-direction:row;
    margin-left:10px;
    overflow:auto;
    height:170px;
  }

  & .container-horizontal-videos img{
    width:100%;
    height:100%;
  }

  & .videos-card{
    overflow:hidden;
    width:250px;
    min-width:250px;
    height:150px;
    min-height:150px;
    justify-content:center;
    align-items:center;
    border-radius:10px;
    background-color:#000;
    cursor:pointer;
    opacity:0.9;
    margin-right:15px;
    transform: scale(0.9);
  }

  & .videos-card #play-icon-video{
    position:absolute;
    align-self:center;
    opacity:0;
  }

  & .videos-card:hover{
    transform: scale(1);
    ${({ theme }) => theme.shadow};
  }

  & .videos-card:hover #play-icon-video{
    opacity:1;
  }
`;

export const ContainerVideo = styled.div`
  position:fixed;
  z-index:9999999;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);

  & iframe{
    width:100vw;
    height:50vw;
  }

  @media(min-width:400px){
    iframe{
      width:80vw;
      height:35vw;
      margin-top:50px;
      margin-left:50px;
    }
  }
`;
