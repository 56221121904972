import React, { useState, useEffect } from 'react';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Qrcode from 'react-qr-code';

import * as authActions from '~/store/modules/auth/actions';
import { Container, Step } from './styles';
import { Input } from '~/styles/components/inputs';
import { Button } from '~/styles/components/buttons';
import StratumIcon from '~/assets/png/mazzera-logo.png';
import { LoadingAnimation } from '~/components/animation';

export default function PageSignin({ match, history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    error,
    message,
    loading,
    status,
    otp_url,
    token,
    step_register,
  } = useSelector((state) => state.auth);

  const [formValues, setFormValues] = useState({
    userId: '',
    password: '',
    input2fa: '',
    email: '',
  });

  useEffect(() => {
    if (!token) {
      return history.push('/signin/user');
    }
  }, [dispatch, token]);

  useEffect(() => {
    console.log(match.params.step)
    if (!match.params.step) {
      return history.push('/signin/user');
    }
  }, [history, error, message, match]);

  function handleBackPress() {
    history.goBack();
  }

  function handleRegister() {
    return history.push('/register');
  }

  function handleLogin() {
    const toSend = {
      nickname: formValues.userId,
      password: formValues.password,
    };
    return dispatch(authActions.requestLogin(toSend));
  }

  function handleCheck2fa() {
    if (!status) handleBackPress();
    if (step_register === 1) {
      return dispatch(authActions.requestCheck2fa({
        confirmation: true,
        code: formValues.input2fa,
      }));
    }
    return dispatch(authActions.requestCheck2fa({ code: formValues.input2fa }));
  }

  function handleRecovery({ type }) {
    const { email, userId } = formValues;
    const newValues = { ...formValues, email: '', userId: '' };
    setFormValues(newValues);
    return dispatch(authActions.requestRecovery({ type, email, userId }));
  }

  /**
   *  check input values and set into state
   */
  function handleChangeInput(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    const newvalues = { ...formValues };
    if (inputName === 'userId') {
      const test = new RegExp(/[\w\d,]+$/, 'g').test(inputValue);
      if (test || !inputValue) {
        newvalues[inputName] = inputValue;
      }
    }
    if (inputName === 'password') {
      newvalues[inputName] = inputValue;
    }
    if (inputName === 'input2fa') {
      const test = new RegExp(/[\d,]+$/, 'g').test(inputValue);
      if (test || !inputValue) {
        newvalues[inputName] = inputValue;
      }
    }
    if (inputName === 'email') {
      newvalues[inputName] = inputValue;
    }
    setFormValues(newvalues);
  }

  if (loading || !match.params.step) {
    return (
      <>
        <Container>
          <section className="section-container">
            <Link to="/trade" className="link-to-book">
              <AiOutlineArrowLeft />
              {'      '}
              {t('go_back_to_book')}
            </Link>
            <div className="container-card" style={{ justifyContent: 'center' }}>
              <LoadingAnimation width={100} height={100} />
            </div>
          </section>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <section className="section-container">
          <Link to="/trade" className="link-to-book">
            <AiOutlineArrowLeft />
            {'      '}
            {t('go_back_to_book')}
          </Link>
          <div className="container-card">
            <header>
              <img alt="stratumx icon" src={StratumIcon} height="45" />
              <h1>Login</h1>
            </header>
            {(match.params.step === 'recoveryid') && (
            <>
              <div className="container-input">
                <span>{t('put_your_email')}</span>
                <Input
                  name="email"
                  value={formValues.email}
                  onChange={handleChangeInput}
                />
                <span className="error-message">{error}</span>
              </div>
              <Button className="btn btn-next" onClick={() => handleRecovery({ type: 'nickname' })}>
                {t('recovery')}
              </Button>
            </>
            )}
            {(match.params.step === 'recoverypassword') && (
            <>
              <div className="container-input">
                <span>{t('put_your_user_id')}</span>
                <Input
                  name="userId"
                  value={formValues.userId}
                  onChange={handleChangeInput}
                />
                <span className="error-message">{error}</span>
              </div>
              <Button className="btn btn-next" onClick={() => handleRecovery({ type: 'password' })}>
                {t('recovery')}
              </Button>
            </>
            )}
            {(match.params.step === 'user' || !match.params.step) && (
            <>
              <div className="container-input">
                <span>{t('put_your_user_id')}</span>
                <Input
                  name="userId"
                  value={formValues.userId}
                  onChange={handleChangeInput}
                />
                <Link to="/signin/recoveryid">{t('forgot_user_id')}</Link>
              </div>
              <div className="container-input">
                <span>{t('put_your_password')}</span>
                <Input
                  type="password"
                  name="password"
                  onChange={handleChangeInput}
                />
                <Link to="/signin/recoverypassword">{t('forgot_password')}</Link>
                <span className="error-message">{error}</span>
              </div>
              <Button className="btn btn-next" onClick={handleLogin}>
                {t('next')}
              </Button>
              <Button className="btn" onClick={handleRegister}>{t('register')}</Button>
            </>
            )}
            {match.params.step === '2fa' && (
            <>
              <div className="container-input">
                {/* {status === 'confirm-2fa' && !!otp_url && ( */}
                {step_register === 1 && !!otp_url && (
                  <div className="container-signin-qrcode">
                    <Qrcode
                      size={150}
                      value={otp_url}
                    />
                  </div>
                )}
                <span style={{ marginBottom: 10 }}>{t('code_2fa')}</span>
                <Input name="input2fa" onChange={handleChangeInput} />
                <span style={{ marginTop: 10 }} className="error-message">
                  {t(error)}
                </span>
                <Button
                  className="btn btn-next"
                  style={{ alignSelf: 'center' }}
                  onClick={handleCheck2fa}
                >
                  {t('next')}
                </Button>
              </div>
            </>
            )}

            {match.params.step === 'message' && (
            <>
              <div className="container-success">
                <FaCheckCircle />
              </div>
              <h2>{t(message)}</h2>
            </>
            )}

            {match.params.step === 'error' && (
            <>
              <div className="container-success">
                <FaTimesCircle className="svg-error" />
              </div>
              <h2>{t(error)}</h2>
            </>
            )}

            <div className="container-step">
              {match.params.step !== 'user' && (
              <button type="button" onClick={handleBackPress}>
                <AiOutlineArrowLeft />
              </button>
              )}
              <Step
                selected={!match.params.step || match.params.step === 'user'}
              />
              <Step
                selected={match.params.step === '2fa'}
              />
              <Step
                selected={match.params.step === 'message'}
              />
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

PageSignin.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }).isRequired,
};
