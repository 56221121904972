import produce from 'immer';

const INITIAL_STATE = {
  history: [],
  orders: [],
  loadingOrders: false,
  loadingCreateOrder: false,
  loadingDeleteOrder: false,
  loadingDirectPrice: true,
  limit: 100,
  query: {
    status: 'open',
  },
  formValues: {
    price: '0',
    amount: '0',
    total: '0',
    current: 0, // 0: buy 1:sell
  },
  formValuesDirect: {
    quoteCurrency:"RAS",
    baseCurrency:"BTC",
    quoteCurrencyAmount:"0",
    baseCurrencyAmount:"0",
    current: 0, // 0: buy 1:sell
  },
  directPrice:{
    price:0,
    product:{
      BaseCurrency: "BTC",
      BaseScale: 5,
      Id: "BTC-RAS",
      QuoteCurrency: "RAS",
      QuoteScale: 2,
    },
  }
};

export default function order(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@order/setFormValues':
      return produce(state, (draft) => {
        const { price, amount, current } = action.payload;

        const total = parseFloat(price * amount);

        draft.formValues.total = Number.isNaN(total) ? 'invalid' : total;
        draft.formValues.price = price;
        draft.formValues.amount = amount;

        if (typeof current === 'number') {
          draft.formValues.current = current;
        }
      });
    case '@order/setFormValuesDirect':
    return produce(state, (draft) => {
      const {   quoteCurrencyAmount, baseCurrencyAmount, current, quoteCurrency, baseCurrency } = action.payload;
      draft.formValuesDirect.quoteCurrencyAmount = quoteCurrencyAmount
      draft.formValuesDirect.baseCurrencyAmount = baseCurrencyAmount
      draft.formValuesDirect.quoteCurrency = quoteCurrency
      draft.formValuesDirect.baseCurrency = baseCurrency
      if (typeof current === 'number') {
        draft.formValuesDirect.current = current;
      }
    });
    case '@order/updateQueryAndLimitOrder':
      return produce(state, (draft) => {
        draft.limit = action.payload.limit;
        draft.query = action.payload.query;
      });
    case '@order/requestCreateOrder':
      return produce(state, (draft) => {
        draft.loadingCreateOrder = true;
      });
    case '@order/responseCreateOrder':
      return produce(state, (draft) => {
        draft.loadingCreateOrder = false;
      });
    case '@order/requestOrders':
      return produce(state, (draft) => {
        draft.loadingOrders = true;
      });
    case '@order/responseOrders':
      return produce(state, (draft) => {
        draft.loadingOrders = false;
        draft.orders = action.payload.orders;
      });
    case '@order/requestDeleteOrder':
      return produce(state, (draft) => {
        draft.loadingDeleteOrder = true;
      });
    case '@order/responseDeleteOrder':
      return produce(state, (draft) => {
        draft.loadingDeleteOrder = false;
      });
      case '@order/requestDirectPrice':
        return produce(state, (draft) => {
          draft.loadingDirectPrice = true;
          draft.directPrice.price = 0;
        });
    case '@order/responseDirectPrice':
      return produce(state, (draft) => {
        draft.directPrice = action.payload.directPrice
        draft.loadingDirectPrice = false;
      });
    default:
      return state;
  }
}
