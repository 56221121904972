import './styles/colors.css';
// import './styles/global.css';
import './styles/common-classes.css';
import './config/i18n';

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { ToastNotification } from '~/components/notification';
import ThemeProvider from './styles/themeProvider';
import GlobalStyle from './styles/globalStyle';

import Routes from './routes';
import StoreData from '~/store';




import ModalWelcome from '~/components/modal/Welcome';
import Footer from '~/components/footers/Options';

function App() {
  const { store, persistor } = StoreData;
  return (
    <Provider store={store}>
      <GlobalStyle />
      {/* <ToastNotification /> */}
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <>
            <Routes />
            <ModalWelcome />
            <Footer />
          </>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
