import endpoints from './endpoints';

export const priceUrl = 'https://prices.stratum.bt/currency';
export const priceToken = '58221ca0b90e49c7b5e9ac3409ecf35a';

export const urlSocket = endpoints.socket;
export const baseUrlWithdrawPayment= 'https://payment.mazzera.trade/#/withdraw';

export const defaultWallets = {
  BLU: 'NCYTV3UMZKBCIXXWGQPPH2QMSPL6L4FX67IGIP2J',
  ZAR: 'r99sqXsRXFSkyBVinWSDUwMo2HjVs6V5Cw',
};

export const fullnames = {
  WBX: 'WBX | Wibx',
  DGB: 'DGB | DigiByte',
  DAI: 'DAI | Dai',
  BTC: 'BTC | Bitcoin',
  RAS: 'RAS | RAS',
  TUSD: 'TUSD | True USD',
  USDT: 'USDT | Tether',
  ZCR: 'ZCR | Zcore',
  ETH: 'ETH | Ethereum',
  BCH: 'BCH | BitCoin Cash',
  DASH: 'DASH | Dash',
  BLU: 'BLU | StratumBlue',
  ZAR :'ZAR'
};

export const explorer = {
  WBX: 'https://blockchair.com/ethereum/transaction',
  DGB: 'https://digibyteblockexplorer.com/tx',
  DAI: 'https://blockchair.com/ethereum/transaction',
  BTC: 'https://blockchair.com/bitcoin/transaction',
  RAS: 'https://blockchair.com/ethereum/transaction',
  TUSD: 'https://blockchair.com/ethereum/transaction',
  USDT: 'https://blockchair.com/ethereum/transaction',
  ZCR: 'http://explorer.zcore.cash/tx',
  ETH: 'https://blockchair.com/ethereum/transaction',
  BCH: 'https://blockchair.com/bitcoin-cash/transaction',
  DASH: 'https://blockchair.com/dash/transaction',
  BLU: '#',
  ZAR: 'https://blockchair.com/ripple/transaction'
};


export const fees = {
  WBX: 500,
  DGB: 0.1,
  DAI: 10,
  BTC: 0.0005,
  RAS: 5,
  TUSD: 10,
  USDT: 10,
  ZCR: 0.0003,
  ETH: 0.003,
  BCH: 0.0001,
  DASH: 0.01,
  BLU: 0,
};

export const CurrencyAviable = [
  {
    currency: 'WBX',
    source: 'coingecko',
  },
  {
    currency: 'DGB',
    source: '',
  },
  {
    currency: 'DAI',
    source: '',
  },
  {
    currency: 'ETH',
    source: '',
  },
  {
    currency: 'BTC',
    source: '',
  },
  {
    currency: 'BLU',
    source: '',
  },
  {
    currency: 'REALT',
    source: 'stratum',
  },
  {
    currency: 'BCH',
    source: '',
  },
  {
    currency: 'DASH',
    source: '',
  },
  {
    currency: 'ZCR',
    source: 'stratum',
  },
  {
    currency: 'RAS',
    source: 'coingecko',
  },
];

export const cryptos = [
  {
    Key: 'WBX',
  },
  {
    Key: 'DGB',
  },
  {
    Key: 'DAI',
  },
  {
    Key: 'BTC',
  },
  {
    Key: 'RAS',
  },
  {
    Key: 'TUSD',
  },
  {
    Key: 'USDT',
  },
  {
    Key: 'ZCR',
  },
  {
    Key: 'ETH',
  },
  {
    Key: 'BCH',
  },
  {
    Key: 'DASH',
  },
  {
    Key: 'BLU',
  },
];

export const maintenanceWallets = ['DGB', 'DAI'];

export const minInDolar = {
  TUSD: 10,
  USDT: 10,
  RAS: 10,
  BTC: 10,
  ETH: 10,
  BCH: 10,
  DASH: 2,
  WBX: 10,
  DGB: 5,
  DAI: 5,
};

export const currencies = [
  {
    name: 'BTC/RAS',
    code: 'BTC_RAS',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },

  {
    name: 'BTC/TUSD',
    code: 'BTC_TUSD',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'BTC/USDT',
    code: 'BTC_USDT',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },

  {
    name: 'ZCR/RAS',
    code: 'ZCR_RAS',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'ZCR/TUSD',
    code: 'ZCR_TUSD',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'ZCR/USDT',
    code: 'ZCR_USDT',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },

  {
    name: 'ETH/RAS',
    code: 'ETH_RAS',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },

  {
    name: 'ETH/TUSD',
    code: 'ETH_TUSD',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 10, // main currency decimal places -> BRL
  },
  {
    name: 'ETH/USDT',
    code: 'ETH_USDT',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 10, // main currency decimal places -> BRL
  },

  {
    name: 'BCH/RAS',
    code: 'BCH_RAS',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },

  {
    name: 'BCH/TUSD',
    code: 'BCH_TUSD',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 10, // main currency decimal places -> BRL
  },
  {
    name: 'BCH/USDT',
    code: 'BCH_USDT',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 10, // main currency decimal places -> BRL
  },

  {
    name: 'DASH/RAS',
    code: 'DASH_RAS',
    decimal_places: 12, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },

  {
    name: 'DASH/TUSD',
    code: 'DASH_TUSD',
    decimal_places: 12, // currency decimal places -> BTC
    main_decimal_places: 10, // main currency decimal places -> BRL
  },
  {
    name: 'DASH/USDT',
    code: 'DASH_USDT',
    decimal_places: 12, // currency decimal places -> BTC
    main_decimal_places: 10, // main currency decimal places -> BRL
  },
  {
    name: 'BLU/RAS',
    code: 'BLU_RAS',
    decimal_places: 8, // currency decimal places -> BLU
    main_decimal_places: 4, // main currency decimal places -> BRL
  },

  {
    name: 'BLU/TUSD',
    code: 'BLU_TUSD',
    decimal_places: 8, // currency decimal places -> BLU
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'BLU/USDT',
    code: 'BLU_USDT',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'WBX/RAS',
    code: 'WBX_RAS',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'WBX/TUSD',
    code: 'WBX_TUSD',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'WBX/USDT',
    code: 'WBX_USDT',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'DAI/RAS',
    code: 'DAI_RAS',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'DAI/TUSD',
    code: 'DAI_TUSD',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'DAI/USDT',
    code: 'DAI_USDT',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'DGB/RAS',
    code: 'DGB_RAS',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'DGB/TUSD',
    code: 'DGB_TUSD',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },
  {
    name: 'DGB/USDT',
    code: 'DGB_USDT',
    decimal_places: 8, // currency decimal places -> BTC
    main_decimal_places: 4, // main currency decimal places -> BRL
  },

  // {
  //   name: 'ETH/BTC',
  //   code: 'ETH_BTC',
  //   decimal_places: 8,
  //   main_decimal_places: 8
  // },

  // {
  //   name: 'DASH/BTC',
  //   code: 'DASH_BTC',
  //   decimal_places: 8,
  //   main_decimal_places: 8
  // },

  // {
  //   name: 'BCH/BTC',
  //   code: 'BCH_BTC',
  //   decimal_places: 8,
  //   main_decimal_places: 8
  // },
];

export const commands = {
  REGISTER: 1,
  LOGIN: 2,
  LOGOUT: 3,
  ORDER: 4,
  ORDER_CANCEL: 5,
  REQUEST_BOOK: 6,
  TRADE: 7,
  BOOK: 8,
  HEADERINFO: 9,
  BALANCE: 10,
  OPEN_ORDERS: 11,
  ORDER_AND_TRADE_HISTORY: 12,
  VALIDATE_ACCOUNT: 19,
  CHECK_SESSION: 23,
  CHECK_SESSION_BROAD: -2,

  // NAO DEFINIDOS
  CREATEWITHDRAWAL: 16,
  WITHDRAWAL: 17,
  WITHDRAWAL_CREATE: 16,
  DEPOSIT: 18,
  TWOFA: 0,
  CREATE_ADDRESS: 14,
  LIST_ADDRESS: 15,
  GET_SECRECT_2FA: 20,
  CONFIRM_2FA: 21,
  CONFIRM_LOGIN_2FA: 24,
  CONFIRM_WITHDRAW: 22,
  GET_WITHDRAW: 25,
  CANCEL_WITHDRAW: 26,

  // Auth
  FORGET_NICKNAME: 27,
  FORGET_PASSWORD: 28,
  CHANGE_PASSWORD: 29,
};

export const decimalsWallet = {
  TUSD: 2,
  USDT: 2,
  RAS: 4,
  WBX: 6,
  DGB: 6,
  DAI: 6,
  BTC: 8,
  ETH: 8,
  BCH: 8,
  DASH: 8,
  ZCR: 6,
  BLU: 6,
};

export const decimals = {
  TUSD: 2,
  USDT: 2,
  RAS: 2,
  WBX: 8,
  DGB: 8,
  DAI: 8,
  BTC: 8,
  ETH: 8,
  BCH: 8,
  DASH: 8,
  ZCR: 6,
  BLU: 6,
};

export const chartProperties = {
  // width: '100%',
  // height: '100%',
  layout: {
    backgroundColor: '#2B2B43',
    lineColor: '#2B2B43',
    textColor: '#D9D9D9',
  },
  watermark: {
    color: 'rgba(0, 0, 0, 0)',
  },
  crosshair: {
    color: '#758696',
  },
  grid: {
    vertLines: {
      color: '#2B2B43',
    },
    horzLines: {
      color: '#363C4E',
    },
  },
  priceScale: {
    borderVisible: true,
  },
  timeScale: {
    borderVisible: true,
  },
  overrides: {
    'paneProperties.background': '#232140',
    'paneProperties.vertGridProperties.color': '#454545',
    'paneProperties.horzGridProperties.color': '#454545',
    'scalesProperties.textColor': '#AAA',
    'mainSeriesProperties.candleStyle.upColor': '#70a800',
    'mainSeriesProperties.candleStyle.downColor': '#ea0070',
    'mainSeriesProperties.candleStyle.borderUpColor': '#70a800',
    'mainSeriesProperties.candleStyle.borderDownColor': '#ea0070',
    'mainSeriesProperties.candleStyle.wickUpColor': '#70a800',
    'mainSeriesProperties.candleStyle.wickDownColor': '#ea0070',
  },
  studies_overrides: {
    'Moving Average.precision': 8,
    'volume.volume.color.0': '#70a800',
    'volume.volume.color.1': '#ea0070',
    'volume.volume ma.color': '#FF0000',
  },
  toolbar_bg: '#232140',
  autosize: true,
  enabled_features: [
    'dont_show_boolean_study_arguments',
    'hide_last_na_study_output',
  ],
  disabled_features: [
    'header_symbol_search',
    'symbol_info',
    'header_compare',
    'header_chart_type',
    'display_market_status',
    'symbol_search_hot_key',
    'compare_symbol',
    'border_around_the_chart',
    'remove_library_container_border',
    'symbol_info',
    'header_interval_dialog_button',
    'show_interval_dialog_on_key_press',
    'volume_force_overlay',
    'header_widget',
  ],
  loading_screen: {
    backgroundColor: '#262d33',
  },
};

export const languages = [
  {
    key: 'pt_br',
    name: 'portuguese',
  },
  {
    key: 'en',
    name: 'english',
  },
];

export const videosUrls = {
  stratumx: [
    {
      video: 'https://www.youtube.com/embed/dMRKg_cYtdI',
      thumb: 'http://img.youtube.com/vi/dMRKg_cYtdI/maxresdefault.jpg',
    },
    {
      video: 'https://www.youtube.com/embed/xOpwQ2Nun_c',
      thumb: 'http://img.youtube.com/vi/xOpwQ2Nun_c/maxresdefault.jpg',
    },
  ],
  stratum: [
    {
      video: 'https://www.youtube.com/embed/7BayrAkueH8',
      thumb: 'http://img.youtube.com/vi/7BayrAkueH8/maxresdefault.jpg',
    },
    {
      video: 'https://www.youtube.com/embed/Wk7_MFQiJeI',
      thumb: 'http://img.youtube.com/vi/Wk7_MFQiJeI/maxresdefault.jpg',
    },
    {
      video: 'https://www.youtube.com/embed/ooQ7IVlumWg',
      thumb: 'http://img.youtube.com/vi/ooQ7IVlumWg/maxresdefault.jpg',
    },
  ],
  atarpay: [
    {
      video: 'https://www.youtube.com/embed/Nb1WL-MmIN0',
      thumb: 'http://img.youtube.com/vi/Nb1WL-MmIN0/maxresdefault.jpg',
    },
    {
      video: 'https://www.youtube.com/embed/eAvnBkDqGBQ',
      thumb: 'http://img.youtube.com/vi/eAvnBkDqGBQ/maxresdefault.jpg',
    },
    {
      video: 'https://www.youtube.com/embed/-acrpz0vPoo',
      thumb: 'http://img.youtube.com/vi/-acrpz0vPoo/maxresdefault.jpg',
    },
  ],
  ras: [
    {
      video: 'https://www.youtube.com/embed/2FEUa_8cIYY',
      thumb: 'http://img.youtube.com/vi/2FEUa_8cIYY/maxresdefault.jpg',
    },
  ],
  institutional: [
    {
      video: 'https://www.youtube.com/embed/1YUtxcaS2_E',
      thumb: 'http://img.youtube.com/vi/1YUtxcaS2_E/maxresdefault.jpg',
    },
    {
      video: 'https://www.youtube.com/embed/rBxCcvZ2Eyw',
      thumb: 'http://img.youtube.com/vi/rBxCcvZ2Eyw/hqdefault.jpg',
    },
    {
      video: 'https://www.youtube.com/embed/5H-EB91Bbrs',
      thumb: 'http://img.youtube.com/vi/5H-EB91Bbrs/maxresdefault.jpg',
    },
  ],
};
