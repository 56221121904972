import '~/config/reactotron';
import { persistStore } from 'redux-persist';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { middlewareToken } from '../services/safeToken';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import persistReducers from './persistReducers';

const dev = process.env.NODE_ENV === 'development';

const sagaMonitor = dev ? console.tron.createSagaMonitor() : null;
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [sagaMiddleware, middlewareToken];

const composer = dev
  ? compose(applyMiddleware(...middlewares), console.tron.createEnhancer())
  : applyMiddleware(...middlewares);

const store = createStore(persistReducers(rootReducer), composer);
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export default { store, persistor };
