import Axios from 'axios';
import { getToken } from '../safeToken';

import endpoints from '../../config/endpoints';

const baseURL = endpoints.order;

const Book = Axios.create({
  baseURL,
});

Book.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export function RequestProducts() {
  return Axios.get(`${baseURL}/web/v1/products`);
}

export function RequestDirectProducts() {
  return Axios.get(`${baseURL}/web/v1/directProducts`);
}

/**
 * Pair id. Ex: BTC-RAS
 * @param {string} pairId
 *
 */
export function RequestTrades({ pairId }) {
  return Book.get(`${baseURL}/web/v1/products/${pairId}/trades`);
}
