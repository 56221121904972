import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FaExclamation, FaCheck } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Container } from './styles';
import { LoadingAnimation } from '~/components/animation';

import * as SwapActions from '~/store/modules/swap/actions';
import { dateFormat } from '~/utils/format';

function WithdrawHistoryComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history, loadingHistory } = useSelector((state) => state.swap);

  useEffect(() => {
    dispatch(SwapActions.requestSwaps({ page: 1 }));
  }, [dispatch]);

  function handlePageSelect({ selected }) {
    dispatch(SwapActions.requestSwaps({ page: selected + 1 }));
  }

  function renderFlag(status) {
    if (status === 'create') {
      return (
        <p className="flag-status status-create">
          {t('awaiting')}
          {' '}
          {/* <AiOutlineLoading size={10} fill="#fff" /> */}
        </p>
      );
    }
    if (status === 'pending') {
      return (
        <p className="flag-status status-pending">
          {t('pending')}
          {' '}
          <FaExclamation size={10} />
        </p>
      );
    }
    if (status === 'converted') {
      return (
        <p className="flag-status status-converted">
          {t('converted')}
          {' '}
          <FaCheck size={10} />
        </p>
      );
    }
    if (status === 'canceled') {
      return (
        <p className="flag-status status-canceled">
          {t('canceled')}
          {' '}
          <FaCheck size={10} />
        </p>
      );
    }
  }

  return (
    <Container title={t('swap')}>
      <header>
        <h1>{t('history')}</h1>
      </header>
      <div className="history-item header-history">
        <p>{t('coin')}</p>
        <p>{t('fee')}</p>
        <p>{t('date_swaped')}</p>
        <p>{t('date')}</p>
        <p>{t('type')}</p>
        <p>{t('status')}</p>
      </div>
      <div className="container-data">
        {loadingHistory && (
          <LoadingAnimation width={100} height={100} />
        )}
        {!loadingHistory && !!history?.data[0] && history?.data.map((i) => (
          <div key={i.id} className="history-item">
            <p>{i.currency}</p>
            <p>{parseFloat(i.amount).toFixed(4)}</p>
            <p>{dateFormat({ date: i.CreatedAt, format: 'yyyy-MM-dd' })}</p>
            <p>{'  '}</p>
            <p>{i.type}</p>
            {renderFlag(i.status)}
          </div>
        ))}
      </div>
      <ReactPaginate
        previousLabel=""
        nextLabel=""
        breakLabel="..."
        breakClassName="break-me"
        pageCount={history?.pages || 0}
        marginPagesDisplayed={10}
        pageRangeDisplayed={10}
        onPageChange={handlePageSelect}
        containerClassName="pagination"
        activeClassName="pagination-active"
      />
    </Container>
  );
}

export default WithdrawHistoryComponent;
