export function setCurrentWallet({ currentWallet }) {
  return { type: '@wallet/setCurrentWallet', payload: { currentWallet } };
}

export function setWallets(wallets) {
  return { type: '@wallet/setWallets', payload: { wallets } };
}

export function setBalance({ balance, inOrder }) {
  return { type: '@wallet/setBalance', payload: { balance, inOrder } };
}

export function walletResponse(data) {
  return { type: '@wallet/walletResponse', payload: { data } };
}

export function requestWallet({ per_page, page }) {
  return { type: '@wallet/requestWallet', payload: { per_page, page } };
}

export function responseWallet({
  success, wallets, error, page, pages, total,
}) {
  return {
    type: '@wallet/responseWallet',
    payload: {
      success,
      wallets: wallets || [],
      error,
      page: page || 1,
      pages: pages || 0,
      total: total || 0,
    },
  };
}
