import { all, call, takeLatest } from 'redux-saga/effects';

import * as SocketSagas from './socket/sagas';
import General from './general/sagas';
import Socket from './socket/sagas';
import Auth from './auth/sagas';
import Wallet from './wallet/sagas';
import Swap from './swap/sagas';
import Register from './register/sagas';
import Deposit from './deposit/sagas';
import DepositRas from './depositRas/sagas';
import Book from './book/sagas';
import Fee from './fee/sagas';
import trade from './trade/sagas';
import Order from './order/sagas';
import Product from './product/sagas';
import Withdraw from './withdraw/sagas';

import * as TradingView from './tradingView/sagas';

export default function* rootSaga() {
  return yield all([
    call(SocketSagas.startListen),
    General,
    Socket,
    Auth,
    Wallet,
    Swap,
    Register,
    Deposit,
    DepositRas,
    Book,
    Fee,
    Order,
    Product,
    Withdraw,
    trade,

    // takeLatest('@withdraw/requestWithdraw', Withdraw.requestWithdraw),
    // takeLatest('@withdraw/withdrawResponse', Withdraw.withdrawResponse),
    // takeLatest('@withdraw/withdrawCreate', Withdraw.withdrawCreate),
    // takeLatest('@withdraw/withdrawConfirm', Withdraw.withdrawConfirm),
    // takeLatest('@withdraw/withdrawCancel', Withdraw.withdrawCancel),
    // takeLatest('@withdraw/withdrawGetInfoToConfirm', Withdraw.withdrawGetInfoToConfirm),

    takeLatest(
      '@tradingView/requestSymbolConfig',
      TradingView.requestSymbolConfig,
    ),
    takeLatest('@tradingView/requestHistory', TradingView.requestHistory),
  ]);
}
