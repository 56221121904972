import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import * as DepositRasActions from '~/store/modules/depositRas/actions';

import { LoadingAnimation } from '~/components/animation';
import { Container } from './styles';
import { ReactComponent as DepositIcon } from '~/assets/svg/deposit.svg';

function DepositRasComponent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, linkToDeposit, message } = useSelector((state) => state.depositRas);

  useEffect(() => {
    dispatch(DepositRasActions.requestDepositRasUrl());
  }, [dispatch]);

  return (
    <Container title="deposit">
      <div className="flag_top_left">
        <DepositIcon width={30} />
      </div>
      <div className="body_deposit_card">
        {loading && <LoadingAnimation width={100} height={100} />}
        {!loading && (
          <>
            <div>
              <Trans i18nKey="withdraw_ras_header" />
            </div>
            <img alt="real deposit" src="/depositreal.png" />
            {!message && !!linkToDeposit ? (
              <a href={linkToDeposit} target="_blank" rel="noreferrer" className="button-redirect">{t('convert')}</a>
            ) : (
              <p style={{ color: 'red', marginTop: 10 }}>{t(message)}</p>
            )}
            <div>
              <Trans i18nKey="withdraw_ras_click_redirect" />
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

export default DepositRasComponent;
