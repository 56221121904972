import { call, put, select } from 'redux-saga/effects';
import { getUnixTime, subDays } from 'date-fns';
import * as tradingViewActions from './actions';
import * as api from '../../../services/api';
import { currencies } from '../../../config/environments';

export function* requestSymbolConfig(action) {
  try {
    const { name } = action.payload.currency || currencies[0];

    const symbol = name.replace('/', '');
    const { data } = yield call(api.requestConfigurationToASymbol, {
      symbol,
    });
    yield put(tradingViewActions.setSymbolConfig({ symbolConfig: data }));
  } catch (error) {
    console.log('request symbol error', error);
  }
}

export function* requestHistory() {
  try {
    const { baseCurrency } = yield select((state) => state.product);
    if (!baseCurrency) return false;
    const { data } = yield call(api.requestHistory, {
      symbol: baseCurrency.id.replace('-', ''),
      resolution: '1',
      from: getUnixTime(subDays(new Date(), 3)),
      to: getUnixTime(new Date()),
    });
    yield put(tradingViewActions.setHistory({ history: data }));
  } catch (error) {
    console.log('[error] requestHistory', error);
  }
}
