import React, { useState, useMemo, useEffect } from 'react';

import { FaAngleDown } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import * as ProductActions from '~/store/modules/product/actions';
import * as BookActions from '~/store/modules/book/actions';
import { Container, Coin, ListDown } from './styles';
import { Svg } from '~/components/iconSet';

export default function BookSelectCurrencyComponent() {
  const { products, baseCurrency } = useSelector((state) => state.product);
  const [toggleList, setToggleList] = useState([false, false]);
  const dispatch = useDispatch();

  function requestProductList() {
    dispatch(ProductActions.requestProducts());
  }

  useEffect(() => {
    if (!products?.length) {
      requestProductList();
    }
  }, [products]);

  const baseCurrencyList = useMemo(() => {
    const baseList = [];
    products.forEach((product, index) => {
      if (index === 0) {
        return baseList.push(product);
      }
      if (!baseList.find((b) => b.baseCurrency === product.baseCurrency)) {
        return baseList.push(product);
      }
    });
    return baseList;
  }, [products]);

  const quoteCurrencyList = useMemo(() => products.filter((product) => product.id.includes(`${baseCurrency?.baseCurrency}-`)), [products, baseCurrency]);

  const handleToggleList = (index) => {
    const toggle = [...toggleList];
    toggle[index] = !toggle[index];
    setToggleList(toggle);
  };

  const handleSelectCurrency = (baseCurrencySelected) => {
    dispatch(ProductActions.unsubscribeBaseCurrency({ baseCurrency }));
    dispatch(ProductActions.setBaseCurrency({ baseCurrency: baseCurrencySelected }));
    dispatch(BookActions.updateTicker({ low24h: 0, open24h: 0, price: 0, volume24h: 0 }));
    dispatch(BookActions.setBook({ Asks:[],Bids:[] }));
    setToggleList([false, false]);
  };

  return (
    <Container>
      <Coin className="first-coin" onClick={() => handleToggleList(0)}>
        {!!baseCurrency && (
          <>
            <Svg name={baseCurrency?.baseCurrency} size={30} />
            <div className="info info-icon-currency">
              <h1>{baseCurrency?.baseCurrency}</h1>
              <h3>{parseFloat(baseCurrency.baseAvailable || 0).toFixed(baseCurrency.baseScale)}</h3>
            </div>
          </>
        )}
        <FaAngleDown />
        <ListDown show={toggleList[0]}>
          {baseCurrencyList.map((c) => (
            <Coin key={c.id + Math.random()} onClick={() => handleSelectCurrency(c)}>
              <Svg name={c?.baseCurrency} size={30} />
              <div className="info info-icon-currency">
                <h1>{c.baseCurrency}</h1>
                <h3>{parseFloat(c.baseAvailable || 0).toFixed(c.baseScale)}</h3>
              </div>
              <span />
            </Coin>
          ))}
        </ListDown>
      </Coin>
      <Coin onClick={() => handleToggleList(1)}>
        {!!baseCurrency && (
          <>
            <Svg name={baseCurrency?.quoteCurrency} size={30} />
            <div className="info info-icon-currency">
              <h1>{baseCurrency?.quoteCurrency}</h1>
              <h3>
                {parseFloat(baseCurrency.quoteAvailable || 0).toFixed(baseCurrency.quoteScale || 0)}
              </h3>
            </div>
          </>
        )}
        <FaAngleDown />
        <ListDown show={toggleList[1]}>
          {quoteCurrencyList.map((c) => (
            <Coin key={c.quoteCurrency + Math.random()} onClick={() => handleSelectCurrency(c)}>
              <Svg name={c.quoteCurrency} size={30} />
              <div className="info info-icon-currency">
                <h1>{c.quoteCurrency}</h1>
                <h3>{parseFloat(c.quoteAvailable || 0).toFixed(c.quoteScale)}</h3>
              </div>
              <span />
            </Coin>
          ))}
        </ListDown>
      </Coin>
    </Container>
  );
}
