export function requestLogin({ nickname, password }) {
  return { type: '@auth/requestLogin', payload: { nickname, password } };
}

export function responseLogin({ success, status, user, token, step_register, step_session, error = '' }) {
  return { type: '@auth/responseLogin', payload: { success, status, user, token, step_register, step_session, error } };
}

export function requestSecret() {
  return { type: '@auth/requestSecret' };
}

export function responseSecret({ success, error = '', code, request_time, otp_url }) {
  return { type: '@auth/responseSecret', payload: { success, error, code, request_time, otp_url } };
}

export function requestCheck2fa({ confirmation, code }) {
  return { type: '@auth/requestCheck2fa', payload: { confirmation, code } };
}

export function responseCheck2fa({
  success,
  error,
  message,
  status,
  user,
  step_register,
  step_session,
  token,
}) {
  return { type: '@auth/responseCheck2fa',
    payload: {
      success,
      error,
      message,
      status,
      user,
      step_register,
      step_session,
      token,
    } };
}

export function logout() {
  return { type: '@auth/logout' };
}
export function cleanUnecessary() {
  return { type: '@auth/cleanUnecessary' };
}

export function requestRecovery({ type, email = '', userId = '', onlyToastMessage = false }) {
  return { type: '@auth/requestRecovery', payload: { type, email, userId, onlyToastMessage } };
}

export function responseRecovery({ success, message = '', error = '' }) {
  return { type: '@auth/responseRecovery', payload: { success, message, error } };
}
