import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: {

      translation: {
        book_sell:"Sell orders",
        book_sell_description:"Orders from who is selling",
        book_buy:"Buy orders",
        book_buy_description:"Orders from who is buying",
        direct_order:"Direct order",
        direct_order_description:"An order that does not depend on a book to execute",
        direct_new_description:"Now you buy without having to order in the book",
        menu:{
          wallet:"Wallet",
          trade:"Trade",
          otc:"Otc",
          swap: "Swap",
          settings:"Settings",
          videos:"Video",
          fiat:"Fiat",
          direct:"Direct"
        },

        fiat:{
          description:"You can make a deposit or withdraw using your balance in {{currency}}",
          convert_RAS:"Convert in Reais",
          convert_ZAR:"Convert in ZAR"
        },

        your_balance:"current balance",
        direct_want_buy:"I want to buy",
        direct_want_buy_total:"To receive a total of",
        direct_want_sell:"I want to sell",
        direct_want_sell_total:"For a total of",
        send: 'enviar',
        send: 'enviar',
        login: 'login',
        news: 'news',
        pair: 'pair',
        type: 'type',
        side: 'side',
        next: 'next',
        recovery: 'recovery',
        register: 'register',
        convert: 'convert',
        date: 'date',
        create: 'create',
        deposit: 'deposit',
        deposit_header_message: `
          Send only {{crypto}} to this deposit address. Sending any other currency to this address may result in theloss of your deposit.
        `,
        deposit_notes: `
          <p>Please note:</p>
          <p>Notice: Coins will be deposited immediately after the following steps:</p>
          <p>1/3 - Seen on Blockchain</p>
          <p>2/3 - Seen on Platform</p>
          <p>3/3 - Available to trade</p>
        `,
        deposit_notes_blu: `
          <p>To make a {{crypto}} deposit, you need to use your {{crypto}} message (TAG)!</p>
          <p>Otherwise your deposit will be forfeited!</p>
        `,
        deposit_real_with_ras: 'Deposit Real with RAS',
        withdraw_ras_with_real: 'Withdraw RAS with Real',
        withdraw_ras_header: `
          <p>Mazzera only works with StableCoins.</p>
          <p>Here you can convert BRL (Reais) in RAS.</p>
        `,
        withdraw_ras_click_redirect: `
          <p>By clicking you will be redirected to a</p>
          <p>payment partner.</p>
        `,
        withdraw_ras_note: `
          <p>Please note:</p>
          <p>
            -After making a withdrawal, you can track your progress on the history page.
          </p>
        `,
        loading_fee: 'loading fee',
        fail_load_fees: 'fail load fees',
        deposit_history: 'deposit history',
        open_orders: 'open orders',
        order_history: 'order history',
        order_successfull_created: 'order successfull created',
        order_successfull_canceled: 'order successfull canceled',
        fail_to_cancel_order: 'fail to cancel order',
        fail_to_create_order: 'fail to create order',
        founds: 'funds',
        go_back_to_book: 'Go back to book',
        expired_session: 'expired session',
        you_are_not_logged: 'you are not logged',
        problems_to_get_ras_addresses: 'problems to get ras addresses',
        put_your_code: 'Put your code',
        put_your_email: 'Put Your e-mail',
        put_your_name: 'Put your name',
        put_your_user_id: 'Put Your userId',
        put_your_password: 'Put Your password',
        put_your_new_password: 'Put your new password',
        confirm_your_password: 'confirm your password',
        put_your_phone: 'Insert your Mobile Number',
        address: 'address',
        example:"example",
        amount: 'amount',
        coin: 'coin',
        fee: 'taxa',
        balance: 'balance',
        available_amount: 'available amount',
        available_balance: 'available balance',
        total_balance: 'total balance',
        copied: 'copied',
        cancel: 'cancel',
        approve: 'approve',
        copy: 'copy',
        withdraw: 'withdraw',
        withdraw_history: 'withdraw history',
        withdraw_address: 'withdrawal address',
        withdraw_amount: 'withdraw amount',
        withdraw_amount_minimum_required: 'Amount below the minimum required',
        withdraw_amount_minimum_required_modal:'Amount below the minimum required. min: {{min}} {{currency}}',
        withdraw_2fa_message: 'You must enter the 2FA code to continue the withdrawal process',
        withdraw_check_your_email_message: 'Almost done! Please, check your d to approve the withdrawal',
        withdraw_canceled_success: 'withdraw canceled! You can see on the reports page.',
        withdraw_approve_success: 'withdraw approved! You can progress on the reports page.',
        withdraw_check_your_available_amount_in: 'check your available amount in {{crypto}}',
        smartwallet_address: 'smartwallet address',
        xago_address:"xago address",
        forgot_user_id: 'forgot user id',
        forgot_email: 'forgot email',
        forgot_password: 'forgot password',
        change_password: 'change password',
        confirm: 'confirm',
        code_mail: 'Insert the code Received in your e-mail.',
        code_2fa: '2FA code (6 Digits)',
        in_order: 'in order',
        total_value: 'total value',
        wallet: 'wallet',
        available: 'available',
        back: 'back',
        buy: 'Buy',
        sell: 'Sell',
        market: 'Market',
        quantity: 'Quantity',
        price: 'Price',
        total: 'Total',
        spread: 'spread',
        trades: 'trades',
        trades_description: 'negotiations executed on the platform',
        last_price: 'Last price',
        hight_price: 'Hight price',
        low_price: 'Low price',
        price_24h: 'Price 24h',
        password_not_match: 'password not match',
        required_field: 'required field',
        invalid_d: 'invalid email',
        invalid_phone: 'invalid phone',
        invalid_2fa: 'Invalid 2fa',
        validate: 'validate',
        swap: 'Swap',
        swap_alert_title: 'Are you sure?',
        swap_alert_button: 'Yes',
        swap_alert_text: 'You wont be able to revert this!',
        history: 'history',
        awaiting: 'awaiting',
        converted: 'converted',
        pending: 'pending',
        canceled: 'canceled',
        date_swaped: 'date swaped',
        real_to_ras_message: 'In this page i will Convert RAS in REAL',
        we_send_email_for_you: 'we send an email to you',
        register_send_mail_message: 'you have been successfully registered, please check your email to confirm your account',
        invalid_request_code: 'the received code is expired or invalid',
        your_password_has_been_changed: 'your password has been changed',
        your_account_is_validated: 'your account is validated',
        authentication_confirmed_redirecting: 'authentication confirmed. redirecting...',
        login_successfully_redirecting: 'Login successfully redirecting...',
        ERROR_USER_LOGGED: 'Your password or login is invalid',
        INVALID_JWT: 'invalid token',
        ERROR_INVALID_CURRENCY: 'Selected currency is invalid',
        ERROR_INVALID_CODE: 'Invalid code',
        ERROR_INVALID_CODE_SIZE: 'Invalid code size',
        INVALID_CREDENTIALS: 'Invalid credentials',
        ERROR_INVALID_WITHDRAWAL_REQUEST: 'invalid  withdraw request',
        ERROR_CUSTOMER_EMAIL_EXISTS: 'customer email already exists',
        ERROR_INVALID_STATUS: 'invalid status',
        'Account not activated': 'Account not activated',
        you_dont_have_funds_in: "you don't have funds in {{crypto}}",
        invalid_value: 'invalid value',
        otc: {
          hotline: 'Hotline',
          header: 'Mazzera OTC Desk',
          welcome: `
          <p>Welcome to the innovative solutions of Mazzera, your reliable partner in the cryptocurrency market.</p>
          <p>We are committed to providing a unique and efficient experience for those seeking over-the-counter (OTC) operations with digital assets.</p>
          <p>We offer a variety of OTC services for cryptocurrencies, including buying and selling large volumes, customized negotiations, and solutions tailored to the individual demands of each client. Our goal is to simplify the transaction process, providing security and efficiency.</p>
          <p>Benefits for Buyers:</p>
          
          <p>- Privacy and Confidentiality:
             We understand the importance of privacy in the world of cryptocurrencies. Our OTC services ensure total confidentiality in transactions, protecting the identity and details of buyers.</p>
          
          <p>- Fast and Efficient Execution:
             With a technologically advanced platform, we facilitate OTC transactions quickly and efficiently. Our aim is to ensure that buyers receive their cryptocurrencies in a timely manner, avoiding the volatility of the conventional market.</p>
          
          <p>- Personalized Service:
             We recognize that each client is unique. We offer personalized service, understanding individual needs and adapting our services to ensure the satisfaction of each buyer.</p>
          
          <p>- High-Level Security:
             Security is our priority. We implement rigorous protocols to protect both assets and customer data, providing a secure environment for OTC transactions.</p>
          
          <p>- Access to Various Cryptocurrencies:
             We offer access to a wide variety of cryptocurrencies, allowing buyers to diversify their portfolios conveniently and safely.</p>
          
          `,
          contact_message: 'Get to know our table. Get in touch with them on the channels below and good negotiation.',
        },
      },
    },
    pt_br: {
      translation: {
        book_sell:" Ordens de venda",
        book_sell_description:"Ordens de quem está vendendo",
        book_buy:" Ordens de compra",
        book_buy_description:"Ordens de quem está comprando",
        direct_order:"Ordem direta",
        direct_order_description:"Uma ordem que não depende de livro para executar",
        direct_new_description:"Agora você comprar sem precisar ter ordem no book",
        menu:{
          wallet:"Carteira",
          trade:"Trade",
          otc:"Otc",
          swap: "Swap",
          settings:"Configuração",
          videos:"Video",
          fiat:"Dinheiro",
          direct:"Direta"
        },

        fiat:{
          description:" Você pode fazer um deposito ou um saque usando o seu saldo em {{currency}}.",
          convert_RAS:"Converter em Reais",
          convert_ZAR:"Converter em ZAR"
        },

        your_balance:"saldo atual",
        direct_want_buy:"Eu quero comprar",
        direct_want_buy_total:"Para receber um total de",
        direct_want_sell:"Eu quero vender",
        direct_want_sell_total:"Por um total de",
        send: 'send',
        login: 'login',
        news: 'novos',
        pair: 'par',
        type: 'tipo',
        side: 'lado',
        next: 'próximo',
        recovery: 'recuperar',
        register: 'registrar',
        convert: 'converter',
        date: 'data',
        create: 'criar',
        deposit: 'deposito',
        deposit_header_message: `
          Envie apenas {{crypto}} para este endereço de depósito. O envio de qualquer outra moeda para este endereço pode resultar na perda do seu depósito.`,
        deposit_notes: `
          <p>Observe:</p>
          <p>Aviso: As moedas serão depositadas imediatamente após as seguintes etapas:</p>
          <p>1/3 - Visto no Blockchain</p>
          <p>2/3 - Visto na Plataforma</p>
          <p>3/3 - Disponível para comércio</p>
        `,
        deposit_notes_blu: `
          <p>Para fazer um depósito {{crypto}}, você precisa usar o campo memo!</p>
          <p>Caso contrário, seu depósito será perdido!</p>
        `,
        deposit_real_with_ras: 'Deposite Real com RAS',
        withdraw_ras_with_real: 'Retire RAS com Real',
        withdraw_ras_header: `
          <p>Mazzera funciona apenas com StableCoins.</p>
          <p>Aqui você pode converter BRL (Reais) em RAS.</p>
        `,
        withdraw_ras_click_redirect: `
          <p>Ao clicar, você será redirecionado para um</p>
          <p>parceiro de pagamento.</p>
        `,
        withdraw_ras_note: `
          <p>Observe:</p>
          <p>
            -Após fazer um saque, você pode acompanhar seu progresso na página de histórico.
          </p>
        `,
        loading_fee: 'carregando taxa',
        fail_load_fees: 'falha ao carregar taxas',
        deposit_history: 'histórico de depósito',
        open_orders: 'ordens abertas',
        order_history: 'histórico de ordens',
        order_successfull_created: 'ordem criaca com sucesso',
        order_successfull_canceled: 'ordem cancelada com sucesso',
        fail_to_cancel_order: 'falha no cancelamento da ordem',
        fail_to_create_order: 'falha na criação da ordem',
        founds: 'fundos',
        go_back_to_book: 'Voltar para o book',
        expired_session: 'sessão expirada',
        you_are_not_logged: 'você não está logado',
        problems_to_get_ras_addresses: 'problemas para obter o endereço ras',
        put_your_code: 'Coloque seu código',
        put_your_email: 'Coloque seu e-mail',
        put_your_name: 'Coloque seu nome',
        put_your_user_id: 'Coloque seu id de usuário',
        put_your_password: 'Coloque sua senha',
        put_your_new_password: 'Insira seu novo password',
        confirm_your_password: 'Confirme sua senha',
        put_your_phone: 'Coloque seu telefone',
        address: 'endereço',
        amount: 'quantidade',
        coin: 'moeda',
        fee: 'taxa',
        balance: 'saldo',
        available_amount: 'quantidade disponível',
        available_balance: 'saldo disponível',
        total_balance: 'saldo total',
        copied: 'copiado',
        cancel: 'cancelar',
        approve: 'aprovar',
        copy: 'copiar',
        example:"exemplo",
        withdraw: 'retirar',
        withdraw_history: 'histórico de retirada',
        withdraw_address: 'endereço de retirada',
        withdraw_amount: 'quantidade da retirada',
        withdraw_amount_minimum_required: 'Quantidade abaixo do mínimo exigido',
        withdraw_amount_minimum_required_modal:'Quantidade abaixo do mínimo exigido. min: {{min}} {{currency}}',
        withdraw_2fa_message: 'Você deve inserir o código 2FA para continuar o processo de retirada',
        withdraw_check_your_email_message: 'Quase pronto! Verifique seu email para confirmar a retirada',
        withdraw_canceled_success: 'retirada cancelada. Você pode acompanhar na pagina de relatórios.',
        withdraw_approve_success: 'retirada aprovada. Você pode acompanhar o progresso na pagina de relatórios.',
        withdraw_check_your_available_amount_in: 'verifique a quantidade disponível em {{crypto}}',
        smartwallet_address: 'endereço smartwallet',
        xago_address:"xago address",
        forgot_user_id: 'esqueci meu usuário',
        forgot_email: 'esqueci meu email',
        forgot_password: 'esqueci minha senha',
        change_password: 'mudar senha',
        confirm: 'confirmar',
        code_mail: 'código recebido no email',
        code_2fa: 'código 2FA (6 Digitos)',
        in_order: 'em ordem',
        total_value: 'valor total',
        wallet: 'carteira',
        available: 'disponível',
        back: 'voltar',
        buy: 'Comprar',
        sell: 'Vender',
        market: 'Mercado',
        quantity: 'Quantidade',
        price: 'Preço',
        total: 'Total',
        spread: 'propagação',
        trades: 'negociações',
        trades_description: 'negociações realizada na plataforma',
        last_price: 'último preço',
        hight_price: 'melhor preço',
        low_price: 'menor preço',
        price_24h: 'preço 24h',
        password_not_match: 'as senhas não coincidem',
        required_field: 'campo obrigatório',
        invalid_email: 'email inválido',
        invalid_phone: 'telefone inválido',
        invalid_2fa: 'código 2fa inválido',
        validate: 'validar',
        swap: 'Trocar',
        swap_alert_title: 'Você tem certeza?',
        swap_alert_button: 'Sim',
        swap_alert_text: 'Você não será capaz de reverter isso!',
        awaiting: 'aguardando',
        history: 'histórico',
        converted: 'convertido',
        pending: 'pendente',
        canceled: 'cancelado',
        date_swaped: 'data da troca',
        real_to_ras_message: 'Nesta página vou converter RAS em REAL',
        we_send_email_for_you: 'Nós enviamos um email para você.',
        register_send_mail_message: 'registro concluído, verifique seu email e confirme sua conta',
        invalid_request_code: 'o codigo recebido está expirado ou é inválido',
        your_password_has_been_changed: 'sua senha foi alterada',
        your_account_is_validated: 'sua conta esta validada',
        authentication_confirmed_redirecting: 'autenticação confirmada. redirecionando...',
        login_successfully_redirecting: 'Login efetuado com sucesso. redirecionando...',
        ERROR_USER_LOGGED: 'usuário ou senha incorretos',
        INVALID_JWT: 'Token inválido',
        ERROR_INVALID_CURRENCY: 'moeda selecionada é invalida',
        ERROR_INVALID_CODE: 'codigo inválido',
        ERROR_INVALID_CODE_SIZE: 'tamanho do codigo é inválido',
        INVALID_CREDENTIALS: 'credenciais inválidas',
        ERROR_INVALID_WITHDRAWAL_REQUEST: 'requisição de retirada inválida',
        ERROR_CUSTOMER_EMAIL_EXISTS: 'email do cliente ja existe',
        ERROR_INVALID_STATUS: 'status inválido',
        'Account not activated': 'conta não ativada',
        you_dont_have_funds_in: 'você não tem saldo em {{crypto}}',
        invalid_value: 'valor inválido',
        otc: {
          hotline: 'Linha direta',
          header: 'Mazzera OTC Desk',
          welcome: `
              
              <p>Seja bem-vindo às soluções inovadoras da Mazzera, a sua parceira confiável no mercado de criptomoedas.</p>
              
              <p>Estamos comprometidos em proporcionar uma experiência única e eficiente para aqueles que buscam operações over-the-counter (OTC) com ativos digitais.</p>
              
              <p>Oferecemos uma variedade de serviços OTC para criptomoedas, incluindo compra e venda de grandes volumes, negociações personalizadas e soluções adaptadas às demandas individuais de cada cliente. Nosso objetivo é simplificar o processo de transação, proporcionando segurança e eficiência.</p>
              
              <p> <strong> Benefícios para Compradores: </strong></p>

              <p> - Privacidade e Confidencialidade:
              Entendemos a importância da privacidade no mundo das criptomoedas. Nossos serviços OTC garantem total confidencialidade nas transações, protegendo a identidade e os detalhes dos compradores.
              </p>
              <p>
              - Execução Rápida e Eficiente:
              Com uma plataforma tecnologicamente avançada, facilitamos transações OTC de forma rápida e eficiente. Nosso objetivo é garantir que os compradores recebam suas criptomoedas de maneira oportuna, evitando a volatilidade do mercado convencional.
              </p>
              - Atendimento Personalizado:
              Reconhecemos que cada cliente é único. Oferecemos um atendimento personalizado, entendendo as necessidades individuais e adaptando nossos serviços para garantir a satisfação de cada comprador.
              <p>

              - Segurança de Alto Nível:
              A segurança é a nossa prioridade. Implementamos protocolos rigorosos para proteger tanto os ativos quanto os dados dos clientes, proporcionando um ambiente seguro para transações OTC.

              </p>
              - Acesso a Diversas Criptomoedas:
              Oferecemos acesso a uma ampla variedade de criptomoedas, permitindo que os compradores diversifiquem seus portfólios de maneira conveniente e segura.
                            </p>
              
          `,
          contact_message: 'Conheça nossa mesa. Entre em contato com eles nos canais abaixo e boa negociação.',
        },
      },
    },
  },
});

export function changeLanguage(lng) {
  i18next.changeLanguage(lng);
}
