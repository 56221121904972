/* eslint-disable indent */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import * as WalletActions from '~/store/modules/wallet/actions';

import {
  Container,
  ContainerSelectDraw,
  ContainerWallet,
  ContainerCryptoInfo,
  CardWallet,
} from './styles';

import { Svg } from '~/components/iconSet';
import { HeaderBook } from '~/components/headers';
import realIcon from '~/assets/png/real-fiat.png';
import randIcon from '~/assets/png/rand-fiat.png';
import { WalletQrCode, WalletHistoryDeposit, WalletHistoryWithdraw, WalletWithdraw } from '~/components/wallet';

import { ReactComponent as WithdrawIcon } from '~/assets/svg/withdraw.svg';
import { ReactComponent as DepositIcon } from '~/assets/svg/deposit.svg';

import * as FeeActions from '~/store/modules/fee/actions';

export default function WalletPage({ match }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [type, setType] = useState('deposit');
  const { wallets: walletsReceived, currentWallet } = useSelector(
    (state) => state.wallet,
  );
  const { fees } = useSelector((state) => state.fee);

  React.useEffect(() => {
    if (!fees.length) {
      dispatch(FeeActions.requestFee());
    }
  }, [dispatch, fees]);

  let walletsWithPriceInDolar = useMemo(() => walletsReceived.filter((wallet) => {
    return wallet.Currency == "RAS"
  }).map((wallet) => {
		const fee = fees.find((f) => f.name === wallet.Currency);
		let link = ""
		switch(wallet.Currency){
			case "RAS":
				link = "#/ras/deposit"
			break;
			default:
			  link = "#/ras/deposit"
			break;
		}
    if (fee) {
      console.log(link)
      return { ...wallet, price_dolar: parseFloat(wallet.Available) * parseFloat(fee.price_dolar),Link:link };
		}
		

    return { ...wallet, price_dolar: 0 };
  }), [walletsReceived, fees]);

  useEffect(() => {
    if (match.params.crypto) {
      if (!walletsReceived.find((wlt) => wlt.Currency === match.params.crypto)) {
        return history.replace('/wallet');
      }
      const { crypto } = match.params;
      const wallet = walletsReceived.find((w) => w.Currency === crypto);
      dispatch(WalletActions.setCurrentWallet({ currentWallet: wallet }));
    } else {
      dispatch(WalletActions.setCurrentWallet({ currentWallet: null }));
    }
  }, [match, walletsReceived, dispatch, history]);


  const gotoPage = (currency) => {
    switch(currency){
      case "RAS":
        history.replace('/ras/deposit');
        break;
    }
   
  }

  const prevButton = useMemo(() => {
    if (currentWallet) {
      return (
        <Link to="/wallet" className="link-to-back">
          <AiOutlineArrowLeft />
          <p>
            {`   ${t('back')}`}
          </p>
        </Link>
      );
    }
    return null;
  }, [currentWallet, t]);

  function handleChangeTypeWallet({ type: typeReceived }) {
    setType(typeReceived);
  }

  function handleSelectWallet({ wallet }) {
    history.push(`/wallet/${wallet.Currency}`);
  }

  return (
    <>
      <HeaderBook />
      <Container>
        {!currentWallet && (
          <>
            <ContainerWallet>
              
              {walletsWithPriceInDolar.map((wallet) => (
                <CardWallet
                  // disabled={maintenanceWallets.includes(wallet.Currency)}
                  key={wallet.id}
                  role="button"
                  tabIndex={0}
                  className="wallet_item"
									href={wallet.Link}
                >
                  {/* {maintenanceWallets.includes(wallet.Currency) && (
                    <span className="maintenance_flag">maintenance</span>
                  )} */}
                  
                   <div className="icon">
                       {
                        (wallet.Currency == "RAS") &&(
                          <img alt="real deposit" src={realIcon} height="45px"/>
                        )
                      }
                   </div>
                  <div className="wallet_item_info">

                  <div className="wallet_item_row">
                      <p>
                      <Trans t={t} values={{ currency: wallet.Currency }}>fiat.description</Trans>
                      
                       </p>
                    </div>

                    <div className="wallet_item_row">
                      <p>{t('your_balance')}</p>
                      <p>{parseFloat(wallet.Available).toFixed(wallet.quoteScale)}</p>
                    </div>
                    <div className="wallet_item_row icons">
                      <div className="title">
                      { t(`fiat.convert_${wallet.Currency}`)}
                      </div>

                      
                          
                    </div>
                  </div>
                </CardWallet>
              ))}
            </ContainerWallet>
          </>
        )}
      </Container>
    </>
  );
}

WalletPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      crypto: PropTypes.string,
    }),
  }).isRequired,
};
