import Axios from 'axios';
import { getToken } from '../safeToken';

import endpoints from '../../config/endpoints';

const baseURL = endpoints.order;

const Order = Axios.create({
  baseURL,
});

Order.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

/**
  *
  * @param {object} query
  * @param {number} limit
  *
  * query can set status, side and product_id
  *
  * status options: filled, cancelled, new and open
  *
  * params:
  * "limit":1,
  * "query":{
  *   "status":"filled",
  *   "side":"buy",
  *   "product_id":"BTC-RAS"
  * }
 */
export function RequestOrders({ query, per_page }) {
  return Order.post('/client/web/v1/orders', {
    per_page,
    query,
  });
}

export function RequestDirectPrice({ productId, type}) {
  return Order.get(`/web/v1/client/directOrder/price/${productId}/${type}`);
}

/**
 *
 * @param {string} orderId
 */
export function RequestDeleteOrder({ orderId }) {
  return Order.delete(`/web/v1/client/order/${orderId}`);
}

/**
 * @param {string} productId
 * @param {string} side
 * @param {number} size
 * @param {string} type
 * @param {number} price
 */
export function RequestCreateOrder({ productId, side, size, type, price }) {
  return Order.post('/client/web/v1/order', { productId, side, size, type, price });
}
