import produce from 'immer';

const INITIAL_STATE = {
  trades: [],
  error: '',
  loading: false,
};

export default function trades(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@trade/requestTrades':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
      });
    case '@trade/responseTrades':
      return produce(state, (draft) => {
        draft.trades = action.payload.trades;
        draft.error = action.payload.error;
        draft.loading = false;
      });
    case '@trade/addNewTrade':
      return produce(state, (draft) => {
        draft.trades.unshift(action.payload.trade);
      });
    default:
      return state;
  }
}
