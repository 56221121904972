export function setLoading({ name, value }) {
  return { type: '@book/setLoading', payload: { name, value } };
}

export function requestBook() {
  return { type: '@book/requestBook' };
}

export function requestHistory() {
  return { type: '@book/requestHistory' };
}

export function setHistory({ history }) {
  return { type: '@book/setHistory', payload: { history } };
}

export function setBook({ Asks: asks, Bids: bids }) {
  return { type: '@book/setBook', payload: { asks, bids } };
}

export function setOpenOrders({ openOrders }) {
  return { type: '@book/setOpenOrders', payload: { openOrders } };
}

export function updateTicker({ price, open24h, low24h, volume24h }) {
  return { type: '@book/updateTicker', payload: { price, open24h, low24h, volume24h } };
}

export function setCurrency({ currency }) {
  return { type: '@book/setCurrency', payload: { currency } };
}

export function setCurrencies({ currencies }) {
  return { type: '@book/setCurrencies', payload: { currencies } };
}

export function updateCurrenciesWithAvailableAndBanalce({ currencies, currency }) {
  return { type: '@book/updateCurrenciesWithAvailableAndBanalce', payload: { currencies, currency } };
}

export function setTrades({ trades }) {
  return { type: '@book/setTrades', payload: { trades } };
}

export function bookResponse(data) {
  return { type: '@book/bookResponse', payload: { data } };
}
