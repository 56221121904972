export function startListen() {
  return { type: '@socket/startListen' };
}

export function send({ cmd, data }) {
  return { type: '@socket/send', payload: { cmd, data } };
}

export function response({ cmd, data }) {
  return { type: '@socket/response', payload: { cmd, data } };
}
