import Axios from 'axios';
import { getToken } from '../safeToken';

import endpoints from '../../config/endpoints';

const Payment = Axios.create({
  baseURL: endpoints.payment,
});

Payment.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export function RequestDepositRasURl() {
  return Payment.get('/client/deposit');
}
