import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import endpoints from '~/config/endpoints';
import { explorer,baseUrlWithdrawPayment } from '~/config/environments';
import * as WithdrawActions from '~/store/modules/withdraw/actions';
import { Container } from './styles';
import { LoadingAnimation } from '~/components/animation';

import { dateFormat } from '~/utils/format';

function WithdrawHistoryComponent({ receivedCurrentWallet }) {
  const dispatch = useDispatch();
  const historyNavigation = useHistory();
  const { t } = useTranslation();
  const { history, loading } = useSelector((state) => state.withdraw);
  const currentWallet = useSelector((state) => state.wallet.currentWallet);

  React.useEffect(() => {
    if (receivedCurrentWallet) {
      dispatch(WithdrawActions.requestWithdrawHistory({ currency: receivedCurrentWallet }));
    } else {
      dispatch(WithdrawActions.requestWithdrawHistory({ currency: currentWallet?.Currency }));
    }
  }, [dispatch, currentWallet, receivedCurrentWallet]);

  function handleConfirmPress(withdrawRequested) {
    dispatch(WithdrawActions.setRequestedWithdraw({ withdrawRequested }));
    historyNavigation.push('/wallet/withdraw/confirm');
  }

  if (loading) {
    return (
      <Container style={{ justifyContent: 'center' }} title={t('deposit_history')}>
        <LoadingAnimation width={100} height={100} />
      </Container>
    );
  }

  return (
    <Container title={t('withdraw_history')}>
      <header>
        <h1>{t('withdraw_history')}</h1>
      </header>
      <div className="history-item header-history">
        <p>{t('address')}</p>
        <p>{t('amount')}</p>
        <p>{t('date')}</p>
        <p>Txid</p>
        <p>Status</p>
      </div>
      {history.map((hist, index) => (
        <div key={hist.txId || index} className="history-item">
          <p>{hist.address}</p>
          <p>{hist.amount}</p>
          <p>{dateFormat({ date: hist.createdAt })}</p>
          {/* <p>data</p> */}
          <p className="history_tolltip">
            {hist.txId}
            <span>
              <a href={ hist.source == "payment" ? `${baseUrlWithdrawPayment}/${hist.txId}`:`${explorer[hist.currency]}/${hist.txId}`} target="_blank" rel="noreferrer">
                {hist.txId}
              </a>
            </span>
          </p>
          {hist.status === 'confirm' ? (
            <p role="button" tabIndex="0" onClick={() => handleConfirmPress(hist)} className="clickable">{hist.status}</p>
          ) : (
            <p>{hist.status}</p>
          )}
        </div>
      ))}
    </Container>
  );
}

WithdrawHistoryComponent.propTypes = {
  receivedCurrentWallet: PropTypes.string,
};

WithdrawHistoryComponent.defaultProps = {
  receivedCurrentWallet: '',
};
export default WithdrawHistoryComponent;
