import produce from 'immer';
import { languages } from '~/config/environments';

const INITIAL_STATE = {
  languages,
  language: {
    key: 'pt_br',
    name: 'portuguese',
  },
  themeName: 'dark',
  message: '',
  error: '',
  menuOpen: false,
  // contém o índice do que esta em loading no momento
  loading: null,
};

export default function general(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@general/SET_LANGUAGE':
      return produce(state, (draft) => {
        draft.language = action.payload.language;
      });
    case '@general/TOGGLE_MENU':
      return produce(state, (draft) => {
        draft.menuOpen = action.payload.menuOpen;
      });
    case '@general/SET_LOADING':
      return produce(state, (draft) => {
        const loading = { ...draft.loading };
        loading[action.payload.name] = action.payload.value;
        draft.loading = loading;
      });
    case '@general/SET_MESSAGE':
      return produce(state, (draft) => {
        draft.message = action.payload.message;
      });
    case '@general/SET_ERROR':
      return produce(state, (draft) => {
        draft.error = action.payload.error;
      });
    default:
      return state;
  }
}
