import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Input } from '~/styles/components/inputs';
import { Button } from '~/styles/components/buttons';
import {
  Container,
  ContainerButtons,
  ButtonOrder,
  ContainerInputs,
} from './styles';
import { LoadingAnimation } from '~/components/animation';
import * as OrderActions from '~/store/modules/order/actions';
import { ToastFailed } from '~/utils/toast';

export default function BookBuyComponent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { price, loadingCreateOrder } = useSelector((state) => state.book);
  const { wallets } = useSelector((state) => state.wallet);
  const { baseCurrency } = useSelector((state) => state.product);
  const { token } = useSelector((state) => state.auth);
  const formValues = useSelector((state) => state.order.formValues);

  function handleChangeCurrent(index) {
    dispatch(OrderActions.setFormValues({
      ...formValues,
      current: index,
    }));
    // setValues({
    //   price: '0',
    //   amount: '0',
    //   total: '0',
    // });
    // setCurrent(index);
  }

  function handleChangeValue(event) {
    const { name, value } = event.target;

    if (value !== '') {
      const regex = new RegExp(/^[\d.]+$/, 'g');
      if (!regex.test(value)) return true;
    }

    const newValues = { ...formValues };
    newValues[name] = value;

    // setValues(newValues);
    dispatch(OrderActions.setFormValues({
      ...newValues,
      current: formValues.current,
    }));
  }

  function handleMarketPress() {
    handleChangeValue({ target: { name: 'price', value: price } });
  }

  function handleSendOrder() {
    if (!token) {
      ToastFailed('you_are_not_logged');
      return history.push('/signin');
    }
    const { amount, total } = formValues;

    const baseCrypto = wallets.find((w) => w.Currency === baseCurrency?.baseCurrency);
    const quoteCrypto = wallets.find((w) => w.Currency === baseCurrency?.quoteCurrency);

    if (!parseFloat(total)) {
      return ToastFailed('invalid_value');
    }

    if (formValues.current === 0) {
      if (parseFloat(total) > parseFloat(quoteCrypto.Available)) {
        return ToastFailed('you_dont_have_funds_in', { crypto: quoteCrypto.Currency });
      }
    } else if (parseFloat(amount) > parseFloat(baseCrypto.Available)) {
      return ToastFailed('you_dont_have_funds_in', { crypto: baseCrypto.Currency });
    }

    const productId = baseCurrency?.id;
    const side = formValues.current ? 'sell' : 'buy';
    const type = 'limit';
    dispatch(OrderActions.requestCreateOrder({ productId, side, size: amount, price: formValues.price, type }));

    // setValues({
    //   price: '0',
    //   amount: '0',
    //   total: '0',
    // });
  }

  return (
    <Container className="bg-content">
      {loadingCreateOrder && (
        <div className="container-animation">
          <LoadingAnimation width={100} height={100} />
        </div>
      )}
      <ContainerButtons>
        <ButtonOrder
          className="button-buy"
          selected={formValues.current === 0}
          onClick={() => handleChangeCurrent(0)}
        >
          {t('buy')}
        </ButtonOrder>
        <ButtonOrder
          className="button-sell"
          selected={formValues.current === 1}
          onClick={() => handleChangeCurrent(1)}
        >
          {t('sell')}
        </ButtonOrder>
      </ContainerButtons>
      <ContainerInputs current={formValues.current}>
        <div className="container-input-book">
          <span className="span-label">{t('price')}</span>
          <div className="container-input-market">
            <span className="span-input-currency">{baseCurrency?.quoteCurrency}</span>
            <Input
              name="price"
              type="text"
              id="input-market"
              value={formValues.price}
              onChange={handleChangeValue}
            />
            <Button id="button-market" onClick={handleMarketPress}>
              {t('market')}
            </Button>
          </div>
        </div>
        <div className="container-input-book">
          <span className="span-label">{t('amount')}</span>
          <div>
            <span className="span-input-currency">{baseCurrency?.baseCurrency}</span>
            <Input
              name="amount"
              type="text"
              value={formValues.amount}
              onChange={handleChangeValue}
            />
          </div>
        </div>
        <div className="container-input-book">
          <span className="span-label">{t('total')}</span>
          <div>
            <span className="span-input-currency">{baseCurrency?.quoteCurrency}</span>
            <Input disabled type="text" value={formValues.total} />
          </div>
        </div>
        <ButtonOrder
          className="button-buy"
          selected={formValues.current === 0}
          onClick={handleSendOrder}
        >
          {formValues.current ? t('sell') : t('buy')}
          {' '}
          {` ${baseCurrency?.baseCurrency}`}
        </ButtonOrder>
      </ContainerInputs>
    </Container>
  );
}
