import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

export default function PrivateRoute({ component: Component, ...rest }) {
  const token = useSelector((state) => state.auth.token);

  if (token) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return (
    <Route
      {...rest}
      render={() => (
        <Redirect
          to="/"
        />
      )}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};
