import { all, takeEvery, call, delay, put } from 'redux-saga/effects';
import * as swapActions from './actions';
import { GetSwapHistory, GetSwapTotal, GetSwapRescue } from '~/services/api/swap.api';

function* requestSwaps(action) {
  try {
    const { page } = action.payload;
    yield put(swapActions.setInfo({ key: 'loadingHistory', value: true }));
    yield delay(2000);
    const { data } = yield call(GetSwapHistory, { page, per_page: 10 });
    yield put(swapActions.setInfo({ key: 'history', value: data }));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(swapActions.setInfo({ key: 'loadingHistory', value: false }));
  }
}

function* requestTotal() {
  try {
    yield put(swapActions.setInfo({ key: 'loadingTotal', value: true }));
    yield delay(2000);
    const { data } = yield call(GetSwapTotal);
    yield put(swapActions.setInfo({ key: 'total', value: data }));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(swapActions.setInfo({ key: 'loadingTotal', value: false }));
  }
}

function* requestRescue() {
  try {
    yield put(swapActions.setInfo({ key: 'loadingTotal', value: true }));
    yield delay(2000);
    const { data } = yield call(GetSwapRescue);
    yield put(swapActions.requestSwaps({ page: 1 }));
    yield put(swapActions.requestTotal());
  } catch (error) {
    console.log(error);
  } finally {
    yield put(swapActions.setInfo({ key: 'loadingTotal', value: false }));
  }
}

export default all([
  takeEvery('@swap/requestSwaps', requestSwaps),
  takeEvery('@swap/requestTotal', requestTotal),
  takeEvery('@swap/requestRescue', requestRescue),
]);
