import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, ContainerHistory, MiddleContent,ContainerBuySel } from './styles';
import { setFormValues } from '~/store/modules/order/actions';
import {
  BookBuyComponent,
} from '~/components/book';
import ReactTooltip from 'react-tooltip';

export default function BookSpreadComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { asks, bids, currency } = useSelector((state) => state.book);
  const { baseCurrency } = useSelector((state) => state.product);

  function handlePressItem(item) {
    dispatch(setFormValues(item));
  }

  const header = useMemo(() => (
    <header className="history-header">
      <h1>
        {t('price')}
        {' '}
        (
        {baseCurrency?.quoteCurrency}
        )
      </h1>
      <h1>
        {t('amount')}
        {' '}
        (
        {baseCurrency?.baseCurrency}
        )
      </h1>
      <h1>
        {t('total')}
        {' '}
        (
        {baseCurrency?.quoteCurrency}
        )
      </h1>
    </header>
  ), [baseCurrency, t]);

  const history = useMemo(() => {
    const asksWithTotal = asks.map((ask) => ({
      key: ask[0] + ask[1],
      price: parseFloat(ask[0]).toFixed(baseCurrency?.quoteScale),
      amount: parseFloat(ask[1]).toFixed(baseCurrency?.baseScale),
      total: (ask[0] * ask[1]).toFixed(baseCurrency?.quoteScale),
    }));
    const bidsWithTotal = bids.map((bid) => ({
      key: bid[0] + bid[1],
      price: parseFloat(bid[0]).toFixed(baseCurrency?.quoteScale),
      amount: parseFloat(bid[1]).toFixed(baseCurrency?.baseScale),
      total: (bid[0] * bid[1]).toFixed(baseCurrency?.quoteScale),
    }));
    return { asksWithTotal, bidsWithTotal };
  }, [bids, asks, currency]);

  const spread = useMemo(() => {
    if (asks.length && bids.length) {
      return (
        <MiddleContent>
          <span />
          <h1>
            {` ${t('spread')} `}
            {(asks[0][0] - bids[0][0]).toFixed(baseCurrency?.baseScale)}
          </h1>
          <span />
        </MiddleContent>
      );
    }
    return null;
  }, [bids, asks, currency, t]);

  return (
    <Container className="bg-content">
    
      <ContainerBuySel>
      <BookBuyComponent />
      </ContainerBuySel>
      
      <ContainerHistory>
        <h2>
        {t('book_buy')}
          <span data-tip data-for='buyDecription'> (?) </span>
          <ReactTooltip id='buyDecription'>
            <span>{t('book_buy_description')}</span>
          </ReactTooltip>
        </h2>  
        {header}
        {history.bidsWithTotal.map((trade) => (
          <div key={trade.key} className="trade-item" onClick={() => handlePressItem({ ...trade })}>
            <p className="cl-buy">{trade.price}</p>
            <p className="">{trade.amount}</p>
            <p className="">{trade.total}</p>
          </div>
        ))}
       
      </ContainerHistory>
      <ContainerHistory>
        <h2>
        {t('book_sell')}
          <span data-tip data-for='sellDecription'> (?) </span>
          <ReactTooltip id='sellDecription'>
            <span>{t('book_sell_description')}</span>
          </ReactTooltip>
        </h2>  
        {header}
        {history.asksWithTotal.map((trade) => (
          <div key={trade.key} className="trade-item" onClick={() => handlePressItem({ ...trade })}>
            <p className="cl-sell">{trade.price}</p>
            <p className="">{trade.amount}</p>
            <p className="">{trade.total}</p>
          </div>
        ))}
      </ContainerHistory>
    </Container>
  );
}
