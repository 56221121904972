import produce from 'immer';

const INITIAL_STATE = {};

export default function socket(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@socket/setSocketObject':
      return produce(state, (draft) => {
        draft.socket = action.payload.socket;
      });
    default:
      return state;
  }
}
