import { createGlobalStyle } from 'styled-components';

const styles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    font-size: 14px;
    font-family: 'Questrial', sans-serif;
    font-style: normal;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    text-decoration: none;
    list-style: none;
    transition:0.3s;
  }
  h1{
    font-size:20px;
    font-weight: 600;
  }
  h2{
    font-size:14px;
    font-weight: 600;
  }

  html, body, header, footer, div, section, nav, ul, li, a, button{
    text-rendering: optimizeLegibility!important;
    flex-direction: column;
    position: relative;
    display: -webkit-flex;
    /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
    display: -moz-box;
    /* OLD: Firefox (buggy) */
    display: -ms-flexbox;
    /* MID: IE 10 */
    display: flex;

    color:var(--text-color)

  }
  body, #root{
    min-height:100vh;
    max-height:100vh;
    overflow:hidden;
    -webkit-font-smoothing: antialiased !important;
    background-color: var(--default-bg-color);
  }

  button{
    cursor:pointer;
  }

  a{
    text-decoration: none;
    color:var(--text-color)
  }

  p{
    font-size:12px;
  }

  input{
    border: 0;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 10px 20px;
    background-color: var(--content-bg-color);
    color: var(--text-color);

  };

  input::placeholder{
    color: var(--text-color);
  }

  .clickable{
    cursor:pointer;
  }

  *:focus{
    outline:0;
  };

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff3;
    border-radius: 0px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff5;
  }

  /** Alert TODO:*/
  .toast-popup{

  }
`;

export default styles;
