import styled from 'styled-components';

export const Container = styled.section`
  flex: 1;
  padding: 10px;
  padding-bottom:50px;
  display:block;
`;

export const ContainerWallet = styled.div`
  & {
    align-self: center;
    display: grid;
    grid-template-columns: 130px 130px;
    row-gap:30px;
    align-items:center;
    justify-content:center;
  }
  @media (min-width: 400px) {
    & {
      grid-template-columns: 130px 130px 130px;
    }
  }
  @media (min-width: 600px) {
    & {
      grid-template-columns: 160px 160px 160px;
    }
  }
`;

export const CardWallet = styled.div`
  width: 110px;
  height:90px;
  flex-direction: column;
  padding: 10px;
  opacity:${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${({ theme }) => theme.content_bg_color};
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;

  & h1{
    font-size:10px;
    margin-top:2px;
    margin-bottom:2px;
  }

  & span.maintenance_flag{
    position:absolute;
    top:-15px;
    font-size:12px;
    color: ${({ theme }) => theme.error_color};
  }

  & p {
    font-size: 10px;
  }

  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? '' : theme.content_selected_bg_color)};
    cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  }

  & .wallet_item_info {
    width:100%;
    justify-content:space-between;
    align-items:flex-start;
  }

  & .wallet_item_row {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  & .wallet_item_row.price_in_dolar{
    flex-direction: column;
    align-items:center;
    opacity:0.8;
    line-height:20px;
  }

  & .wallet_item_row.wallet_item_opacity{
    opacity:0.5;
  }

  @media (min-width: 600px) {
    & {
      min-width: 150px;
      min-height:130px;
    }
    & h1{
      font-size:13px;
      margin-top:2px;
      margin-bottom:2px;
    }
    & p {
      font-size: 13px;
    }
  }
`;

export const ContainerSelectDraw = styled.div`
  align-self: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
  min-height:80px;

  & .link-to-back {
    position: absolute;
    left: 0px;
    flex-direction: row;
    padding: 10px;
    padding-left: 0px;
    top: calc(50%-15px);
    svg {
      margin-bottom: -2px;
      margin-right: 10px;
    }
  }

  & .wallet_select {
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & p {
      margin-left: 10px;
    }
  }
  & .deposit_active {
    background-color: ${({ theme }) => theme.buy_color};
  }

  & .withdraw_active {
    background-color: ${({ theme }) => theme.sell_color};
  }

  @media( max-width:400px){
    & .link-to-back>p{
      display:none;
    }
  }
  @media (min-width: 600px) {
    & .wallet_select {
      margin: 10px;
      padding: 15px;
      border-radius: 10px;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

    }
    & .wallet_select  p {
      font-size: 15px;
    }
    & .link-to-back p{
      font-size:15px;
    }
  }
`;

export const ContainerCryptoInfo = styled.div`
  margin-top:20px;

  & section:nth-child(2) {
    margin-top: 10px;
  }

  @media (min-width: 1000px) {
    & {
      flex-direction: row;
    }
    & section:nth-child(2) {
      margin-top: 0px;
      margin-left: 10px;
    }
  }
`;
