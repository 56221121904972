import styled from 'styled-components';

export const Container = styled.section`
  /* position:unset;
  z-index:9999999999;
  bottom:0; */
  flex-direction:row;
  display:flex;
  width:100%;
  height: 30px;
  min-height: 30px;
  background-color: ${({ theme }) => theme.default_bg_color};
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  position:fixed;
  bottom:0px;
  z-index:999;

  & .container-footer-item{
    height:100%;
    width:120px;
    justify-content:center;
    align-items:center;
    height:30px;
  }

  & .container-footer-item.footer-whatsapp{
    color: ${({ theme }) => theme.default_bg_color};
    background-color:${({ theme }) => theme.text_color_dark};
    border-top-left-radius:10px;
    border-top-right-radius:10px;
  }
  & a{
    color: ${({ theme }) => theme.default_bg_color};
  }
  & span, & a {
    display: flex;
    width: 120px;
    flex-direction:row;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    & h1 {
      font-size: 15px;
    }
  }

  & ul {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 100%;
  }
  & ul li {
    height: 40px;
    width: 120px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.text_color_dark};
    color: ${({ theme }) => theme.default_bg_color};
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
  }
`;
