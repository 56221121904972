import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const forRight = keyframes`
  0%{
    transform: translateX(0px);
  }
  100%{
    transform: translateX(100px);
  }
`;

export const Container = styled.div`
  width:100%;
  height:100%;
  position:absolute;
  z-index:9999;
  top:0;
  background-color: rgba(0,0,0,0.9);
  animation: 0.5s ${fadeIn} ease-out;
  justify-content:center;
  align-items:center;
`;

export const Content = styled.div`
  flex-direction:row;
  align-items:center;
`;

export const ContainerLogoTranslate = styled.div`
  animation:1s ${forRight};
  animation-delay: ${({ animationDelay }) => animationDelay};
  animation-fill-mode: forwards;
`;

export const AnimatedParagraph = styled.p`
  font-size:40px;
  animation: 1s ${fadeIn} ease-in-out 1s;
  animation-delay: ${({ animationDelay }) => animationDelay};
  opacity:0;
  animation-fill-mode: forwards;
`;
