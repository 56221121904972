import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { Button } from '~/styles/components/buttons';
import MazzeraName from '~/assets/png/mazzera-logo.png';

export default function PageNotFound() {
  const history = useHistory();
  return (
    <Container>
      <div className="card-not-found">
        <img src={MazzeraName} />
        <h1>Pagina não encontrada</h1>
        <Button onClick={() => history.push('/trade')}>
          ir para trade
        </Button>
      </div>
    </Container>
  );
}
