import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as depositRasActions from './actions';
import * as PaymentService from '~/services/api/payment.api';

export function* requestDepositRasUrl() {
  try {
    const { data: DepositData } = yield call(PaymentService.RequestDepositRasURl, {});
    yield put(depositRasActions.responseDepositRasURl({ success: true, link: DepositData.link }));
  } catch (error) {
    yield put(depositRasActions.responseDepositRasURl({ success: false, link: '', message: 'problems_to_get_ras_addresses' }));
  }
}

export default all([
  takeLatest('@depositRas/requestDepositRasUrl', requestDepositRasUrl),
]);
