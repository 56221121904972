import produce from 'immer';

const INITIAL_STATE = {
  wallets: [],
  currentWallet: '',
  balance: 0,
  inOrder: 0,
  loading: false,
  error: '',
  page: 1,
  per_page: 100,
  total: 0,
};

export default function wallet(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@wallet/requestWallet':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
      });
    case '@wallet/responseWallet':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.payload.error;
        draft.wallets = action.payload.wallets;
        draft.page = action.payload.page;
        draft.pages = action.payload.pages;
        draft.total = action.payload.total;
      });
    case '@wallet/setCurrentWallet':
      return produce(state, (draft) => {
        draft.currentWallet = action.payload.currentWallet;
      });
    // case '@wallet/setWallets':
    //   return produce(state, (draft) => {
    //     draft.wallets = action.payload.wallets;
    //   });
    // case '@wallet/setBalance':
    //   return produce(state, (draft) => {
    //     draft.balance = action.payload.balance;
    //     draft.inOrder = action.payload.inOrder;
    //   });
    default:
      return state;
  }
}
