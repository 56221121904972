import produce from 'immer';

const INITIAL_STATE = {
  history: [],
  error: '',
  message: '',
  withdrawRequested: null,
  loading: false,
  loadingConfirm: false,
  errorConfirm: '',
  messageConfirm: '',
};

export default function deposit(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@withdraw/clearMessages':
      return produce(state, (draft) => {
        draft.error = '';
        draft.message = '';
        draft.errorConfirm = '';
        draft.messageConfirm = '';
      });
    case '@withdraw/requestWithdrawHistory':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
      });
    case '@withdraw/responseWithdrawHistory':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.payload.error;
        draft.history = action.payload.history;
      });
    case '@withdraw/requestNewWithdraw':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
        draft.message = '';
      });
    case '@withdraw/requestNewWithdrawRas':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
        draft.message = '';
      });
    case '@withdraw/responseNewWithdraw':
      return produce(state, (draft) => {
        draft.loading = false;
        if (action.payload.success) {
          draft.message = action.payload.message;
        } else {
          draft.error = action.payload.error;
        }
      });
    case '@withdraw/requestConfirmOrCancelWithdraw':
      return produce(state, (draft) => {
        draft.loadingConfirm = true;
        draft.errorConfirm = '';
        draft.messageConfirm = '';
      });
    case '@withdraw/responseConfirmOrCancelWithdraw':
      return produce(state, (draft) => {
        draft.loadingConfirm = false;
        if (action.payload.success) {
          draft.messageConfirm = action.payload.message;
        } else {
          draft.errorConfirm = action.payload.error;
        }
      });
      //
    case '@withdraw/setRequestedWithdraw':
      return produce(state, (draft) => {
        draft.withdrawRequested = action.payload.withdrawRequested;
      });
    case '@withdraw/setHistory':
      return produce(state, (draft) => {
        draft.history = action.payload.history;
      });
    case '@withdraw/setError':
      return produce(state, (draft) => {
        draft.error = action.payload.error;
      });
    case '@withdraw/setMessage':
      return produce(state, (draft) => {
        draft.message = action.payload.message;
      });
    default:
      return state;
  }
}
