export default {
  dark: {
    default_bg_color: '#12111c',
    content_bg_color: '#12111c',
    content_light_bg_color: '#2A284A',
    header_bg_color: '#041D2F',
    content_selected_bg_color: '#143e76',
    primary_color: '#69c0bc',
    primary_color2: '#D97E55',
    text_color: '#ffff',
    text_color_dark: '#eee',
    register_color: '#ffffff',
    signin_color: '#24EAA3',
    buy_color: '#70ebc1',
    sell_color: '#e87171',
    market_color: '#24C4EA',
    low_color: '#ea5e6e',
    hight_color: '#00ffa7',
    error_color: '#EA2424',
    shadow: `
      -webkit-box-shadow: 5px 3px 25px -5px rgba(0,0,0,0.68);
      box-shadow: 5px 3px 25px -5px rgba(0,0,0,0.68);
    `,
    shadow_button: `
      -webkit-box-shadow: 6px 10px 29px -9px rgba(247, 146, 30, 0.5);
      -moz-box-shadow: 6px 10px 29px -9px rgba(247, 146, 30, 0.5);
      box-shadow: 6px 10px 29px -9px rgba(247, 146, 30, 0.5);
    `,
  },
};
