import React, { useEffect, useMemo } from 'react';

import Qrcode from 'react-qr-code';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { MdContentCopy } from 'react-icons/md';
import * as DepositActions from '~/store/modules/deposit/actions';

import { ToastSuccess } from '~/utils/toast';
import { LoadingAnimation } from '~/components/animation';
import { Svg } from '~/components/iconSet';
import { Container, Button, ContainerFullAddress } from './styles';
import { ReactComponent as DepositIcon } from '~/assets/svg/deposit.svg';

function WalletQrComponent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { currentWallet } = useSelector((state) => state.wallet);
  const { address, loading } = useSelector((state) => state.deposit);

  const conditionalRender = useMemo(() => currentWallet.Currency === 'BLU' || currentWallet.Currency === 'TREEP'  || currentWallet.Currency === 'XLM', [currentWallet]);


  const tagDiv = useMemo(() => {
    switch(currentWallet.Currency){
      case "TREEP":
        return "?memo="
      case "XLM":
        return "?memo="
      case
       "BLU":
        return "_"
    }
  }, [currentWallet]);


  const bluAddress = useMemo(() => {
 let tagRef = ""
    if (address && conditionalRender) {
      switch(address.currency){
        case "BLU":
          tagRef = "_"
          break;
          case "TREEP":
            tagRef = "?memo="
          break;
          case "XLM":
            tagRef = "?memo="
          break;
      }
      const [adr, tag] = address.address.split(tagRef);
      return { ...address, address: adr, tag };
    }
    return null;
  }, [address]);




  useEffect(() => {
    dispatch(DepositActions.requestAddress({ currency: currentWallet.Currency }));
    dispatch(DepositActions.requestDeposits({ currency: currentWallet.Currency }));
  }, [currentWallet, dispatch]);

  function handleCopyPress(text) {
    navigator.clipboard.writeText(text);
    ToastSuccess(t('copied'));
  }

  function handleCreatePress() {
    dispatch(DepositActions.requestCreateNewAddress({ currency: currentWallet?.Currency }));
  }

  return (
    <Container title="deposit">
      <div className="flag_top_left">
        <DepositIcon width={30} />
      </div>
      <div className="header_qrcode">
        <Svg name={currentWallet?.Currency} size={40} />
        <div>
          <h1>{currentWallet?.Currency}</h1>
          <h4>{t('deposit')}</h4>
        </div>
      </div>
      <div className="body_qrcode">
        {loading && <LoadingAnimation width={100} height={100} />}
        {!loading && (
          <>
            {address && (

              
              <>
              {(currentWallet.Currency != "TREEP") ? (
                    <><ContainerFullAddress>
                    <p>
                    {bluAddress ? `${bluAddress.address}`  : address.address}
                    </p>
                    <MdContentCopy size={20} color="#fff" onClick={() => handleCopyPress(bluAddress ? `${bluAddress.address}`  : address.address)} />
                  </ContainerFullAddress> </>
                  ):(<div id="container-tag-footer">
                  {bluAddress?.tag && (
                    <>
                      <p>
                        Memo:
                        {' '}
                      </p>
                      <div id="blu-tag-footer">{bluAddress?.tag}</div>
                    </>
                  )}
                </div>)}
              </>
            )}
            <div className="qr_code_alert">
              <p>
                {(currentWallet.Currency != "TREEP") && <>
                {t('deposit_header_message', { crypto: currentWallet.Currency })}
                </>}
               
                <br></br>
                <br></br>
                {(currentWallet.Currency == "TREEP") && (
                    <div>Os depósitos em Treep somente serão aceitos quando forem provenientes da carteira da DIGRAW.
                    Atenção!!! Treeps provenientes de outras carteiras ou exchanges serão perdidos.
                    Para maiores informações sobre o Treep Token acesse https://digraw.com.br</div>
                  )}
              </p>
            </div>

            {!address && !loading && (
              <Button onClick={() => handleCreatePress()}>{t('create')}</Button>
            )}

            {address && !loading && currentWallet.Currency != "TREEP" && (
              <div className="container-signin-qrcode">
                <Qrcode
                  size={150}
                  value={bluAddress ? `${bluAddress.address}` : address.address}
                />
              </div>
            )}

            <div className="qr_code_note">
              {conditionalRender && currentWallet.Currency != "TREEP"  ? (
                <>
                  <div id="container-tag-footer">
                    {bluAddress?.tag && (
                      <>
                        <p>
                          {address.currency} Memo:
                          {' '}
                        </p>
                        <div id="blu-tag-footer">{bluAddress?.tag}</div>
                      </>
                    )}
                  </div>
                  <Trans t={t} values={{ crypto: currentWallet.Currency }}>deposit_notes_blu</Trans>

                </>
              ) : (
                <div className="footer-qrcode">
                  <div>
                    <Trans t={t}>deposit_notes</Trans>
                  </div>
                  {currentWallet?.Currency === 'RAS' && (
                    <div className="footer-qrcode-ras clickable" onClick={() => history.push('/ras/deposit')}>
                      <p>{t('deposit_real_with_ras')}</p>
                      <img alt="real deposit" src="/depositreal.png" />
                    </div>
                  )}
                </div>
              )}

            </div>
          </>
        )}
      </div>
    </Container>
  );
}

export default WalletQrComponent;
