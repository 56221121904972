import React,{ useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Input } from '~/styles/components/inputs';
import {
  Container,
  ContainerButtons,
  ButtonOrder,
  ContainerInputs,
} from './styles';
import { LoadingAnimation } from '~/components/animation';
import * as OrderActions from '~/store/modules/order/actions';
import * as ProductActions from '~/store/modules/product/actions';
import { ToastFailed } from '~/utils/toast';

export default function BookBuyComponent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [baseCurrencyDirect, setBaseCurrencyDirect] = useState(["BTC","WBX"]);
  const [quoteCurrencyDirect, setQuoteCurrencydirect] = useState(["RAS","ZAR"]);

  const { loadingDirectPrice } = useSelector((state) => state.order);
  const { wallets } = useSelector((state) => state.wallet);
  const { baseCurrency,products } = useSelector((state) => state.product);
  const { token } = useSelector((state) => state.auth);
  const formValuesDirect = useSelector((state) => state.order.formValuesDirect);

  const { directPrice } = useSelector((state) => state.order);

  React.useEffect(() => {
    dispatch(ProductActions.requestProducts())
    dispatch(OrderActions.requestDirectPrice({productId:"BTC-RAS",type:formValuesDirect.current == 0 ? "buy":"sell" }));
  }, [dispatch]);
  React.useEffect(() => {
    console.log(products)
    let filterAcceptList = products.filter(item => item.accept_direct)
    var baseCurrency = filterAcceptList.map(item => item.baseCurrency)
    var quoteCurrency =  filterAcceptList.map(item => item.quoteCurrency)
    setBaseCurrencyDirect(baseCurrency)
    setQuoteCurrencydirect([...new Set(quoteCurrency)])
    handleChangeCurrency({target:{name:"baseCurrency",value:baseCurrency[0]}})
  }, [products]);


  const renderFraction = ({price,product}) => {
    let priceIn = price ? price:0;
    let min  = product.QuoteMinSize ? product.QuoteMinSize: 0
    let priceFormated  =  (priceIn * parseFloat(min)).toFixed(product.QuoteScale)
    return (<>
        {priceFormated} = {product.QuoteMinSize} {product?.BaseCurrency}
    </>)
  }

  const renderPrice = ({price,product}) => {
    return parseFloat(price).toFixed(product.QuoteScale) 
  }


  const requestPrice = ( values ) => {
    values.quoteCurrencyAmount = 0
    values.baseCurrencyAmount = 0
    dispatch(OrderActions.setFormValuesDirect({
      ...values,
    }));
    if (values.baseCurrency != undefined &&   values.quoteCurrency != undefined)  {
      dispatch(OrderActions.requestDirectPrice({productId:`${values.baseCurrency}-${values.quoteCurrency}`,type:values.current == 0 ? "buy":"sell" }));
    }
    
  }
  

  function handleChangeCurrent(index) {
    dispatch(OrderActions.setFormValuesDirect({
      ...formValuesDirect,
      current: index,
    }));
    requestPrice({
      ...formValuesDirect,
      current: index,
    })
  }

  function handleChangeCurrency(event) {
    const { name, value } = event.target;
    let newValues = { ...formValuesDirect };
    newValues[name] = value;
    dispatch(OrderActions.setFormValuesDirect({
      ...newValues,
    }));
    requestPrice(newValues)

  }

  function handleChangeValue(event) {
    const { name, value } = event.target;
  
    if (value !== '') {
      const regex = new RegExp(/^[\d.]+$/, 'g');
      if (!regex.test(value)) return true;
    }

    let newValues = { ...formValuesDirect };
    newValues[name] = value;
    switch(name){
      case "quoteCurrencyAmount":
        newValues.baseCurrencyAmount = (  newValues.quoteCurrencyAmount / directPrice.price).toFixed(directPrice.product.BaseScale) 
      break;
      case "baseCurrencyAmount":
        newValues.quoteCurrencyAmount = ( newValues.baseCurrencyAmount * directPrice.price).toFixed(directPrice.product.QuoteScale) 
      break;
    }
    dispatch(OrderActions.setFormValuesDirect({
      ...newValues,
      current: formValuesDirect.current,
    }));
  }

  function handleSendOrder() {
    if (!token) {
      ToastFailed('you_are_not_logged');
      return history.push('/signin');
    }
    const { amount, total, quoteCurrencyAmount,baseCurrencyAmount } = formValuesDirect;

    const { product } = directPrice


    const baseCrypto = wallets.find((w) => w.Currency === product?.BaseCurrency);
    const quoteCrypto = wallets.find((w) => w.Currency === product?.QuoteCurrency);

    // if (!parseFloat(total)) {
    //   return ToastFailed('invalid_value');
    // }

    console.log(product)

    console.log(baseCrypto.Available)

    if (formValuesDirect.current === 0) {
      if (parseFloat(quoteCurrencyAmount) > parseFloat(quoteCrypto.Available)) {
        return ToastFailed('you_dont_have_funds_in', { crypto: quoteCrypto.Currency });
      }
    } else if (parseFloat(baseCurrencyAmount) > parseFloat(baseCrypto.Available)) {
      return ToastFailed('you_dont_have_funds_in', { crypto: baseCrypto.Currency });
    }

    console.log(product)

    const productId = product?.Id;
    const side = formValuesDirect.current ? 'sell' : 'buy';
    const type = 'direct';
    dispatch(OrderActions.requestCreateOrder({ productId, side, size: baseCurrencyAmount, price: formValuesDirect.price, type }));
  }


  const rendPriceDisplay = () => {
    return (<>
        <div className="container-display-book">
          <span className="span-label">Preço por {formValuesDirect?.baseCurrency}</span>
          <span className="span-label strong">1 {formValuesDirect?.baseCurrency} = {formValuesDirect?.quoteCurrency} {renderPrice(directPrice)}</span>
        </div>
        <div className="container-display-book">
          <span className="span-label"></span>
          <span className="span-label strong"> 1 RAS = 1 Real</span>
        </div>
    </>)
  }
  

  const baseCurrencyRender = () => {
    return(<>
      <div className="container-input-market">
        <span className="span-input-currency">
            <select 
              value={formValuesDirect.baseCurrency}
              name="baseCurrency"
              onChange={handleChangeCurrency}>
              {baseCurrencyDirect.map(item => <option key={item} value={item}>{item}</option>)}
            </select>
        </span>
        <Input
          name="baseCurrencyAmount"
          type="text"
          value={formValuesDirect.baseCurrencyAmount}
          onChange={handleChangeValue}
        />
      </div>
    </>)
  }

  const quoteCurrencyRender = () => {
    return(<>
      <div className="container-input-market">
        <span className="span-input-currency">
            <select 
              name="quoteCurrency"
              value={formValuesDirect.quoteCurrency}
              onChange={handleChangeCurrency}>
              {quoteCurrencyDirect.map(item => <option key={item} value={item}>{item}</option>)}
            </select>
        </span>
        <Input
          name="quoteCurrencyAmount"
          type="text"
          id="input-market"
          value={formValuesDirect.quoteCurrencyAmount}
          onChange={handleChangeValue}
        />
      </div>
    </>)
  }

  const renderSell = () => {
    return (<>
        <div className="container-input-book">
          <span className="span-label">{t('direct_want_sell')}</span>
          {baseCurrencyRender()}

        </div>
        {rendPriceDisplay()}
        <div className="container-input-book">
          <span className="span-label"> {t('direct_want_sell_total')}</span>
           {quoteCurrencyRender()}
        </div>

    </>)
  }

  const renderBuy = () => {
    return (<>
      <div className="container-input-book">
          <span className="span-label"> {t('direct_want_buy')}</span>
            {quoteCurrencyRender()}
        </div>
        {rendPriceDisplay()}
        <div className="container-input-book">
          <span className="span-label">{t('direct_want_buy_total')}</span>
            {baseCurrencyRender()}
        </div>
    </>)
  }

  return (
    <Container className="bg-content">
      {loadingDirectPrice && (
        <div className="container-animation">
          <LoadingAnimation width={100} height={100} />
        </div>
      )}
      <ContainerButtons>
        <ButtonOrder
          className="button-buy"
          selected={formValuesDirect.current === 0}
          onClick={() => handleChangeCurrent(0)}
        >
          {t('buy')}
        </ButtonOrder>
        <ButtonOrder
          className="button-sell"
          selected={formValuesDirect.current === 1}
          onClick={() => handleChangeCurrent(1)}
        >
          {t('sell')}
        </ButtonOrder>
      </ContainerButtons>
      <ContainerInputs current={formValuesDirect.current}>
      
            {formValuesDirect.current === 0 ? renderBuy(): renderSell()}
        
        <ButtonOrder
          className="button-buy"
          selected={formValuesDirect.current === 0}
          onClick={handleSendOrder}
        >
           
          Executar
        </ButtonOrder>
      </ContainerInputs>
    </Container>
  );
}
