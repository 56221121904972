import produce from 'immer';

const INITIAL_STATE = {
  deposits: [],
  error: '',
  loading: false,
  address: null,
  loadingDeposits: false,
  errorDeposits: '',
};

export default function deposit(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@deposit/requestAddress':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
      });
    case '@deposit/responseAddress':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.payload.error;
        draft.address = action.payload.address;
      });
    case '@deposit/requestDeposits':
      return produce(state, (draft) => {
        draft.loadingDeposits = true;
        draft.errorDeposits = '';
      });
    case '@deposit/responseDeposits':
      return produce(state, (draft) => {
        draft.loadingDeposits = false;
        draft.errorDeposits = action.payload.error;
        draft.deposits = action.payload.deposits;
      });
    case '@deposit/requestCreateNewAddress':
      return produce(state, (draft) => {
        draft.loadingDeposits = true;
        draft.errorDeposits = '';
      });
    case '@deposit/responseCreateNewAddress':
      return produce(state, (draft) => {
        draft.loadingDeposits = false;
        if (!action.payload.success) {
          draft.errorDeposits = action.payload.error;
        }
      });
    default:
      return state;
  }
}
