import Swal from 'sweetalert2';
import i18next from 'i18next';

export function ToastSuccess(message, params) {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: i18next.t(message, params),
    showConfirmButton: false,
    timer: 2000,
    toast: true,
    customClass: {
      popup: 'toast-popup',
    },
  });
}

export function ToastFailed(message, params) {
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: i18next.t(message, params),
    showConfirmButton: false,
    timer: 2000,
    toast: true,
  });
}
