import styled, { keyframes } from 'styled-components';

const mount = keyframes`
  0% {
    max-height:0px;
    margin-top:0px;
    opacity:0;
  }
  100% {
    max-height:300px;
    margin-top:10px;
  }
`;

const unmount = keyframes`
  0% {
    max-height:0px;
    margin-top:0px;
    opacity:0;
  }
  100% {
    max-height:0px;
    margin-top:0px;
    opacity:0;
  }
`;

export const Container = styled.section`
  flex: 1;
  background-color: var(--content-bg-color);
  padding: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  min-height: 80px;
  margin-top:10px;

  @media (min-width: 1100px) {
    & {
      margin-top: 0px;
      flex: 0;
    }
  }
`;

export const Coin = styled.div`
  cursor: pointer;
  flex: 1;
  background-color: ${({ theme }) => theme.content_light_bg_color};
  padding: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  min-height:60px;

  &.first-coin {
    margin-right: 5px;
  }
  & .info {
    margin-left: 5px;
    margin-right: 5px;
  }

  & .info.info-icon-currency{
    flex:1;
    text-align:center;
  }

  & h1 {
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
  }

  & h3 {
    font-size: 14px;
    color: #fff;
  }

  @media (min-width: 350px) {
    &.first-coin {
      margin-right: 15px;
    }
  }

  @media (min-width: 1100px) {
    & {
      min-width: 150px;
    }
  }
`;

export const ListDown = styled.div`
  background-color:${({ theme }) => theme.default_bg_color};
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  position: absolute;
  top: 100%;
  left: 0px;
  width: 103%;
  z-index: 100;
  display:flex;
  max-height:0px;
  overflow:auto;
  overflow-x:visible;
  /* display: ${({ show }) => (show ? 'flex' : 'none')}; */
  /* animation: ${mount} 1s ease-out forwards; */
  animation: ${({ show }) => (show ? mount : unmount)} 0.5s forwards;

  & > div {
    margin-top: 5px;
  }
`;
