import { put, takeEvery, all, takeLatest, call, select } from 'redux-saga/effects';
import * as tradeActions from './actions';
import * as BookService from '~/services/api/book.api';

export function* requestTrades(action) {
  try {
    const { pairId } = action.payload;
    const { data } = yield call(BookService.RequestTrades, { pairId });
    yield put(tradeActions.responseTrades({ success: true, trades: data, error: '' }));
  } catch (error) {
    yield put(tradeActions.responseTrades({
      success: false,
      trades: [],
      error: error?.response?.data?.message }));
  }
}

/**
 * TRADE RECEIVED BY SOCKET
 *{
 *  "type":"match",
 *  "tradeId":31,
 *  "sequence":121,
 *  "time":"2020-07-13T23:54:07Z",
 *  "productId":"BTC-RAS",
 *  "price":"100",
 *  "size":"1",
 *  "makerOrderId":"60",
 *  "takerOrderId":"61",
 *  "side":"sell"
  }
 *
 */

export function* listenSocketResponse(action) {
  const { data } = action.payload;
  if (data.type === 'match') {
    const { baseCurrency } = yield select((state) => state.product);
    if (baseCurrency.id === data.productId) {
      yield put(tradeActions.addNewTrade({ trade: data }));
    }
  }
}

export default all([
  takeEvery('@socket/response', listenSocketResponse),
  takeLatest('@trade/requestTrades', requestTrades),
]);
