import { combineReducers } from 'redux';

import general from './general/reducers';
import socket from './socket/reducers';
import auth from './auth/reducers';
import register from './register/reducers';
import wallet from './wallet/reducers';
import swap from './swap/reducers';
import book from './book/reducers';
import fee from './fee/reducers';
import trade from './trade/reducers';
import order from './order/reducers';
import product from './product/reducers';
import deposit from './deposit/reducers';
import depositRas from './depositRas/reducers';
import withdraw from './withdraw/reducers';
import tradingView from './tradingView/reducers';

const reducers = combineReducers({
  general,
  socket,
  auth,
  register,
  wallet,
  swap,
  book,
  fee,
  trade,
  order,
  product,
  deposit,
  depositRas,
  withdraw,
  tradingView,
});

export default reducers;
