import styled from 'styled-components';

export const Container = styled.section`
  display: block;
  width: 100%;
  height:400px;
  min-height: 400px;
  margin-top: 10px;
  padding: 0px;
  background-color: ${({ theme }) => theme.content_bg_color};

  & .tradingview-widget-container {
    width: 100%;
    height: 100%;
    padding: 0px;
  }
`;
