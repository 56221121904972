import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as WalletActions from '~/store/modules/wallet/actions';

import {
  Container,
  ContainerSelectDraw,
  ContainerWallet,
  ContainerCryptoInfo,
  CardWallet,
} from './styles';

import { Svg } from '~/components/iconSet';
import { HeaderBook } from '~/components/headers';
import { WalletQrCode, WalletHistoryDeposit, WalletHistoryWithdraw, WalletWithdraw } from '~/components/wallet';

import { ReactComponent as WithdrawIcon } from '~/assets/svg/withdraw.svg';
import { ReactComponent as DepositIcon } from '~/assets/svg/deposit.svg';

import * as FeeActions from '~/store/modules/fee/actions';

export default function WalletPage({ match }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [type, setType] = useState('deposit');
  const { wallets: walletsReceived, currentWallet } = useSelector(
    (state) => state.wallet,
  );
  const { fees } = useSelector((state) => state.fee);

  React.useEffect(() => {
    if (!fees.length) {
      dispatch(FeeActions.requestFee());
    }
  }, [dispatch, fees]);

  const walletsWithPriceInDolar = useMemo(() => walletsReceived.map((wallet) => {
    const fee = fees.find((f) => f.name === wallet.Currency);
    if (fee) {
      return { ...wallet, price_dolar: parseFloat(wallet.Available) * parseFloat(fee.price_dolar) };
    }
    return { ...wallet, price_dolar: 0 };
  }), [walletsReceived, fees]);

  useEffect(() => {
    if (match.params.crypto) {
      if (!walletsReceived.find((wlt) => wlt.Currency === match.params.crypto)) {
        return history.replace('/wallet');
      }
      const { crypto } = match.params;
      // dispatch(DepositActions.requestAddresses({ crypto }));
      // dispatch(WithdrawActions.requestWithdraw({ crypto }));
      // dispatch(DepositActions.requestDeposits({ crypto }));
      const wallet = walletsReceived.find((w) => w.Currency === crypto);
      dispatch(WalletActions.setCurrentWallet({ currentWallet: wallet }));
    } else {
      dispatch(WalletActions.setCurrentWallet({ currentWallet: null }));
    }
  }, [match, walletsReceived, dispatch, history]);

  const prevButton = useMemo(() => {
    if (currentWallet) {
      return (
        <Link to="/wallet" className="link-to-back">
          <AiOutlineArrowLeft />
          <p>
            {`   ${t('back')}`}
          </p>
        </Link>
      );
    }
    return null;
  }, [currentWallet, t]);

  function handleChangeTypeWallet({ type: typeReceived }) {
    setType(typeReceived);
  }

  function handleSelectWallet({ wallet }) {
    history.push(`/wallet/${wallet.Currency}`);
  }

  return (
    <>
      <HeaderBook />
      <Container>
        <ContainerSelectDraw>
          {prevButton}
          <div
            role="button"
            tabIndex={-1}
            className={`wallet_select ${
              type === 'deposit' ? 'deposit_active' : ''
            }`}
            onClick={() => handleChangeTypeWallet({ type: 'deposit' })}
          >
            <DepositIcon width={30} />
            <p>{t('deposit')}</p>
          </div>
          <div
            role="button"
            tabIndex={-1}
            className={`wallet_select ${
              type === 'withdraw' ? 'withdraw_active' : ''
            }`}
            onClick={() => handleChangeTypeWallet({ type: 'withdraw' })}
          >
            <WithdrawIcon width={30} />
            <p>{t('withdraw')}</p>
          </div>
        </ContainerSelectDraw>
        {currentWallet && type === 'deposit' && (
          <ContainerCryptoInfo>
            <WalletQrCode />
            <WalletHistoryDeposit />
          </ContainerCryptoInfo>
        )}

        {currentWallet && type === 'withdraw' && (
          <ContainerCryptoInfo>
            <WalletWithdraw />
            <WalletHistoryWithdraw />
          </ContainerCryptoInfo>
        )}

        {!currentWallet && (
          <>
            <ContainerWallet>
              {walletsWithPriceInDolar.map((wallet) => (
                <CardWallet
                  // disabled={maintenanceWallets.includes(wallet.Currency)}
                  key={wallet.id}
                  role="button"
                  tabIndex={0}
                  className="wallet_item"
                  onClick={() => handleSelectWallet({ wallet })}
                >
                  {/* {maintenanceWallets.includes(wallet.Currency) && (
                    <span className="maintenance_flag">maintenance</span>
                  )} */}
                  <Svg name={wallet.Currency} size={30} />
                  <h1>{wallet.Currency}</h1>
                  <div className="wallet_item_info">
                    {/* <div className="wallet_item_row">
                      <p>{t('available')}</p>
                      <p>{wallet.Balance}</p>
                    </div> */}
                    <div className="wallet_item_row">
                      <p>{t('in_order')}</p>
                      <p>{parseFloat(wallet.Book).toFixed(wallet.quoteScale)}</p>
                    </div>
                    <div className="wallet_item_row">
                      <p>{t('balance')}</p>
                      <p>{parseFloat(wallet.Available).toFixed(wallet.quoteScale)}</p>
                    </div>
                    {/* <div className="wallet_item_row price_in_dolar">
                      <p>{`=$ ${wallet?.price_dolar?.toFixed(2)}`}</p>
                    </div> */}
                    {/* <div className="wallet_item_row wallet_item_opacity">
                      <p>{t('amount')}</p>
                      <p>
                        ≅$
                        {' '}
                        {wallet.Price ? (wallet.Balance * wallet.Price).toFixed(2) : '0.00'}
                      </p>
                    </div> */}
                  </div>
                </CardWallet>
              ))}
            </ContainerWallet>
          </>
        )}
      </Container>
    </>
  );
}

WalletPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      crypto: PropTypes.string,
    }),
  }).isRequired,
};
