import { put, delay, take, call, takeLatest, all, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as generalActions from './actions';
import * as productActions from '../product/actions';
import { commands } from '~/config/environments';

/**
 * Insere a chave do comando no atributo loading do reducer
 * para simular um carregamento de tela
 */
export function* timeLoading(action) {
  if (action.payload) {
    const { cmd } = action.payload;

    // ignorar ping no loading
    if (cmd === -1) return;
    let keyLoading = '';
    Object.keys(commands).forEach((key) => {
      if (commands[key] === cmd) {
        keyLoading = key;
      }
    });
    if (keyLoading) {
      yield put(generalActions.setLoading({ name: keyLoading, value: true }));
      yield delay(1000);
      yield put(generalActions.setLoading({ name: keyLoading, value: false }));
    }
  }
}

export function* channelCurrentBrowserTabFocus() {
  // Set the name of the hidden property and the change event for visibility
  let hidden; let visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  let channel = null;
  // Warn if the browser doesn't support addEventListener or the Page Visibility API
  if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
    console.log('This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.');
  } else {
    // Handle page visibility change
    channel = eventChannel((emitter) => {
      document.addEventListener(visibilityChange, (e) => emitter(e), false);
      return () => {
        document.removeEventListener(visibilityChange, () => {}, false);
      };
    });
  }
  return channel;
}

export function* listenCurrentTabFocus() {
  try {
    let hidden;

    if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
    }

    const channel = yield call(channelCurrentBrowserTabFocus);

    if (!channel) return;

    while (true) {
      yield take(channel);
      const { baseCurrency } = yield select((state) => state.product);
      if (document[hidden]) {
        yield put(productActions.unsubscribeBaseCurrency({ baseCurrency }));
      } else {
        yield put(productActions.subscribeBaseCurrency({ baseCurrency }));
      }
    }
  } catch (error) {
    console.log('[error] listenCurrentTabFocus');
  }
}

export default all([
  takeLatest('@general/listenCurrentTabFocus', listenCurrentTabFocus),
]);
