import React, { useState, useRef, useEffect } from 'react';
import { FaPlay } from 'react-icons/fa';
import { HeaderBook } from '~/components/headers';
import { Container, ContainerVideo } from './styles';
import { videosUrls } from '~/config/environments';

export default function VideosPage() {
  const containerRef = useRef(null);
  const [currentVideo, setCurrentVideo] = useState();

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        setCurrentVideo(null);
      }
    });
    return () => document.removeEventListener('keydown', () => {});
  }, []);

  function handleClosePress() {
    setCurrentVideo(null);
  }

  function handleSetItem(item) {
    containerRef.current.parentNode.scrollTo(0, 0);
    setCurrentVideo(item);
  }

  return (
    <>
      <Container name="book" ref={containerRef}>
        <HeaderBook />
        {Object.keys(videosUrls).map((key) => (
          <div key={key} className="container-videos">
            <h1 className="videos-title">{key}</h1>
            <div className="container-horizontal-videos">
              {videosUrls[key].map((videoItem) => (
                <div key={videoItem.thumb} role="button" tabIndex="0" className="videos-card" onClick={() => handleSetItem(videoItem)}>
                  <FaPlay color="#fff" size={35} id="play-icon-video" />
                  <img src={videoItem.thumb} alt="thumbnail do youtube " />
                </div>
              ))}
            </div>
          </div>
        ))}

        {currentVideo && (
          <ContainerVideo onClick={handleClosePress}>
            <iframe title="video youtube" src={currentVideo.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </ContainerVideo>
        )}

      </Container>

    </>
  );
}
