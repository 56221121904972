import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  linkToDeposit: '',
  message: '',
};

export default function depositRas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@depositRas/requestDepositRasUrl':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case '@depositRas/responseDepositRasUrl':
      return produce(state, (draft) => {
        draft.loading = false;
        if (action.payload.success) {
          draft.linkToDeposit = action.payload.link;
        } else {
          draft.message = action.payload.message;
        }
      });
    default:
      return state;
  }
}
