import Axios from 'axios';
import { getToken } from '../safeToken';

import endpoints from '../../config/endpoints';

const Withdraw = Axios.create({
  baseURL: endpoints.wallet,
});

Withdraw.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export function RequestWithdeawHistory({ page, per_page, query }) {
  return Withdraw.post('/web/v1/client/withdraws', { page, per_page, query });
}

export function RequestNewWithdraw({ currency, amount, otp, address }) {
  return Withdraw.post('/web/v1/client/withdraw', { currency, amount, otp, address });
}

export function RequestNewWithdrawRas({ amount, otp }) {
  return Withdraw.post('/client/withdraw', { amount, otp });
}

export function ConfirmWithdraw({ code, withdraw_id }) {
  return Withdraw.post('/web/v1/client/withdraw/confirm', { code, withdraw_id });
}

export function CancelWithdraw({ withdraw_id }) {
  return Withdraw.delete(`/web/v1/client/withdraw/${withdraw_id}`);
}

export function CheckAddress({ address, currency }) {
  return Withdraw.get(`/web/v1/client/withdraw/fee/${address}/${currency}`);
}
