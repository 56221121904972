import React, { useState, useEffect, useMemo } from 'react';

import { FaWallet } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toggleMenu } from '~/store/modules/general/actions';
import { Container, SignButtons } from './styles';
import { Button } from '~/styles/components/buttons';
import MazzeraName from '~/assets/png/mazzera-logo.png';
import * as walletActions from '~/store/modules/wallet/actions';
import * as FeeActions from '~/store/modules/fee/actions';

export default function HeaderWallet() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token, user, step_session } = useSelector((state) => state.auth);
  const { wallets } = useSelector((state) => state.wallet);
  const { fees } = useSelector((state) => state.fee);
  const [hideWallet, setHideWallet] = useState(true);
  const history = useHistory();

  useEffect(() => {
    dispatch(FeeActions.requestFee());
    if (token && step_session >= 1) {
      dispatch(walletActions.requestWallet({ page: 1, per_page: 100 }));
    }
  }, [dispatch, token, step_session]);

  const walletData = useMemo(() => {
    let available = 0;
    let inOrder = 0;
    let total = 0;

    wallets.forEach((w) => {
      const feeSelected = fees.find((item) => item.name === w.Currency);
      inOrder += parseFloat(w.Book) * parseFloat(feeSelected?.price_dolar || 0);
      available += parseFloat(w.Available) * parseFloat(feeSelected?.price_dolar || 0);
      total += (parseFloat(w.Book) + parseFloat(w.Available)) * parseFloat(feeSelected?.price_dolar || 0);
    });
    return { available, inOrder, total };
  }, [wallets, fees]);

  function handleToggleWallet() {
    setHideWallet(!hideWallet);
  }

  function handleToggleMenu() {
    dispatch(toggleMenu({ menuOpen: true }));
  }
  function handleLoginPress() {
    history.push('/signin');
  }
  function handleRegisterPress() {
    history.push('/register');
  }

  return (
    <Container className="bg-content" title="wallet">
      <div className="container-header-wallet-icon">
        {/* <StratumIcon width={40} onClick={handleToggleMenu} /> */}
        <img src={MazzeraName} className="stratumx-icon" onClick={handleToggleMenu} />
      </div>
      {token && step_session === 1 && (
        <div
          role="button"
          tabIndex={0}
          className="wallet-container-button"
          onClick={handleToggleWallet}
        >
          <div className="wallet-button">
            <h1 className="m-font">{user?.user_name}</h1>
          </div>
          <div
            className={`wallet-container-values ${
              hideWallet ? 'wallet-hide' : ''
            }`}
          >
            <span>
              {/* <FaWallet size={20} />
              <p>{t('wallet')}</p> */}
            </span>
            {/* <div className="wallet-content">
              <div>
                <p>{t('total_value')}</p>
                <p>{`$ ${(walletData.total).toFixed(2)}`}</p>
              </div>
              <div>
                <p>{t('in_order')}</p>
                <p>{`$ ${walletData.inOrder.toFixed(2)}`}</p>
              </div>
              <div className="wallet-available">
                <p>{t('available')}</p>
                <p>{`$ ${walletData.available.toFixed(2)}`}</p>
              </div>
            </div> */}
          </div>
        </div>
      )}

      {(step_session === 0) && (
        <SignButtons>
          <Button className="button-signin" onClick={handleLoginPress}>
            {t('login')}
          </Button>
          <Button className="button-register" onClick={handleRegisterPress}>
            {t('register')}
          </Button>
        </SignButtons>
      )}
    </Container>
  );
}
