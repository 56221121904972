import styled from 'styled-components';

export const Container = styled.section`
  flex: 1;
  display:flex;
  padding: 10px;
  padding-bottom:50px;
  align-items:center;
  justify-content:center;
`;

export const ContainerCryptoInfo = styled.div`
  margin-top:20px;
  width:80%;
  & section:nth-child(2) {
    margin-top: 10px;
  }

  @media (min-width: 1000px) {
    & {
      flex-direction: row;
    }
    & section:nth-child(2) {
      margin-top: 0px;
      margin-left: 10px;
    }
  }
`;

export const ContainerSelectDraw = styled.div`
  align-self: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;

  & .link-to-back {
    position: absolute;
    left: 0px;
    flex-direction: row;
    padding: 10px;
    padding-left: 0px;
    top: calc(50%-15px);
    svg {
      margin-bottom: -2px;
      margin-right: 10px;
    }
  }

  & .wallet_select {
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & p {
      margin-left: 10px;
    }
  }
  & .deposit_active {
    background-color: ${({ theme }) => theme.buy_color};
  }

  & .withdraw_active {
    background-color: ${({ theme }) => theme.sell_color};
  }

  @media( max-width:400px){
    & .link-to-back>p{
      display:none;
    }
  }
  @media (min-width: 600px) {
    & .wallet_select {
      margin: 10px;
      padding: 15px;
      border-radius: 10px;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

    }
    & .wallet_select  p {
      font-size: 15px;
    }
    & .link-to-back p{
      font-size:15px;
    }
  }
`;
