import React from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import * as GeneralActions from '~/store/modules/general/actions';
import * as AuthActions from '~/store/modules/auth/actions';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TradeIcon } from '~/assets/svg/trade_icon.svg';
import { ReactComponent as WalletIcon } from '~/assets/svg/wallet_icon.svg';
import { ReactComponent as MoneyIcon } from '~/assets/svg/money_icon.svg';
import { ReactComponent as SettingIcon } from '~/assets/svg/settings_icon.svg';
import { ReactComponent as OtcIcon } from '~/assets/svg/otc_icon.svg';
import { ReactComponent as LogoutIcon } from '~/assets/svg/logout_icon.svg';
import { ReactComponent as BtcqIcon } from '~/assets/svg/icon_btcq.svg';
import ReactTooltip from 'react-tooltip';

import {  
  FaClipboardCheck
   } from 'react-icons/fa';

import { Container } from './styles';

/**
 * Used in routes
 */
export default function LayoutMenu({ children, noShow }) {
  const { menuOpen } = useSelector((state) => state.general);
  const { token, step_register, step_session } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const pathname = React.useMemo(() => location.pathname, [location]);
  const hideLeftMenu = React.useMemo(() => pathname.includes('/newpassword') || pathname.includes('/signin') || pathname.includes('/register') || pathname.includes('validateemail'), [pathname]);
  const logged = React.useMemo(() => !!token && step_register === 2 && step_session === 1, [token, step_register]);


  function handleLogout() {
    dispatch(AuthActions.logout());
  }

  if (hideLeftMenu) {
    return (
      <Container noShow={noShow.includes(pathname)}>
        <section className="menu-container-content">{children}</section>
      </Container>
    );
  }

  return (
    <Container noShow={noShow.includes(pathname)}>
      <nav className={`menu bg-content menu-bg ${menuOpen ? '' : 'hide'}`}>
        <ul>
        {logged && (
              <>
                <li className="new">
                  <div className="newItem" data-tip data-for='newDecription'>
                  <ReactTooltip id='newDecription'>
                  <span>{t('direct_new_description')}</span>
                </ReactTooltip>
                  </div>
                
                  <Link
                    to="/direct"
                    className={
                      location.pathname === '/direct' ? 'menu-item-active' : '','item'
                    }
                  >
                    <FaClipboardCheck size={20} />
                  </Link>
                  <div className="title">  {t('menu.direct') }</div>
                </li>
              </>
            )}
          <li>
            <Link
              to="/trade"
              className={
                location.pathname === '/trade' ? 'menu-item-active' : '','item'
              }
            >
              <TradeIcon className="svg_icon" width={15} />
            </Link>
            <div className="title">  {t('menu.trade') }</div>
          </li>
          {logged && (
            <>
            <li>
              <Link
                to="/wallet"
                className={
                location.pathname.includes('/wallet') ? 'menu-item-active' : '','item'
              }
              >
                <WalletIcon className="svg_icon" width={15} />
              </Link>
              <div className="title">  {t('menu.wallet') }</div>
            </li>
            <li>
            <Link
              to="/fiat"
              className={
              location.pathname.includes('/fiat') ? 'menu-item-active' : '','item'
            }
            >
              <MoneyIcon className="svg_icon" width={15} />
            </Link>
            <div className="title">  {t('menu.fiat') }</div>
          </li>
          </>
          )}
          <li>
            <Link to="/otc" className={location.pathname === '/otc' ? 'menu-item-active' : '','item'}>
              <OtcIcon className="svg_icon" width={15} />
            </Link>
            <div className="title">  {t('menu.otc') }</div>
          </li>

        
          {/* <li>
            <Link
              to="/report"
              className={
                location.pathname === '/report' ? 'menu-item-active' : '','item'
              }
            >
              <ReportsIcon className="svg_icon" width={20} />
            </Link>
          </li> */}
          {/* <li>
            <Link
              to="/videos"
              className={
                location.pathname === '/videos' ? 'menu-item-active' : '','item'
              }
            >
              <VideosIcon className="svg_icon" width={30} />
            </Link>
            <div className="title">  {t('menu.videos') }</div>
          </li> */}
          {logged && (       
            <>
              <li>
                <Link
                  to="/settings"
                  className={
                location.pathname === '/settings' ? 'menu-item-active' : '','item'
              }
                >
                  <SettingIcon className="svg_icon" width={15} />
                </Link>
                <div className="title"> {t('menu.settings') }</div>
              </li>
              {/* <li>
                <Link
                  to="/swap"
                  className={
                location.pathname === '/swap' ? 'menu-item-active' : '','item'
              }
                >
                  <BtcqIcon className="svg_icon" width={35} />
                </Link>
                <div className="title">  {t('menu.swap') }</div>
              </li> */}
              <li>
                <Link to="/" onClick={handleLogout}>
                  <LogoutIcon className="svg_icon" width={20} />
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
      <section className="menu-container-content">{children}</section>
    </Container>
  );
}

LayoutMenu.propTypes = {
  children: PropTypes.element.isRequired,
  noShow: PropTypes.arrayOf(PropTypes.string),
};

LayoutMenu.defaultProps = {
  noShow: '',
};
