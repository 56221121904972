import React, { useState, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { ToastFailed } from '~/utils/toast';
import * as WithdrawActions from '~/store/modules/withdraw/actions';
import * as FeeActions from '~/store/modules/fee/actions';

import { LoadingAnimation } from '~/components/animation';

import { Container, Button, ContainerInput, ContainerButtons, LightButton } from './styles';
import { Input } from '~/styles/components/inputs';

import { ReactComponent as WithdrawIcon } from '~/assets/svg/withdraw.svg';

function WithdrawRasComponent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open2faInput, setOpen2faInput] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [inputValues, setInputValues] = useState({
    value: '',
    code2fa: '',
    percent: '',
  });
  const { wallets } = useSelector((state) => state.wallet);
  const { error, message, loading } = useSelector((state) => state.withdraw);
  const { fees } = useSelector((state) => state.fee);

  React.useEffect(() => {
    if (!fees.length) {
      dispatch(FeeActions.requestFee());
    }
  }, [dispatch, fees]);

  const walletRas = useMemo(() => wallets.find((w) => w.Currency === 'RAS'), [wallets]);
  const feeRas = useMemo(() => fees.find((f) => f.name === 'RAS'), [fees]);

  function handlePressConfirm() {
    const amountWithFee = parseFloat(inputValues.value)
    if (amountWithFee < 30) {
      return;
    }

    if (amountWithFee > walletRas?.Available) {
      setOpen2faInput(false);
      return ToastFailed('withdraw_check_your_available_amount_in', { crypto: 'RAS' });
    }
    setOpen2faInput(true);
  }

  function resetComponent() {
    setInputValues({
      value: '',
      code2fa: '',
      percent: '',
    });
    setOpen2faInput(false);
  }

  function handleSubmitWithdrawWith2faCode({ code }) {
    const { value: amount } = inputValues;
    const currency = walletRas?.Currency;
    dispatch(WithdrawActions.requestNewWithdrawRas({ currency,
      amount: parseFloat(amount),
      otp: code,
      callback: (link) => {
        resetComponent();
        setRedirectLink(link);
      },
    }));
  }

  function handleChangeInputValue(event) {
    const { name, value } = event.target;
    const newValues = { ...inputValues };

    if (name === 'address') {
      newValues[name] = value;
      setInputValues(newValues);
      return true;
    }
    if (name === 'value') {
      if (value !== '') {
        const regex = new RegExp(/^[\d.]+$/, 'g');
        if (!regex.test(value)) return true;
      }
      newValues[name] = value;
      newValues.percent = ((value * 100) / parseFloat(walletRas.Available));
      setInputValues(newValues);
      return true;
    }
    if (name === 'percent') {
      newValues.value = parseFloat(walletRas.Available) * (value / 100);
    }

    if (name === 'code2fa' && value.length === 6) {
      handleSubmitWithdrawWith2faCode({ code: value });
    }

    newValues[name] = value;
    setInputValues(newValues);
  }

  return (
    <Container>
      <div className="flag_top_left">
        <WithdrawIcon width={30} />
      </div>
      {!redirectLink ? (
        <>
          <div className="header_qrcode">
            <img alt="real deposit" src="/withdrawras.png" />
          </div>
          <div className="body_qrcode">
            {loading && <LoadingAnimation width={100} height={100} />}
            {!loading && !open2faInput && (
            <>
              <div className="withdraw_amount">
                <h2>
                  {t('real_to_ras_message')}
                </h2>
              </div>
              <ContainerInput inputValue>
                <div>
                  <Input
                    onChange={handleChangeInputValue}
                    value={inputValues.value}
                    autoComplete="off"
                    className="withdraw_value"
                    name="value"
                    type="text"
                  />
                </div>
              </ContainerInput>
              <ContainerButtons>
                {[25, 50, 75, 100].map((value) => (
                  <LightButton onClick={() => handleChangeInputValue({ target: { name: 'percent', value } })} key={value} selected={value === inputValues.percent}>
                    {`${value}%`}
                  </LightButton>
                ))}
              </ContainerButtons>
              <div className="withdraw_container_info">
                <p>{t('withdraw_amount_minimum_required')}</p>
                <p>
                  Total:
                  {' '}
                  {parseFloat(inputValues.value)}
                </p>
                <p>Minimum:30</p>
              </div>
              <div style={{ color: '#00acde', marginTop: 10 }}>{t(message)}</div>
              <div style={{ color: 'red', marginTop: 10 }}>{t(error)}</div>
              <Button disabled={(!inputValues.value)} onClick={handlePressConfirm}>
                {t('confirm')}
              </Button>
            </>
            )}
            {!loading && open2faInput && (
            <>
              <div className="withdraw_amount">
                <h2>
                  {t('available_amount')}
                </h2>
                <h1>
                  {parseFloat(walletRas?.Available).toFixed(2)}
                </h1>
              </div>
              <div className="withdraw_amount">
                <h2>
                  {t('withdraw_amount')}
                </h2>
                <h1>
                  {inputValues.value}
                  {' '}
                  {walletRas?.Currency}
                </h1>
              </div>
              {message ? (
                <div style={{ color: '#00acde', marginTop: 10 }}>{t(message)}</div>
              ) : (
                <ContainerInput>
                  <p style={{ color: '#00acde', fontSize: 12 }}>
                    {t('withdraw_2fa_message')}
                  </p>
                  <Input
                    name="code2fa"
                    type="text"
                    value={inputValues.code2fa}
                    onChange={handleChangeInputValue}
                  />
                </ContainerInput>
              )}

              <div className="withdraw_container_info">
                <Trans i18nKey="withdraw_ras_note" />
              </div>
              <div style={{ color: 'red', marginTop: 10 }}>{t(error)}</div>
            </>
            )}
          </div>
        </>

      ) : (
        <div className="container-withdraw-redirect">
          <div>
            <p>Mazzera only works with StableCoins.</p>
            <p>Here you can convert BRL (Reais) in RAS.</p>
          </div>
          <div className="header_qrcode">
            <img alt="real deposit" src="/withdrawras.png" />
          </div>
          <a href={redirectLink} target="_blank" rel="noreferrer" className="button-redirect">{t('withdraw')}</a>
          <div>
            <p>By clicking you will be redirected to a</p>
            <p>payment partner.</p>
          </div>
        </div>
      )}
    </Container>
  );
}

export default WithdrawRasComponent;
