import axios from 'axios';

export const requestConfigurationToASymbol = ({ symbol }) => axios.get(
  `https://stratum-websocket.vittorazzi.com:2096/symbols?symbol=${symbol}`,
);

export const requestHistory = ({
  symbol, resolution, from, to,
}) => axios.get(
  `https://stratum-websocket.vittorazzi.com:2096/history?symbol=${symbol}&resolution=${resolution}&from=${from}&to=${to}`,
);

export const requestDepositRasURl = ({ email, address }) => axios.post('https://mkt.coindex.services/v1/bankteller/deposit', { email, address });
