import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, ContainerHistory, MiddleContent,ContainerBuySel } from './styles';
import { setFormValues } from '~/store/modules/order/actions';
import {
  BookBuyComponent,
} from '~/components/direct';

import ReactTooltip from 'react-tooltip'; 


export default function BookSpreadComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { asks, bids, currency } = useSelector((state) => state.book);
  const { baseCurrency } = useSelector((state) => state.product);


  return (
    <Container className="bg-content">
    
      <ContainerBuySel>
        <h2>
          {t('direct_order')}
            <span data-tip data-for='directDecription'> (?) </span>
            <ReactTooltip id='directDecription'>
              <span>{t('direct_order_description')}</span>
            </ReactTooltip>
        </h2> 
          <BookBuyComponent />
      </ContainerBuySel>
    </Container>
  );
}
