import produce from 'immer';

const INITIAL_STATE = {
  products: [],
  loading: false,
  error: '',
  baseCurrency: null,
};

export default function product(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@products/requestProducts':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
      });
    case '@products/responseProducts':
      return produce(state, (draft) => {
        draft.loading = false;
        if (action.payload.success) {
          draft.products = action.payload.products;
        } else {
          draft.error = action.payload.error;
        }
      });
    case '@products/setBaseCurrency':
      return produce(state, (draft) => {
        draft.baseCurrency = action.payload.baseCurrency;
      });
    default:
      return state;
  }
}
