import styled from 'styled-components';

export const Input = styled.input.attrs({
  autoComplete: 'off',
})`
  border: none;
  border-radius: 10px;
  min-width: 100%;
  min-height: 40px;
  background-color: ${({ theme }) => theme.default_bg_color};
  font-size: 12px;
  color: #fff;
  padding:10px;
  text-align:left;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
  /* @media(min-width:400px){
    &{
      min-height:40px;
    }
  } */
`;
