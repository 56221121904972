import React from 'react';
import { FiPhoneOutgoing } from 'react-icons/fi';
import { useTranslation, Trans } from 'react-i18next';
import { HeaderBook } from '~/components/headers';
import { Container } from './styles';

const data = [
  {
    name: 'Contato',
    phone: '+55 11 96450-1255',
    mail: 'operacoes@mazzera.finance',
  },
  
];


export default function OtcPage() {
  const { t } = useTranslation();
  return (
    <>
      <Container name="otc">
        <HeaderBook />
        <div className="container-boxes">
          <div className="left-container">
            <div className="box-larger">
              <h1>{t('otc.header')}</h1>
              <Trans i18nKey="otc.welcome" />
            </div>
            <div className="box-larger">
              <p>{t('otc.contact_message')}</p>
              {data.map((d) => (
                <div key={d.phone} className="inline">
                  <p>{d.name}</p>
                  <p className="divisor">
                    |
                  </p>
                  <p>{d.phone}</p>
                  <p className="divisor">
                    |
                  </p>
                  <p>
                    <a href={`mailto:${d.mail}`}>{d.mail}</a>
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="box-larger box-right" />
        </div>
      </Container>
    </>
  );
}
