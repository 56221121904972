import { takeEvery, all, call, put } from 'redux-saga/effects';
import * as feeActions from './actions';
import * as FeeService from '~/services/api/fee.api';

function* requestFee() {
  try {
    const { data } = yield call(FeeService.RequestFees);
    return yield put(feeActions.responseFee({ success: true, fees: data }));
  } catch (error) {
    return yield put(feeActions.responseFee({ success: false, fees: [] }));
  }
}

export default all([
  takeEvery('@fee/requestFee', requestFee),
]);
