import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  min-height:400px;
  margin-top: 10px;
  padding: 15px;
  display: block;
  background-color:${({ theme }) => theme.content_bg_color};

  & h1{
    text-transform:capitalize;
  }
`;

export const HeaderSelect = styled.div`
  width:100%;
  flex-direction:row;
  height:40px;
  margin-top:-15px;
  margin-left:-15px;
  & div {
    text-transform:capitalize;
  }
  & .history_button_select{
    padding:10px;
    justify-content:center;
    align-items:center;
    cursor:pointer;
  }
  & .history_button_select.history_selected{
    background-color:${({ theme }) => theme.default_bg_color};
    color:${({ theme }) => theme.primary_color};
  }
`;

export const ContainerHistory = styled.div`
  width: 100%;
  max-height: 400px;
  min-height: 400px;
  overflow: auto;

  & h1 {
    font-size: 15px;
    align-self: center;
  }

  & .history-row{
    min-height: 30px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.default_bg_color};
    border-radius: 20px;
    margin-bottom: 10px;
  }

  & .history-row p{
    width:25%;
    text-align:center;
  }

  & .row-founds p{
    width:33.33%;
  }

  & .trade-item {
    min-height: 30px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.default_bg_color};
    border-radius: 20px;
    margin-bottom: 10px;
  }
  & .trade-item p {
    width: 12.5%;
    text-align: center;
    justify-content: flex-end;
  }
  & .trade-item p:nth-child(1) {
    padding-left: 5px;
  }
  & .trade-item p:nth-child(6) {
    padding-right: 5px;
  }

  & p[role="none"]{
    cursor:pointer;
  }

  & .history-header {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    min-height: 30px;
  }

  & .history-header h1 {
    font-size: 12px;
    width: 12.5%;
    text-align: center;
  }
  & .header-founds h1{
    width:33.33%;
  }
`;

export const MiddleContent = styled.div`
  width: 100%;
  min-height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & span {
    flex: 1;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    background-color: #fff;
  }

  & h1 {
    background-color: ${({ theme }) => theme.content_bg_color};
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
