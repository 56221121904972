import produce from 'immer';
import { currencies } from '../../../config/environments';

const INITIAL_STATE = {
  book: null,
  bids: [],
  asks: [],
  trades: [],
  price: 0,
  open24h: 0,
  low24h: 0,
  volume24h: 0,
  currency: currencies[0],
  currencies,
  history: [],
};

export default function wallet(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@book/setHistory':
      return produce(state, (draft) => {
        draft.history = action.payload.history;
      });
    case '@book/setBook':
      return produce(state, (draft) => {
        draft.bids = action.payload.bids;
        draft.asks = action.payload.asks;
        draft.bids.sort((a, b) => (parseFloat(a[0]) > parseFloat(b[0]) ? -1 : 1));
        draft.asks.sort((a, b) => (parseFloat(a[0]) < parseFloat(b[0]) ? -1 : 1));
      });
    case '@book/updateTicker':
      return produce(state, (draft) => {
        draft.price = action.payload.price;
        draft.open24h = action.payload.open24;
        draft.low24h = action.payload.low24h;
        draft.volume24h = action.payload.volume24h;
      });
    case '@book/setCurrency':
      return produce(state, (draft) => {
        draft.currency = action.payload.currency;
      });
    case '@book/setCurrencies':
      return produce(state, (draft) => {
        draft.currencies = action.payload.currencies;
      });
    case '@book/setTrades':
      return produce(state, (draft) => {
        draft.trades = action.payload.trades;
      });
    case '@book/updateCurrenciesWithAvailableAndBanalce':
      return produce(state, (draft) => {
        draft.currency = action.payload.currency;
        draft.currencies = action.payload.currencies;
      });
    default:
      return state;
  }
}
