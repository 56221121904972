export function requestSwaps({ page }) {
  return { type: '@swap/requestSwaps', payload: { page } };
}

export function requestTotal() {
  return { type: '@swap/requestTotal' };
}
export function requestRescue() {
  return { type: '@swap/requestRescue' };
}

export function setInfo({ key, value }) {
  return { type: '@swap/setInfo', payload: { key, value } };
}
