export function requestProducts() {
  return { type: '@products/requestProducts' };
}

export function responseProducts({ success, products, error }) {
  return { type: '@products/responseProducts', payload: { success, products, error } };
}

export function setBaseCurrency({ baseCurrency }) {
  return { type: '@products/setBaseCurrency', payload: { baseCurrency } };
}

export function updateProductPricesWithWalletValues() {
  return { type: '@products/updateProductPricesWithWalletValues' };
}

export function unsubscribeBaseCurrency({ baseCurrency }) {
  return { type: '@products/unsubscribeBaseCurrency', payload: { baseCurrency } };
}

export function subscribeBaseCurrency({ baseCurrency }) {
  return { type: '@products/subscribeBaseCurrency', payload: { baseCurrency } };
}
