import React from 'react';

import { Container, Content, AnimatedParagraph } from './styles';
import { ReactComponent as StratumIcon } from '~/assets/png/mazzera-logo.png';
import { LoadingAnimation } from '~/components/animation';

function ModalWelcome() {
  const [showAnimatedLogo, setShowAnimatedLogo] = React.useState(false);
  const [showThisModal, setShowThisModal] = React.useState(false);
  React.useEffect(() => {
    if (sessionStorage.getItem('modal_welcome') === '1') {
      setShowThisModal(false);
    } else {
      setShowThisModal(true);
    }
    setTimeout(() => {
      setShowAnimatedLogo(true);
      setTimeout(() => {
        sessionStorage.setItem('modal_welcome', '1');
        setShowThisModal(false);
      }, 2000);
    }, 3000);
  }, []);

  if (!showThisModal) return null;

  return (
    <Container>
      <Content>
        {/*{showAnimatedLogo ? (*/}
        {/*  <LoadingAnimation width={130} height={130} />*/}
        {/*) : (*/}
        {/*  <StratumIcon width={130} height={130} />*/}
        {/*)}*/}
        <div style={{ marginLeft: 20 }}>
        <AnimatedParagraph animationDelay="1s">Mazzera</AnimatedParagraph>
          <AnimatedParagraph animationDelay="2s"></AnimatedParagraph>
          <AnimatedParagraph animationDelay="3s"></AnimatedParagraph>
        </div>
      </Content>
    </Container>
  );
}

export default ModalWelcome;
