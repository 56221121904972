import React, { useEffect } from 'react';

import InputMask from 'react-input-mask';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import * as registerActions from '~/store/modules/register/actions';
import { validatePhone } from '~/utils/validate';
import { Container, Step } from './styles';
import { Input } from '~/styles/components/inputs';
import { Button } from '~/styles/components/buttons';
import StratumIcon from '~/assets/png/mazzera-logo.png';
import { LoadingAnimation } from '~/components/animation';

const registerSchema = Yup.object().shape({
  email: Yup.string().email('invalid_email').required('required_field'),
  phone: Yup.string().test('phone', 'invalid_phone', (val) => validatePhone(val)),
  password: Yup.string().required('required_field'),
  password_confirmation: Yup.string()
    .required('required_field')
    .test('passwords-match', 'password_not_match', function (value) {
      return this.parent.password === value;
    }),
  name: Yup.string().required('required_field'),
});

export default function PageRegister({ match, history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const history = useHistory();
  const { error, message, loading } = useSelector((state) => state.register);
  const formik = useFormik({
    validationSchema: registerSchema,
    initialValues: {
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      name: '',
    },
    initialErrors: {
      email: '-',
      phone: '-',
      password: '-',
      password_confirmation: '-',
      name: '-',
    },
    onSubmit: (values) => {
      dispatch(registerActions.requestRegister({ ...values }));
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (!match.params.step) {
      return history.push('/register/email');
    }
  }, [match, dispatch, history]);

  function handleBackPress() {
    history.goBack();
  }

  function navigateTo(path) {
    if (formik.errors.email) {
      return history.push('/register/email');
    }
    if (formik.errors.phone) {
      return history.push('/register/phone');
    }
    if (formik.errors.password || formik.errors.password_confirmation) {
      return history.push('/register/password');
    }
    if (formik.errors.name) {
      return history.push('/register/name');
    }
    history.push(`/register/${path}`);
  }

  /*
   * verifica se existe erro nos valores e volta para o primeiro input de erro encontrado
   * serve para caso o usuário acesse diretamentea url sem preencher os valores anteriores
   *
   */
  function checkValuesBeforeSubmit(e) {
    const hasError = Object.keys(formik.errors).filter((key) => formik.errors[key]);
    if (hasError.length) {
      return navigateTo(hasError[0]);
    }
    formik.handleSubmit(e);
  }

  if (loading || !match.params.step) {
    return (
      <>
        <Container>
          <section className="section-container">
            <Link to="/trade" className="link-to-book">
              <AiOutlineArrowLeft />
              {'      '}
              {t('go_back_to_book')}
            </Link>
            <div className="container-card" style={{ justifyContent: 'center' }}>
              <LoadingAnimation width={100} height={100} />
            </div>
          </section>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <section className="section-container">
          <Link to="/trade" className="link-to-book">
            <AiOutlineArrowLeft />
            {'      '}
            {t('go_back_to_book')}
          </Link>
          <div className="container-card">
            <header>
              <img src={StratumIcon} height="45" />
              <h1>{t('register')}</h1>
            </header>
            <>
              {(match.params.step === 'email' || !match.params.step) && (
              <>
                <div className="container-input">
                  <span>{t('put_your_email')}</span>
                  <Input
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="error-message">
                    {' '}
                    {formik.touched.email && formik.errors.email && t(formik.errors.email)}
                  </span>
                </div>
                <Button className="btn btn-next" onClick={() => navigateTo('phone')}>
                  {t('next')}
                </Button>
              </>
              )}
              {(match.params.step === 'phone' || !match.params.step) && (
              <>
                <div className="container-input">
                  <span>{t('put_your_phone')}</span>
                  <Input
                    type="text"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder=""
                  />
                  <div>
                  {t('example')} +5511999998888
                  </div>
                  {/* <InputMask
                    mask={/[0,9]{5,12}/g}
                    type="phone"
                    name="phone"
                    className="input-mask"
                    alwaysShowMask={false}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  /> */}
                  <span className="error-message">
                    {' '}
                    {formik.touched.phone && formik.errors.phone && t(formik.errors.phone)}
                  </span>
                </div>
                <Button className="btn btn-next" onClick={() => navigateTo('password')}>
                  {t('next')}
                </Button>
              </>
              )}
              {(match.params.step === 'password') && (
              <>
                <div className="container-input">
                  <span>{t('put_your_password')}</span>
                  <Input
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="error-message">
                    {' '}
                    {formik.touched.password && formik.errors.password && t(formik.errors.password)}
                  </span>
                </div>
                <div className="container-input">
                  <span>{t('confirm_your_password')}</span>
                  <Input
                    type="password"
                    name="password_confirmation"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="error-message">
                    {' '}
                    {formik.touched.password_confirmation
                    && formik.errors.password_confirmation
                    && t(formik.errors.password_confirmation)}
                  </span>
                </div>
                <Button className="btn btn-next" onClick={() => navigateTo('name')}>
                  {t('next')}
                </Button>
              </>
              )}

              {match.params.step === 'name' && (
              <>
                <div className="container-input">
                  <span style={{ marginBottom: 10 }}>{t('put_your_name')}</span>
                  <Input name="name" values={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  <span style={{ marginTop: 10 }} className="error-message">
                    {' '}
                    {formik.touched.name && formik.errors.name && t(formik.errors.name)}
                  </span>
                  <Button
                    type="submit"
                    className="btn btn-next"
                    style={{ alignSelf: 'center' }}
                    onClick={checkValuesBeforeSubmit}
                  >
                    {t('next')}
                  </Button>
                </div>
              </>
              )}

              {match.params.step === 'message' && (
              <>
                <div className="container-success">
                  <FaCheckCircle />
                </div>
                <h2 style={{ textAlign: 'center' }}>{t(message)}</h2>
              </>
              )}

              {match.params.step === 'error' && (
              <>
                <div className="container-success">
                  <FaTimesCircle className="svg-error" />
                </div>
                <h2>{t(error)}</h2>
              </>
              )}
            </>
            <div className="container-step">
              {match.params.step !== 'email' && (
              <button type="button" onClick={handleBackPress}>
                <AiOutlineArrowLeft />
              </button>
              )}
              <Step
                selected={!match.params.step || match.params.step === 'email'}
              />
              <Step
                selected={match.params.step === 'phone'}
              />
              <Step
                selected={match.params.step === 'password'}
              />
              <Step
                selected={match.params.step === 'name'}
              />
              <Step
                selected={match.params.step === 'message'}
              />
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

PageRegister.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }).isRequired,
};
