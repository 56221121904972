import styled from 'styled-components';

export const Container = styled.section`
  min-height: 500px;
  max-height: 500px;
  width: 100%;
  background-color: ${({ theme }) => theme.content_bg_color};
  padding: 10px;
  padding-bottom:20!important;
  overflow:auto;
  border-radius:10px;

  & header {
    margin-bottom: 10px;
  }
  & header h1 {
    font-size: 15px;
  }

  & .history-item.header-history {
    font-weight: bold;
    cursor: unset;
    font-size: 14px;
    border:solid 1px #1115;
  }

  & .history-item {
    /* cursor: pointer; */
    min-height: 25px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: ${({ theme }) => theme.default_bg_color}; */
    border-bottom:solid 1px #1115;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
  }
  & .history-item p {
    width: 20%;
    text-align: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .flag-status{
    background-color: ${({ theme }) => theme.default_bg_color};
    border-radius:50px;
    padding:3px;
  }

  & .status-pending{
    color: ${({ theme }) => theme.primary_color};
  }
  & .status-converted{
    color: ${({ theme }) => theme.buy_color};
  }
  & .status-canceled{
    color: ${({ theme }) => theme.sell_color};
  }
  & .pagination{
    display:flex;
    flex-direction:row;
  }
  & .pagination .pagination-active{
    background-color:${({ theme }) => theme.default_bg_color};
  }
  & .pagination li{
    height:20px;
    width:20px;
    line-height:20px;
    text-align:center;
    cursor:pointer;
  }

  & .container-data{
    display:flex;
    flex-direction:column;
    flex:1;
    justify-content: flex-start;
    align-items:center;
  }
`;
