import React from 'react';
import { RiSmartphoneLine, RiUserLine, RiMailLine, RiMessage2Line } from 'react-icons/ri';
import { GiPadlock } from 'react-icons/gi';
import { BsLock } from 'react-icons/bs';

import { useSelector, useDispatch } from 'react-redux';
import { HeaderBook } from '~/components/headers';
import { Container } from './styles';

import * as AuthActions from '~/store/modules/auth/actions';

export default function AccountSettings() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  function handleResetPassword() {
    dispatch(AuthActions.requestRecovery({ onlyToastMessage: true, type: 'password', userId: user?.user_name, email: user?.email }));
  }

  return (
    <>
      <Container name="otc">
        <HeaderBook />
        <div className="container-boxes">
          <div className="left-container">
            <div className="box-larger">
              <p>
                Profile
                (to change your personal information contact us throug the support channel)
              </p>
              <div className="row">
                <RiUserLine size={15} color="#fff7" />
                <p>
                  User ID:
                  {' '}
                  {user?.user_name}
                </p>
              </div>
              <div className="row">
                <RiMailLine size={15} color="#fff7" />
                <p>
                  Email:
                  {' '}
                  {user?.email}
                </p>
              </div>
              <div className="row">
                <RiSmartphoneLine size={15} color="#fff7" />
                <p>
                  Phone:
                  {' '}
                  {user?.phone}
                </p>
              </div>
              <div className="row">
                <BsLock size={15} color="#fff7" />
                <p>Password: ******</p>
              </div>
              <div className="row">
                <BsLock size={15} color="#fff7" />
                <p tabIndex="1" className="link" onClick={handleResetPassword}>Reset Password</p>
              </div>
            </div>
            <div className="box-larger">
              <p>Two Factor Authentication</p>
              <div className="row">
                <GiPadlock size={15} color="#fff7" />
                <p>
                  If you wish to reset your 2FA, contat our support
                </p>
              </div>
            </div>
          </div>
          <div className="box-larger box-right">
            <RiMessage2Line size={50} color="#fff7" />
            <p className="link"><a href="mailto: support@mazzera.trade">Support</a></p>
          </div>
        </div>
      </Container>
    </>
  );
}
