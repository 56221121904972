import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

import stratumIcon from '~/assets/png/mazzera-logo.png';

export default function BookPriceComponent() {
  const { t } = useTranslation();
  const {
    price, open24h, low24h,
  } = useSelector((state) => state.book);
  const { baseCurrency } = useSelector((state) => state.product);
  return (
    <Container className="bg-content">
      <img src={stratumIcon} alt="logo stratumx" />
      <div className="hide-when-mobile">
        <div className="circle green" />
        <h2>{!open24h ? '' : parseFloat(open24h).toFixed(baseCurrency?.quoteScale)}</h2>
        <p>{t('hight_price')}</p>
      </div>
      <div className="hide-when-mobile">
        <div className="circle red" />
        <h2>{!low24h ? '' : parseFloat(low24h).toFixed(baseCurrency?.quoteScale)}</h2>
        <p>{t('low_price')}</p>
      </div>
      <div>
        <div className="circle green" />
        <h2>{!price ? '' : parseFloat(price).toFixed(baseCurrency?.quoteScale)}</h2>
        <p>{t('last_price')}</p>
      </div>
      <div>
        <div className="circle red" />
        <h2>{!price ? '' : parseFloat(price).toFixed(baseCurrency?.quoteScale)}</h2>
        <p>{t('price_24h')}</p>
      </div>
    </Container>
  );
}
