import React, { useEffect,useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { Container, Button } from './styles';
import { LoadingAnimation } from '~/components/animation';

import { ReactComponent as DepositIcon } from '~/assets/svg/deposit.svg';
import { ReactComponent as BtcqIcon } from '~/assets/svg/icon_btcq.svg';
import * as SwapActions from '~/store/modules/swap/actions';
import Swal from 'sweetalert2';

function WithdrawRasComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [enable, setEnable] = useState(false);
  const { total, loadingTotal } = useSelector((state) => state.swap);

  function requestTotalData() {
    dispatch(SwapActions.requestTotal());
  }

  function handleRequest(){
    Swal.fire({
      title: t('swap_alert_title'),
      text: t('swap_alert_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('swap_alert_button')
    }).then((result) => {
      if (result.value) {
        dispatch(SwapActions.requestRescue())
        Swal.fire(
          'Request!',
          '.',
          'success'
        )
      }
    })
  }

  useEffect(() => {
    setEnable(total.has_crypto && total.open)
  }, [total],setEnable);

  useEffect(() => {
    requestTotalData();
  }, [dispatch]);
  return (
    <Container>
      <div className="flag_top_left">
        <DepositIcon width={30} />
      </div>
      <header>
        <BtcqIcon size={50} />
        <div>
          <h1>BTCQ</h1>
          <h2>SWAPX</h2>
        </div>
      </header>
      <div className="container-info-swap">

        <p>
          Você pode recuperar as taxas usadas na Mazzera com suas BTCQ.
        </p>
        <p>
          São elegíveis as taxas
        </p>
        <p>
          <strong>Conversão de RAS para BRL</strong>
          <br />
          <strong>Conversão de BRL para RAS</strong>
          <br />
          <strong>Taxa de compra</strong>
          <br />
        </p>
        <p>
          Ao lado você pode acompanhar no mês as movimentações de suas taxas elegíveis
        </p>
        <p />
        <p />
        {loadingTotal ? (
          <div className="btc-line-value">
            <LoadingAnimation width={60} height={60} />
          </div>
        ) : (
          <>
            <div className="btc-line-value">
            <strong>
                RAS
              </strong>
              <strong>
                BTCQ
              </strong>
            </div>
            <div className="btc-line-value">
              <strong>
                {total?.ras_formated || '0.0000'}
              </strong>
              <strong>
                {total?.cryptoTotal || '0.00000000'}
              </strong>
            </div>
          </>
        )}
      </div>
      <Button disabled={!enable} onClick={handleRequest}>
        {t('swap')}
      </Button>
    </Container>
  );
}

export default WithdrawRasComponent;
