export function requestWithdrawHistory({ currency }) {
  return { type: '@withdraw/requestWithdrawHistory', payload: { currency } };
}

export function responseWithdrawHistory({ success, history, error }) {
  return { type: '@withdraw/responseWithdrawHistory', payload: { success, history, error } };
}

export function requestNewWithdraw({ currency, amount, otp, address }) {
  return { type: '@withdraw/requestNewWithdraw', payload: { currency, amount, otp, address } };
}

export function requestNewWithdrawRas({ amount, otp, callback }) {
  return { type: '@withdraw/requestNewWithdrawRas', payload: { amount, otp, callback } };
}

export function responseNewWithdraw({ success, message, error }) {
  return { type: '@withdraw/responseNewWithdraw', payload: { success, message, error } };
}

export function requestConfirmOrCancelWithdraw({ confirm, withdraw_id, code }) {
  return { type: '@withdraw/requestConfirmOrCancelWithdraw', payload: { confirm, withdraw_id, code } };
}

export function responseConfirmOrCancelWithdraw({ success, message, error }) {
  return { type: '@withdraw/responseConfirmOrCancelWithdraw', payload: { success, message, error } };
}

export function clearMessages() {
  return { type: '@withdraw/clearMessages' };
}

export function setHistory({ history }) {
  return { type: '@withdraw/setHistory', payload: { history } };
}

export function setMessage({ message }) {
  return { type: '@withdraw/setMessage', payload: { message } };
}

export function setRequestedWithdraw({ withdrawRequested }) {
  return { type: '@withdraw/setRequestedWithdraw', payload: { withdrawRequested } };
}

export function setError({ error }) {
  return { type: '@withdraw/setError', payload: { error } };
}

export function withdrawResponse(data) {
  return { type: '@withdraw/withdrawResponse', payload: { data } };
}

export function withdrawCreate({ currency, address, amount, code }) {
  return { type: '@withdraw/withdrawCreate', payload: { currency, address, amount, code } };
}

export function withdrawGetInfoToConfirm({ code }) {
  return { type: '@withdraw/withdrawGetInfoToConfirm', payload: { code } };
}

export function withdrawConfirm({ code }) {
  return { type: '@withdraw/withdrawConfirm', payload: { code } };
}
export function withdrawCancel({ code }) {
  return { type: '@withdraw/withdrawCancel', payload: { code } };
}
// txId, offchain,linkExporer
