import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, ContainerHistory, HeaderSelect } from './styles';
import * as OrderActions from '~/store/modules/order/actions';
import * as BookActions from '~/store/modules/book/actions';
import { dateFormat } from '~/utils/format';
import { LoadingAnimation } from '~/components/animation';

export default function TradeviewComponent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentStatus, setCurrentStatus] = useState('open');
  const { token, step_session } = useSelector((state) => state.auth);
  const { orders, loadingOrders, loadingDeleteOrder } = useSelector((state) => state.order);
  const wallets = useSelector((state) => state.wallet.wallets);

  useEffect(() => {
    dispatch(BookActions.requestHistory());
    if (token && step_session >= 1) {
      dispatch(OrderActions.requestOrders());
    }
  }, [dispatch, token, step_session]);

  useEffect(() => {
    if (currentStatus) {
      const query = {
        status: currentStatus,
      };
      dispatch(OrderActions.updateQueryAndLimitOrder({ limit: 100, query }));
      dispatch(OrderActions.requestOrders());
    }
  }, [currentStatus, dispatch]);

  function handleCancelOrder({ orderId }) {
    dispatch(OrderActions.requestDeleteOrder({ orderId }));
  }

  if (loadingOrders || loadingDeleteOrder) {
    return (
      <Container className="bg-content">
        <HeaderSelect>
          <div tabIndex={0} role="button" onClick={() => setCurrentStatus('open')} className={`history_button_select ${currentStatus === 'open' ? 'history_selected' : ''}`}>
            {t('open_orders')}
          </div>
          <div tabIndex={0} role="button" onClick={() => setCurrentStatus('filled')} className={`history_button_select ${currentStatus === 'filled' ? 'history_selected' : ''}`}>
            {t('order_history')}
          </div>
          <div tabIndex={0} role="button" onClick={() => setCurrentStatus('')} className={`history_button_select ${currentStatus === '' ? 'history_selected' : ''}`}>
            {t('founds')}
          </div>
        </HeaderSelect>
        <ContainerHistory style={{ justifyContent: 'center' }}>
          <LoadingAnimation width={300} height={300} />
        </ContainerHistory>
      </Container>
    );
  }

  return (
    <Container className="bg-content">
      <HeaderSelect>
        <div tabIndex={0} role="button" onClick={() => setCurrentStatus('open')} className={`history_button_select ${currentStatus === 'open' ? 'history_selected' : ''}`}>
          {t('open_orders')}
        </div>
        <div tabIndex={0} role="button" onClick={() => setCurrentStatus('filled')} className={`history_button_select ${currentStatus === 'filled' ? 'history_selected' : ''}`}>
          {t('order_history')}
        </div>
        <div tabIndex={0} role="button" onClick={() => setCurrentStatus('')} className={`history_button_select ${currentStatus === '' ? 'history_selected' : ''}`}>
          {t('founds')}
        </div>
      </HeaderSelect>
      <ContainerHistory>
        {currentStatus === 'open' && (
          <>
            <header className="history-header">
              <h1>{t('date')}</h1>
              <h1>{t('pair')}</h1>
              <h1>{t('type')}</h1>
              <h1>{t('side')}</h1>
              <h1>{t('price')}</h1>
              <h1>{t('amount')}</h1>
              <h1>{t('total')}</h1>
              <h1>{'  '}</h1>
            </header>
            {orders.map((order) => (
              <div className="trade-item" key={order.id}>
                <p>{dateFormat({ date: order.created_at, format: 'd/MM/yyyy HH:mm:ss' })}</p>
                <p>{order.productId}</p>
                <p>Limit</p>
                <p className={`${order.side === 'buy' ? 'cl-hight' : 'cl-low'}`}>{order.side}</p>
                <p>{`${parseFloat(order.price).toFixed(4)}`}</p>
                <p>{`${parseFloat(order.size).toFixed(8)}`}</p>
                <p>{`${(parseFloat(order.price) * (order.size)).toFixed(4)}`}</p>
                <p className="clickable" role="none" onClick={() => handleCancelOrder({ orderId: order.id })}>cancel</p>
              </div>
            ))}
          </>
        )}
        {(currentStatus === 'filled' || currentStatus === 'new') && (
          <>
            <header className="history-header">
              <h1>{t('date')}</h1>
              <h1>{t('pair')}</h1>
              <h1>{t('type')}</h1>
              <h1>{t('side')}</h1>
              <h1>{t('price')}</h1>
              <h1>{t('amount')}</h1>
            </header>
            {orders?.map((trade) => (
              <div className="trade-item" key={trade.id}>
                <p>{dateFormat({ date: trade.created_at, format: 'd/MM/yyyy HH:mm:ss' })}</p>
                <p>{trade.productId}</p>
                <p>{trade.type}</p>
                <p className={`${trade.side !== 'sell' ? 'cl-hight' : 'cl-low'}`}>{trade.side}</p>
                <p>{trade.price}</p>
                <p>{trade.size}</p>
              </div>
            ))}
          </>
        )}
        {currentStatus === '' && (
          <>
            <header className="history-header header-founds">
              <h1>{t('coin')}</h1>
              {/**
                <h1>{t('total_balance')}</h1>
              */}
              <h1>{t('available_balance')}</h1>
              <h1>{t('in_order')}</h1>
            </header>
            {wallets?.map((wallet) => (
              <div className="history-row row-founds" key={wallet.id}>
                <p>{wallet.Currency}</p>
                {/**
                  <p>{wallet.Balance}</p>
                */}
                <p>{parseFloat(wallet.Available).toFixed(wallet.quoteScale || 2)}</p>
                <p>{parseFloat(wallet.Book).toFixed(wallet.quoteScale || 2)}</p>
              </div>
            ))}
          </>
        )}
      </ContainerHistory>
    </Container>
  );
}
