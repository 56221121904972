import { put, delay, takeLatest, takeEvery, all, call, select } from 'redux-saga/effects';
import md5 from 'blueimp-md5';
import history from '~/services/history';
import * as registerActions from './actions';
import * as authActions from '../auth/actions';
import * as AuthService from '../../../services/api/auth.api';

export function* requestRegister(action) {
  try {
    const { name, email, phone, password } = action.payload;
    const { data } = yield call(AuthService.Register, { name, email, phone, password: md5(password) });
    yield put(registerActions.responseRegister({ success: true, message: 'register_send_mail_message', error: '' }));
    yield put(authActions.responseLogin({ ...data, success: true }));
    history.push('/register/message');
    yield delay(3000);
    history.push('/validateemail');
  } catch (error) {
    history.push('/register/error');
    yield put(registerActions.responseRegister({ success: false, error: error?.response?.data?.message, message: '' }));
  }
}

export function* requestChangePassword(action) {
  try {
    const { username, code, new_password } = action.payload;
    yield call(AuthService.ChangePassword, { username, code, new_password: md5(new_password) });
    yield put(registerActions.responseChangePassword({ success: true, message: 'your_password_has_been_changed', error: '' }));
    history.push('/newpassword/message');
    yield delay(3000);
    history.push('/signin');
  } catch (error) {
    history.push('/newpassword/error');
    yield put(registerActions.responseChangePassword({ success: false, error: error?.response?.data?.message, message: '' }));
  }
}

export function* requestEmailValidation(action) {
  try {
    const { code } = action.payload;
    const { user } = yield select((state) => state.auth);
    const { data } = yield call(AuthService.EmailValidation, { code, email: user?.email });
    history.push('/validateemail/message');
    yield put(authActions.responseLogin({ success: true, ...data, error: '' }));
    yield put(registerActions.responseEmailValidation({ success: true, message: 'your_account_is_validated', error: '' }));
    yield delay(2000);
    yield put(authActions.requestSecret());
    history.push('/signin/2fa');
    // history.push('/signin');
  } catch (error) {
    history.push('/validateemail/error');
    yield put(registerActions.responseEmailValidation({ success: false, message: '', error: error?.response?.data?.message }));
  }
}

export function* cleanErrorOrMessage(action) {
  yield delay(5000);
  if (action.type === '@register/setError') {
    yield put(registerActions.change({ property: 'error', value: '' }));
  }
  if (action.type === '@register/setMessage') {
    yield put(registerActions.change({ property: 'message', value: '' }));
  }
}

export default all([
  // takeLatest('@register/registerResponse', registerResponse),
  takeLatest('@register/requestRegister', requestRegister),
  takeLatest('@register/requestChangePassword', requestChangePassword),
  takeLatest('@register/requestEmailValidation', requestEmailValidation),
  takeEvery('@register/setError', cleanErrorOrMessage),
  takeEvery('@register/setMessage', cleanErrorOrMessage),
]);
