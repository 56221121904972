// export function setLoading(loading) {
//   return { type: '@general/SET_LOADING', payload: { loading } };
// }

export function setLanguage({ language }) {
  return { type: '@general/SET_LANGUAGE', payload: { language } };
}

export function setLoading({ name, value }) {
  return { type: '@general/SET_LOADING', payload: { name, value } };
}
export function setError(error) {
  return { type: '@general/SET_ERROR', payload: { error } };
}
export function setMessage(message) {
  return { type: '@general/SET_MESSAGE', payload: { message } };
}
export function toggleMenu({ menuOpen }) {
  return { type: '@general/TOGGLE_MENU', payload: { menuOpen } };
}
export function listenCurrentTabFocus() {
  return { type: '@general/listenCurrentTabFocus' };
}
