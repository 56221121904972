import React,{useEffect} from 'react';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';



import Menu from '~/layout/Menu';

// PUBLIC
import NotFound from '~/pages/_404';
import Maintenance from '~/pages/Maintenance';
import Videos from '~/pages/Videos';
import Otc from '~/pages/Otc';
import { Register, Signin, Account, ConfirmChangePassword } from '~/pages/Auth';
import PageDeposit from '~/pages/Deposit';
import PageSwap from '~/pages/Swap';
import PageBook from '~/pages/Book'
import PageDirectOrder from '~/pages/DirectOrder';
import PageWallet from '~/pages/Wallet/Wallets';
import PageConfirmWithdraw from '~/pages/Wallet/ConfirmWithdraw';
import PageReport from '~/pages/Report';
import PageAccountSettings from '~/pages/AccountSettings';
import PageFiat from '~/pages/Fiat';
// PRIVATE
import ValidationAccount from '~/pages/Auth/ValidationAccount';

export default function Routes() {


  return (
    <>
      <HashRouter hashType="slash">
        <Menu noShow={['/register', '/signin', ""]}>
          <Switch>
            {/** account é usado para trocar a senha e validar conta através do email link */}
            <Route path={['/account/:step', '/account/:step/:code', '/account']} exact component={Account} />
            <Route path={['/register/:step', '/register']} exact component={Register} />
            <Route path={['/signin/:step', '/signin']} component={Signin} />
            <Route path={['/newpassword', '/newpassword/:step']} exact component={ConfirmChangePassword} />
            <PrivateRoute path={['/validateemail/:step', '/validateemail']} component={ValidationAccount} />
            <Route path="/videos" exact component={Videos} />
            <Route path="/otc" exact component={Otc} />
            <Route path="/trade" exact component={PageBook} />
            <Route path="/direct" exact component={PageDirectOrder} />
            <Route path="/deposit" exact component={PageDeposit} />
            <Route path="/swap" exact component={PageSwap} />
            <Route path="/fiat" exact component={PageFiat} />
            <Route exact path="/wallet/withdraw/confirm" component={PageConfirmWithdraw} />
            <Route
              path={['/wallet/:crypto', '/wallet']}
              component={PageWallet}
            />
            <Route
              path={['/ras/:type', '/ras']}
              component={PageDeposit}
            />
            <Route path="/report" exact component={PageReport} />
            <Route path="/settings" exact component={PageAccountSettings} />
            <Route path="/" exact component={() => <Redirect to="/trade" />} />
            <Route component={NotFound} />
            {/* <Route component={Maintenance} /> */}
          </Switch>
        </Menu>
      </HashRouter>
    </>
  );
}
