import produce from 'immer';

const INITIAL_STATE = {
  error: '',
  message: '',
  secret2fa: '',
  loading: false,
  status: '',
  user: null,
  token: '',
  step_register: null,
  step_session: 0,
  otp_url: '',
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@auth/requestLogin':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case '@auth/responseLogin':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.payload.error;
        draft.status = action.payload.status;
        draft.user = action.payload.user;
        draft.token = action.payload.token;
        draft.step_register = action.payload.step_register;
        draft.step_session = action.payload.step_session;
      });
    case '@auth/requestSecret':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
        draft.otp_url = '';
      });
    case '@auth/responseSecret':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.payload.error;
        draft.code = action.payload.code;
        draft.request_time = action.payload.request_time;
        draft.otp_url = action.payload.otp_url;
      });
    case '@auth/requestCheck2fa':
      return produce(state, (draft) => {
        draft.message = '';
        draft.error = '';
        draft.loading = true;
      });
    case '@auth/responseCheck2fa':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.payload.error;
        draft.message = action.payload.message;
        draft.status = action.payload.status;
        draft.step_session = action.payload.step_session;
        draft.token = action.payload.token;
        draft.step_register = action.payload.step_register;
        draft.user = action.payload.user;
      });
    case '@auth/logout':
      return produce(state, (draft) => {
        draft.token = '';
        draft.error = '';
        draft.message = '';
        draft.loading = false;
        draft.user = null;
        draft.status = '';
        draft.otp_url = '';
        draft.step_register = 0;
        draft.step_session = 0;
      });
    case '@auth/cleanUnecessary':
      return produce(state, (draft) => {
        draft.error = '';
        draft.message = '';
        draft.loading = false;
        draft.otp_url = '';
        draft.code = '';
      });
    case '@auth/requestRecovery':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
        draft.message = '';
      });
    case '@auth/responseRecovery':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.payload.error;
        draft.message = action.payload.message;
      });
    default:
      return state;
  }
}
