import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Container,
  ContainerCryptoInfo,
  ContainerSelectDraw,
} from './styles';

import { HeaderBook } from '~/components/headers';
import DepositRasComponent from '~/components/wallet/DepositRas';
import WithdrawRasComponent from '~/components/wallet/WithdrawRas';
import WithdrawHistory from '~/components/wallet/HistoryWithdraw';

import { ReactComponent as WithdrawIcon } from '~/assets/svg/withdraw.svg';
import { ReactComponent as DepositIcon } from '~/assets/svg/deposit.svg';

function DepositPage({ match, history }) {
  const { t } = useTranslation();

  useEffect(() => {
    // if (!match.params.type) {
    //   return history.push('/ras/deposit');
    // }
    if (match.params.type !== 'deposit' && match.params.type !== 'withdraw') {
      history.push('/ras/deposit');
    }
  }, [match.params]);

  function handleChangeType(name) {
    history.push(`/ras/${name}`);
  }

  return (
    <>
      <HeaderBook />
      <Container>
        <ContainerSelectDraw>
          <div
            role="button"
            tabIndex={-1}
            className={`wallet_select ${
              match.params.type === 'deposit' ? 'deposit_active' : ''
            }`}
            onClick={() => handleChangeType('deposit')}
          >
            <DepositIcon width={30} />
            <p>{t('deposit')}</p>
          </div>
          <div
            role="button"
            tabIndex={-1}
            className={`wallet_select ${
              match.params.type === 'withdraw' ? 'withdraw_active' : ''
            }`}
            onClick={() => handleChangeType('withdraw')}
          >
            <WithdrawIcon width={30} />
            <p>{t('withdraw')}</p>
          </div>
        </ContainerSelectDraw>
        <ContainerCryptoInfo>
          {match.params.type === 'withdraw' ? (
            <>
              <WithdrawRasComponent />
              <WithdrawHistory receivedCurrentWallet="RAS" />
            </>
          ) : (
            <div style={{ width: '100%', alignItems: 'center' }}>
              <DepositRasComponent />
            </div>
          )}
        </ContainerCryptoInfo>
      </Container>
    </>
  );
}

DepositPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

DepositPage.defaultProps = {
  match: {
    params: {
      type: '',
    },
  },
};

export default DepositPage;
