export function setFormValues({
  price,
  amount,
  total,
  current,
}) {
  return { type: '@order/setFormValues',
    payload: {
      price,
      amount,
      total,
      current,
    },
  };
}

export function updateQueryAndLimitOrder({ query, limit }) {
  return { type: '@order/updateQueryAndLimitOrder', payload: { query, limit } };
}

export function requestOrders(payload) {
  return { type: '@order/requestOrders', payload };
}

export function responseOrders({ success, orders }) {
  return { type: '@order/responseOrders', payload: { success, orders } };
}

export function requestCreateOrder({ side, price, size, type, productId }) {
  return { type: '@order/requestCreateOrder', payload: { side, price, size, type, productId } };
}
export function responseCreateOrder({ success }) {
  return { type: '@order/responseCreateOrder', payload: { success } };
}

export function requestDeleteOrder({ orderId }) {
  return { type: '@order/requestDeleteOrder', payload: { orderId } };
}

export function responseDeleteOrder({ success }) {
  return { type: '@order/responseDeleteOrder', payload: { success } };
}




export function requestDirectPrice({ productId ,type }) {
  return { type: '@order/requestDirectPrice', payload: { productId,type } };
}

export function responseDirectPrice({ success, directPrice }) {
  return { type: '@order/responseDirectPrice', payload: {  success, directPrice } };
}


export function setFormValuesDirect({
  quoteCurrencyAmount,
  baseCurrencyAmount,
  current,
  quoteCurrency,
  baseCurrency,
}) {
  return { type: '@order/setFormValuesDirect',
    payload: {
      quoteCurrencyAmount,
      baseCurrencyAmount,
      current,
      quoteCurrency,
      baseCurrency,
    },
  };
}


