import styled from 'styled-components';
import { Button as ButtonStyle } from '~/styles/components/buttons';

export const Container = styled.section`
  min-height: 500px;
  width: 100%;
  background-color: ${({ theme }) => theme.content_bg_color};
  padding: 20px;

  & #container-tag-footer{
    display: flex;
    flex-direction:row;
    height:40px;
    align-items:center;
    font-size:12px;
    margin-bottom:10px;
    font-weight:500;
  }

  & #container-tag-footer #blu-tag-footer{
    margin-left:20px;
    font-size:20px;
    color: ${({ theme }) => theme.primary_color};
    border-radius:5px;
    border: solid 1px ${({ theme }) => theme.primary_color};
    padding:10px;
  }

  & .footer-qrcode{
    display:flex;
    flex-direction:row;
    width:100%;
    justify-content:space-between;
    align-items:center;
  }

  & .footer-qrcode-ras p{
    font-size:15px!important;
    text-align:center;
    margin-bottom:10px;
  }

  & .footer-qrcode-ras img{
    border:solid 1px gray;
    border-radius:50px;
    padding:10px;
    max-width:170px;
  }

  & .qr_code_alert {
    margin-top:20px;
    margin-bottom:20px;
  }

  & .qr_code_note {
    margin-top:20px;
    align-self:flex-start;
    width:100%;
  }

  & .qr_code_note p {
    font-size:10px;
  }

  & .header_qrcode {
    flex-direction: row;
    align-items: center;
    align-self: center;
  }

  & .header_qrcode > div {
    margin-left: 10px;
  }

  & .body_qrcode {
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  & .container-signin-qrcode{
    margin-bottom:20px;
    align-self:center;
    background-color:#fff;
    padding:10px;
  }

  & .flag_top_left {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-right-radius: 40px;
    background-color: ${({ theme }) => theme.buy_color};
    padding: 5px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
`;

export const Button = styled(ButtonStyle)`
  margin-top:20px;
  background-color: ${({ theme }) => theme.buy_color};
  color: ${({ theme }) => theme.content_bg_color};
`;

export const ContainerFullAddress = styled.div`
  width:90%;
  height:40px;
  justify-content:space-between;
  align-items:center;
  padding:10px;
  flex-direction:row;
  border-radius:10px;
  border: solid 1px ${({ theme }) => theme.content_selected_bg_color};
  color: #fff;
  padding-bottom:9px;

  & svg{
    cursor:pointer;
  }

  & p{
    /* width: 20%; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
