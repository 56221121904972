import { put, select, takeEvery, all, takeLatest } from 'redux-saga/effects';
import * as socketActions from '../socket/actions';
import * as bookActions from './actions';
import { mergeBidsAndAsksReceived } from '~/utils/book';

export function* requestBook() {
  try {
    const { baseCurrency } = yield select((state) => state.product);
    console.log(baseCurrency)
    const { token } = yield select((state) => state.auth);
    // yield put(bookActions.setTrades({ trades: [] }));
    // yield put(bookActions.setBook({ Asks: [], Bids: [] }));

    const data = { type: 'subscribe', product_ids: [baseCurrency.id], channels: ['candles', 'match', 'level2', 'order', 'ticker'], token };
    yield put(socketActions.send({ data }));
  } catch (error) {
    console.log(`[error] requestBook ${JSON.stringify(error)}`);
  }
}

export function* requestHistory() {
  try {
    const { baseCurrency } = yield select((state) => state.product);
    const { token } = yield select((state) => state.auth);
    const data = { type: 'subscribe', product_ids: [baseCurrency.id], channels: ['candles', 'match', 'level2', 'order','ticker'], token };
    yield put(socketActions.send({ data, cmd: '' }));
  } catch (error) {
    console.log(`[error] requestHistory ${JSON.stringify(error)}`);
  }
}

export function* listenSocketResponse(action) {
  const { data } = action.payload;
  if (data?.type === 'snapshot') {
    yield put(bookActions.setBook({ Asks: data.asks, Bids: data.bids }));
  }
  if (data?.type === 'l2update') {
    const { baseCurrency } = yield select((state) => state.product);
    if (baseCurrency.id === data.productId) {
      const { bids, asks } = yield select((state) => state.book);
      const { newBids, newAsks } = mergeBidsAndAsksReceived({ changes: data.changes, currentBids: bids, currentAsks: asks });
      yield put(bookActions.setBook({ Asks: newAsks, Bids: newBids }));
    }
  }
  if (data?.type === 'ticker') {
    yield put(bookActions.updateTicker(data));
  }
}

export default all([
  takeEvery('@socket/response', listenSocketResponse),
  takeLatest('@book/requestHistory', requestHistory),
  takeLatest('@products/setBaseCurrency', requestBook),
  takeLatest('@book/setCurrency', requestHistory),
]);
