import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import * as ProductActions from './actions';
import * as SocketActions from '../socket/actions';
import * as BookService from '~/services/api/book.api';

function* requestProducts() {
  try {
    const { data } = yield call(BookService.RequestProducts);
    const baseCurrency = data[0];
    yield put(ProductActions.setBaseCurrency({ baseCurrency }));
    return yield put(ProductActions.responseProducts({ success: true, products: data }));
  } catch (error) {
    return yield put(ProductActions.responseProducts({ success: false, error: 'fail_load_products' }));
  }
}

function* updateProductPricesWithWalletValues() {
  try {
    const { products, baseCurrency } = yield select((state) => state.product);
    const { wallets } = yield select((state) => state.wallet);



    if (!wallets.length || !products.length) {
      return false;
    }

    let includesProducts =  [].concat(products.map((p) => p.quoteCurrency),products.map((p) => p.baseCurrency))


    const wKeysWithValues = {};
    wallets.filter(w => includesProducts.includes(w.Currency)).forEach((w) => {
      wKeysWithValues[w.Currency] = {
        ...w,
      };
    });

    const newProducts = products.map((p) => ({
      ...p,
      baseBook: wKeysWithValues[p.baseCurrency].Book,
      baseAvailable: wKeysWithValues[p.baseCurrency].Available,
      quoteBook: wKeysWithValues[p.quoteCurrency] ? wKeysWithValues[p.quoteCurrency].Book : '0',
      quoteAvailable: wKeysWithValues[p.quoteCurrency] ? wKeysWithValues[p.quoteCurrency].Available : '0',
    }));

    console.log(newProducts)

    yield put(ProductActions.responseProducts({ success: true, products: newProducts }));

    const updatedBaseCurrency = newProducts.find((nP) => nP?.id === baseCurrency?.id);
    console.log(updatedBaseCurrency)
    if (updatedBaseCurrency) {
      yield put(ProductActions.setBaseCurrency({ baseCurrency: updatedBaseCurrency }));
    }
  } catch (error) {
    console.log('[error] updateProductPricesWithWalletValues',error);
  }
}

function* unsubscribeBaseCurrency(action) {
  try {
    const { baseCurrency } = action.payload;
    const { token } = yield select((state) => state.auth);
    const data = { type: 'unsubscribe', product_ids: [baseCurrency.id], channels: ['candles', 'match', 'level2', 'order', 'ticker'], token };
    yield put(SocketActions.send({ data }));
  } catch (error) {
    console.log('error unsubscribe');
  }
}

function* subscribeBaseCurrency(action) {
  try {
    const { baseCurrency } = action.payload;
    const { token } = yield select((state) => state.auth);
    const data = { type: 'subscribe', product_ids: [baseCurrency.id], channels: ['candles', 'match', 'level2', 'order', 'ticker'], token };
    yield put(SocketActions.send({ data }));
  } catch (error) {
    console.log('error subscribe');
  }
}

export default all([
  takeLatest('@products/requestProducts', requestProducts),
  takeLatest('@products/unsubscribeBaseCurrency', unsubscribeBaseCurrency),
  takeLatest('@products/subscribeBaseCurrency', subscribeBaseCurrency),
  takeLatest('@products/updateProductPricesWithWalletValues', updateProductPricesWithWalletValues),
]);
