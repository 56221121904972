import {
  call, put, select, all, takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';
import { commands,
  decimalsWallet, priceUrl, CurrencyAviable, priceToken, fullnames,
} from '~/config/environments';
import history from '~/services/history';
import * as walletActions from './actions';
import * as authActions from '../auth/actions';
import * as bookActions from '../book/actions';
import * as productActions from '../product/actions';
import * as WalletService from '~/services/api/wallet.api';

// function* GetPrice(currency, main) {
//   try {
//     let urlSource = '';
//     const currencyUp = currency.toUpperCase();

//     CurrencyAviable.forEach((i) => {
//       if (i.source && i.currency === currencyUp) {
//         urlSource = `&source=${i.source}`;
//       }
//     });
//     const url = `${priceUrl}?symbol=${currencyUp}&convert=${main}${urlSource}`;
//     const options = { headers: { 'x-token': priceToken } };
//     const { data } = yield call(axios.get, url, options);
//     return data[0].quotes[0].price;
//     // const price = yield call(formatObject, data, currency);
//     // return price;
//   } catch (error) {
//     return '';
//   }
// }

// function* updateCurrenciesWithPrice(walletsWithPrice) {
//   const { currencies, currency } = yield select((state) => state.book);
//   const currenciesWithPrice = currencies.map((c) => {
//     const names = c.name.split('/');
//     const firstCryptoname = walletsWithPrice.find((wallet) => wallet.Key === names[0]);
//     const secondCryptoname = walletsWithPrice.find((wallet) => wallet.Key === names[1]);
//     const availables = [firstCryptoname.Available, secondCryptoname.Available];
//     const balances = [firstCryptoname.Balance, secondCryptoname.Balance];
//     return { ...c, availables, balances };
//   });
//   const currencyUpdated = currenciesWithPrice.find((c) => c.name === currency.name);
//   yield put(bookActions.updateCurrenciesWithAvailableAndBanalce({ currencies: currenciesWithPrice, currency: currencyUpdated }));
// }

// function* translateWallets(wallets) {
//   const walletsMap = [];
//   const requests = [];
//   for (let index = 0; index < wallets.length; index += 1) {
//     const el = wallets[index];
//     const decimalPlaces = decimalsWallet[el.Key];

//     el.Balance = parseFloat(el.Balance / 100000000).toFixed(decimalPlaces);
//     el.Locked = parseFloat(el.Locked / 100000000).toFixed(decimalPlaces);
//     el.Available = parseFloat(+el.Balance + +el.Locked).toFixed(decimalPlaces);

//     requests.push(call(GetPrice, el.Key, 'TUSD'));
//     walletsMap.push(el);
//   }

//   const prices = yield all(requests);

//   prices.forEach((pr, index) => {
//     walletsMap[index].Price = pr;
//   });

//   let balance = 0;
//   let inOrder = 0;

//   walletsMap.forEach((i, index) => {
//     balance += i.Balance * i.Price;
//     inOrder += i.Locked * i.Price;
//     walletsMap[index].Fullname = fullnames[i.Key];
//   });

//   yield call(updateCurrenciesWithPrice, walletsMap);
//   yield put(walletActions.setWallets(walletsMap));
//   yield put(walletActions.setBalance({ balance, inOrder }));
// }

// function* listenSocketResponse(action) {
//   const { cmd, data, success } = action.payload.data;
//   if (cmd === commands.BALANCE) {
//     if (success) {
//       const wallets = JSON.parse(data);
//       yield call(translateWallets, wallets);
//     }
//   }
// }

function* requestWallet(action) {
  try {
    const { per_page, page } = action.payload;
    const { data } = yield call(WalletService.RequestWallet, { page, per_page });
    yield put(walletActions.responseWallet({
      ...data,
      wallets: data.data,
      success: true,
    }));
    yield put(productActions.updateProductPricesWithWalletValues());
  } catch (error) {
    yield put(authActions.logout());
    history.push('/signin');
    return yield put(walletActions.responseWallet({
      success: false,
      error: error?.response?.data?.message,
    }));
  }
}

export default all([
  // takeEvery('@socket/response', listenSocketResponse),
  takeLatest('@wallet/requestWallet', requestWallet),
]);
