import { put, all, takeLatest, call, select, delay } from 'redux-saga/effects';
import { ToastFailed, ToastSuccess } from '~/utils/toast';
import history from '~/services/history';
import * as orderActions from './actions';
import * as walletActions from '../wallet/actions';
import * as OrderService from '~/services/api/order.api';
import * as authActions from '../auth/actions';

export function* requestDeleteOrder(action) {
  try {
    const { orderId } = action.payload;
    yield call(OrderService.RequestDeleteOrder, { orderId });
    yield delay(2000);
    yield put(orderActions.responseDeleteOrder({ success: true }));
    yield put(orderActions.requestOrders());
  } catch (error) {
    yield put(orderActions.responseDeleteOrder({ success: false }));
  }
}

export function* requestDirectPrice(action) {
  try {
    const { productId,type } = action.payload;
    const {data} = yield call(OrderService.RequestDirectPrice, {productId,type});
    yield delay(1200);
    yield put(orderActions.responseDirectPrice({ success: true, directPrice: data }));

  } catch (error) {
    console.log(error)
    // yield put(authActions.logout());
  }
}


export function* requestOrders(action) {
  try {
    const hasDelay = action?.payload?.delay;
    if (hasDelay) {
      yield delay(hasDelay);
    }
    const { query, limit } = yield select((state) => state.order);
    const { data } = yield call(OrderService.RequestOrders, { query, per_page:limit });
    console.log(data)
    yield put(orderActions.responseOrders({ success: true, orders: data.data }));
  } catch (error) {
    yield put(authActions.logout());
    yield put(orderActions.responseOrders({ success: false, orders: [] }));
  }
}



function* requestCreateOrder(action) {
  try {
    const { side, price, size, type, productId } = action.payload;
    yield call(OrderService.RequestCreateOrder, {
      side,
      price: parseFloat(price),
      size: parseFloat(size),
      type,
      productId,
    });
    yield put(orderActions.responseCreateOrder({ success: true }));
    yield put(orderActions.setFormValues({ price: '0', amount: '0' }));

    yield put(walletActions.requestWallet({ page: 1, per_page: 100 }));
    ToastSuccess('order_successfull_created');
    yield put(orderActions.requestOrders({ delay: 6000 }));
  } catch (error) {
    if (error?.response?.data?.code === '10017') {
      yield put(authActions.logout());
      ToastFailed('expired_session');
      return history.push('/signin');
    }
    const message = error?.response?.data?.message;
    yield put(orderActions.responseCreateOrder({ success: false }));
    ToastFailed(message || 'fail_to_create_order');
  }
}

export default all([
  takeLatest('@order/requestDeleteOrder', requestDeleteOrder),
  takeLatest('@order/requestCreateOrder', requestCreateOrder),
  takeLatest('@order/requestOrders', requestOrders),
  takeLatest('@order/requestDirectPrice', requestDirectPrice),
]);
