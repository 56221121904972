import styled from 'styled-components';

export const Container = styled.section`
  flex: 1;
  justify-content:center;
  align-items:center;
  padding: 10px;
  padding-bottom:50px;
`;

export const ContainerCryptoInfo = styled.div`
  margin-top:20px;
  width:400px;

  @media(max-width:500px){
    width: 300px;
  }
  & section:nth-child(2) {
    margin-top: 10px;
  }
`;
