import Axios from 'axios';
import { getToken } from '../safeToken';

import endpoints from '../../config/endpoints';

const baseURL = endpoints.order;

const Fee = Axios.create({
  baseURL,
});

Fee.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export function RequestFees() {
  return Fee.get('/web/v1/assets');
}
