import produce from 'immer';

const INITIAL_STATE = {
  data: null,
  error: '',
  message: '',
  loading: false,
};

export default function register(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@register/requestRegister':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.message = '';
        draft.error = '';
      });
    case '@register/responseRegister':
      return produce(state, (draft) => {
        const { error, message } = action.payload;
        draft.message = message;
        draft.error = error;
        draft.loading = false;
      });
    case '@register/requestEmailValidation':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.message = '';
        draft.error = '';
      });
    case '@register/responseEmailValidation':
      return produce(state, (draft) => {
        draft.loading = false;
        draft.message = action.payload.message;
        draft.error = action.payload.error;
      });
    case '@register/requestChangePassword':
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case '@register/responseChangePassword':
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case '@register/setError':
      return produce(state, (draft) => {
        draft.error = action.payload.error;
      });
    case '@register/setMessage':
      return produce(state, (draft) => {
        draft.message = action.payload.message;
      });
    case '@register/change':
      return produce(state, (draft) => {
        const { property, value } = action.payload;
        draft[property] = value;
      });
    default:
      return state;
  }
}
