import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top:10px;
  padding:15px;
  display:block;
  height:400px;
  max-height:400px;
  overflow:auto;
  & h1{
    width:100%;
    text-align:center;
    font-size:15px;
    margin-bottom:15px;
    span{
      color:#f7921e;
      cursor: pointer;
    }
  }

  & .trade-item{
    min-height:30px;
    width:100%;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    background-color:${({ theme }) => theme.default_bg_color};
    border-radius:20px;
    padding-left:10px;
    padding-right:10px;
    margin-bottom:10px;
  }
`;
