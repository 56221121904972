import React, {useEffect } from 'react';
import { FaCommentDots } from 'react-icons/fa';
import { MdLanguage } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from './styles';
import * as GeneralActions from '~/store/modules/general/actions';
import { changeLanguage } from '~/config/i18n';

export default function FooterChangeLanguage() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { language, languages } = useSelector((state) => state.general);

  function handleSelectPress(lang) {
    dispatch(GeneralActions.setLanguage({ language: lang }));
    changeLanguage(lang.key);
    sessionStorage.setItem('lang', lang.key);
    setOpen(false);
  }

  useEffect(() => {
    let lang = sessionStorage.getItem("lang");
    if (lang == null) {
      lang = "pt_br";
    }
    let langSelected = languages.find(item => item.key == lang)
    handleSelectPress(langSelected);
    
  }, [])

  return (
    <Container>
      <div className="container-footer-item">
        <span tabIndex="-1" role="button" onClick={() => setOpen(!open)}>
          <MdLanguage size={20} />
          <h1>{language.name}</h1>
        </span>
        {open && (
        <ul>
          {languages.map((l) => (
            <li
              role="menuitem"
              key={l.key}
              onClick={() => handleSelectPress(l)}
            >
              {l.name}
            </li>
          ))}
        </ul>
        )}
      </div>
      <div className="container-footer-item footer-whatsapp">
        <a alt="open whatsapp" href="https://mazzera.tomticket.com" rel="noreferrer" target="_blank">
          <FaCommentDots size={20} />
          <h1>support</h1>
        </a>
      </div>
    </Container>
  );
}
