import Axios from 'axios';
import { getToken } from '../safeToken';

import endpoints from '../../config/endpoints';

const Swap = Axios.create({
  baseURL: endpoints.ledger,
});

Swap.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export function GetSwapHistory({ page, per_page }) {
  return Swap.post('/web/v1/client/cryptobacks', { page, per_page });
}

export function GetSwapTotal() {
  return Swap.get('/web/v1/client/cryptoback/total');
}
export function GetSwapRescue() {
  return Swap.get('/web/v1/client/cryptoback/rescue');
}
