import styled from 'styled-components';
import { Button as ButtonStyle } from '~/styles/components/buttons';

export const Container = styled.section`
  min-height: 500px;
  width: 100%;
  background-color: ${({ theme }) => theme.content_bg_color};
  padding: 20px;

  & #withdraw_blu_image_message{
    width:100%;
    max-width:300px;
    margin-top:10px;
    align-self:center;
  }

  & #withdraw_blu_message{
    color: ${({ theme }) => theme.primary_color};
    text-align:center;
    align-self:center;
    font-size:12px;
  }

  & .qr_code_alert {
    margin-top:20px;
    margin-bottom:20px;
  }

  & .footer-container-qr{
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
  }

  & .footer-qrcode-ras p{
    font-size:15px!important;
    text-align:center;
    margin-bottom:10px;
  }

  & .footer-qrcode-ras img{
    border:solid 1px gray;
    border-radius:50px;
    padding:10px;
    max-width:170px;
  }

  & .withdraw_amount {
    margin-top:20px;
    margin-bottom:20px;
    align-self:center;
    align-items:center;
  }

  & .withdraw_amount h2 {
    font-size:16px;
    font-weight:normal;
  }

  & .withdraw_amount h1 {
    font-size:18px;
  }

  & .header_qrcode {
    flex-direction: row;
    align-items: center;
    align-self: center;
  }

  & .header_qrcode > div {
    margin-left: 10px;
  }

  & .body_qrcode {
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  & .flag_top_left {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-right-radius: 40px;
    background-color: ${({ theme }) => theme.sell_color};
    padding: 5px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  & .withdraw_container_info{
    margin-top:20px;
  }

  @media(max-width:700px){
    & .footer-container-qr{
      flex-direction:column;
    }
    & .footer-qrcode-ras{
      margin-top:10px;
    }
  }
`;

export const ContainerInput = styled.div`
  & {
    width:80%;
    margin-top:${({ inputValue }) => (inputValue ? 30 : 0)}px;
    display:block;
  }

  & p{
    font-size:16px;
    align-self:center;
    margin-top:10px;
    margin-bottom:10px;
  }

  & .span-input-currency {
    position: absolute;
    right: 10px;
    top: 14px;
    color: #fff;
  }

  & .disabled-input-withdraw{
    opacity:0.5;
  }

  & input {
    border-color:${({ theme }) => theme.content_selected_bg_color};
    text-align: center;
    color: #fff;
    border-style:solid;
    border-width:1px;
  }
  & input.withdraw_value{
    border-style: none none solid none ;
    border-radius:0px;
    font-size:20px;
  }
`;

export const ContainerButtons = styled.div`
  flex-direction:row;
  width:80%;
  justify-content:space-around;
  margin-top:20px;
`;

export const LightButton = styled.div`
  width:70px;
  height:40px;
  justify-content:center;
  align-items:center;
  font-size:16px;
  border-radius:10px;
  cursor: pointer;
  color:${({ theme, selected }) => (selected ? theme.content_bg_color : '#fff')};
  background-color:${({ theme, selected }) => (selected ? theme.sell_color : theme.content_bg_color)};
  &:hover{
    color:${({ theme }) => theme.content_bg_color};
    background-color:${({ theme }) => theme.sell_color};
  }
`;

export const Button = styled(ButtonStyle)`
  margin-top:20px;
  background-color: ${({ theme }) => theme.register_color};
  color: ${({ theme }) => theme.content_bg_color};
  width:200px;
  opacity:${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`;

export const ContainerInputVertical = styled.div`
`;




