import React from 'react';
import PropTypes from 'prop-types';
// import { Container } from './styles';
import { ReactComponent as WbxSvgIcon } from '~/assets/svg/icon_wbx.svg';
import { ReactComponent as DaiSvgIcon } from '~/assets/svg/icon_dai.svg';
import { ReactComponent as DgbSvgIcon } from '~/assets/svg/icon_dgb.svg';
import { ReactComponent as BtcSvgIcon } from '~/assets/svg/icon_btc.svg';
import { ReactComponent as TusdSvgIcon } from '~/assets/svg/icon_tusd.svg';
import { ReactComponent as UsdtSvgIcon } from '~/assets/svg/icon_usdt.svg';
import { ReactComponent as BluSvgIcon } from '~/assets/svg/icon_blu.svg';
import { ReactComponent as ZcrSvgIcon } from '~/assets/svg/icon_zcr.svg';
import { ReactComponent as RasSvgIcon } from '~/assets/svg/icon_ras.svg';
import { ReactComponent as DashSvgIcon } from '~/assets/svg/icon_dash.svg';
import { ReactComponent as EthSvgIcon } from '~/assets/svg/icon_eth.svg';
import { ReactComponent as BchSvgIcon } from '~/assets/svg/icon_bch.svg';
import { ReactComponent as BtcqSvgIcon } from '~/assets/svg/icon_btcq.svg';
import { ReactComponent as ZarSvgIcon } from '~/assets/svg/icon_zar.svg';
import { ReactComponent as SmartSvgIcon } from '~/assets/svg/icon_smart.svg';
import { ReactComponent as XrpSvgIcon } from '~/assets/svg/xrp_icon.svg';
import { ReactComponent as B2uSvgIcon } from '~/assets/svg/icon_b2u.svg';
import { ReactComponent as TreepSvgIcon } from '~/assets/svg/icon_treep.svg';
import { ReactComponent as KsocSvgIcon } from '~/assets/svg/icon_ksoc.svg';
import { ReactComponent as XpixSvgIcon } from '~/assets/svg/icon_xpix.svg';
import { ReactComponent as BeckSvgIcon } from '~/assets/svg/icon_beck.svg';
import { ReactComponent as BusdSvgIcon } from '~/assets/svg/icon_busd.svg';
import { ReactComponent as XlmSvgIcon } from '~/assets/svg/icon_xlm.svg';
import { ReactComponent as LightSvgIcon } from '~/assets/svg/icon_light.svg';
import { ReactComponent as UsdcSvgIcon } from '~/assets/svg/icon_usdc.svg';

import { ReactComponent as CusdSvgIcon } from '~/assets/svg/icon_cusd.svg';
import { ReactComponent as DrlSvgIcon } from '~/assets/svg/icon_drl.svg';


import { ReactComponent as SfySvgIcon } from '~/assets/svg/icon_sfy.svg';
import { ReactComponent as FySvgIcon } from '~/assets/svg/icon_fy.svg';
import { ReactComponent as MtySvgIcon } from '~/assets/svg/icon_mty.svg';


function svgComponent({ name, size }) {
  if (name === 'FY') {
    return (
      <FySvgIcon width={size} />
    );
  }
  if (name === 'DRL') {
    return (
      <DrlSvgIcon width={size} />
    );
  }
  if (name === 'CUSD') {
    return (
      <CusdSvgIcon width={size} />
    );
  }
  if (name === 'MTY') {
    return (
      <MtySvgIcon width={size} />
    );
  }
  if (name === 'XPIX') {
    return (
      <XpixSvgIcon width={size} />
    );
  }
  if (name === 'USDC') {
    return (
      <UsdcSvgIcon width={size} />
    );
  }
  if (name === 'SFY') {
    return (
      <SfySvgIcon width={size} />
    );
  }
  if (name === 'BUSD') {
    return (
      <BusdSvgIcon width={size} />
    );
  }
  if (name === 'LIGHT') {
    return (
      <LightSvgIcon width={size} />
    );
  }

  if (name === 'XLM') {
    return (
      <XlmSvgIcon width={size} />
    );
  }
  if (name === 'BECK') {
    return (
      <BeckSvgIcon width={size} />
    );
  }
  if (name === 'TREEP') {
    return (
      <TreepSvgIcon width={size} />
    );
  }
  if (name === 'BTC') {
    return (
      <BtcSvgIcon width={size} />
    );
  }
  if (name === 'BTCQ') {
    return (
      <BtcqSvgIcon width={size} />
    );
  }
  if (name === 'KSOC') {
    return (
      <KsocSvgIcon width={size} />
    );
  }
  if (name === 'SMART') {
    return (
      <SmartSvgIcon width={size} />
    );
  }
  if (name === 'ZAR') {
    return (
      <ZarSvgIcon width={size} />
    );
  }
  if (name === 'BCH') {
    return (
      <BchSvgIcon width={size} />
    );
  }
  if (name === 'ETH') {
    return (
      <EthSvgIcon width={size} />
    );
  }
  if (name === 'DASH') {
    return (
      <DashSvgIcon width={size} />
    );
  }
  if (name === 'TUSD') {
    return (
      <TusdSvgIcon width={size} />
    );
  }
  if (name === 'RAS') {
    return (
      <RasSvgIcon width={size} />
    );
  }
  if (name === 'ZCR') {
    return (
      <ZcrSvgIcon width={size} />
    );
  }
  if (name === 'BLUE') {
    return (
      <BluSvgIcon width={size} />
    );
  }
  if (name === 'USDT') {
    return (
      <UsdtSvgIcon width={size} />
    );
  }
  if (name === 'XRP') {
    return (
      <XrpSvgIcon width={size} />
    );
  }
  if (name === 'B2U') {
    return (
      <B2uSvgIcon width={size} />
    );
  }
  if (name === 'WBX') {
    return <WbxSvgIcon width={size} />;
  }
  if (name === 'DAI') {
    return <DaiSvgIcon width={size} />;
  }
  if (name === 'DGB') {
    return <DgbSvgIcon width={size} />;
  }

  return null;
}

svgComponent.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default svgComponent;
