import styled from 'styled-components';

export const Container = styled.section`
  flex:1;
  width:100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 80px;
  min-height: 80px;
  padding: 20px;
  margin-top:10px;

  img {
    display:none;
    vertical-align: middle;
    border-style: none;
    width: 100%;
    height: auto;
    max-width: 150px;
  }

  & > h2{
    font-size:14px;
  }

  & > div {
    flex: 1;
    min-width:100px;
    max-width:100px;
    align-items: center;
    justify-content: center;
  }

  & .circle {
    width: 12px;
    height: 12px;
    border: none;
    border-radius: 12px;
    position: absolute;
    left: -10px;
  }

  & .circle.green {
    background-color: ${({ theme }) => theme.hight_color};
  }
  & .circle.red {
    background-color: ${({ theme }) => theme.low_color};
  }

  .hide-when-mobile{
    display:none;
  }

  @media (min-width: 1100px) {
    &{
      padding:0px;
      margin-top:0px;
    }
    & img{
      display:flex;
    }
  }
  @media (min-width: 1300px) {
    &{
      padding:0px;
    }
    .hide-when-mobile {
      display: flex;
    }
  }
`;
