import Axios from 'axios';
import { getToken } from '../safeToken';

import endpoints from '../../config/endpoints';

const Auth = Axios.create({
  baseURL: endpoints.client,
});

Auth.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export function Login({ username, password }) {
  return Auth.post('/auth/signin', { username, password });
}

export function Confirm2fa({ code }) {
  return Auth.post('/web/v1/secret/confirm', { code });
}

export function Register({ name, email, phone, password }) {
  return Auth.post('/auth/signup', { name, email, phone, password });
}

export function EmailValidation({ code, email }) {
  return Auth.post('/auth/signup/confirm', { code, email });
}

export function RequestSecret() {
  return Auth.get('/web/v1/secret/register');
}

export function ConfirmSecretCode({ code }) {
  return Auth.post('/web/v1/secret/register/confirm', { code });
}

export function ConfirmOtpLogin({ code }) {
  return Auth.post('/web/v1/secret/confirm', { code });
}

export function RecoveryPassword({ username }) {
  return Auth.post('/auth/forget/password', { username });
}

export function RecoveryUserName({ email }) {
  return Auth.post('/auth/forget/username', { email });
}

export function ChangePassword({ username, code, new_password }) {
  return Auth.post('/auth/forget/password/confirm', { username, code, new_password });
}
