import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  fees: [],
};

export default function wallet(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@fee/requestFee':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = '';
      });
    case '@fee/responseFee':
      return produce(state, (draft) => {
        draft.loading = false;
        if (action.payload.success) {
          draft.fees = action.payload.fees;
        } else {
          draft.error = 'fail_load_fees';
        }
      });
    default:
      return state;
  }
}
