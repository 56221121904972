import styled from 'styled-components';

export const Container = styled.section`
  & .container-body-book {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom:50px;
  }
  & .book-maintance {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    height: 200px;
    padding: 218px;
    font-size: 31px;
  }

  @media (min-width: 600px) {
    & .container-price-currency {
      display: flex;
      flex-direction: row;
    }
    & .container-price-currency > :nth-child(1) {
      margin-right: 10px;
    }
    & .container-buy-trades-spread {
      flex-direction: row;
    }
    & .container-buy-trades-spread .container-buy-trades {
      margin-right: 10px;
      width: 100%;
      display: flex;
    flex-direction: row;
    }
  }
  @media (min-width: 1100px) {
    & .show-when-larger {
      display: flex;
    }

    & .hide-when-larger {
      display: none;
    }

    & .container-price-currency {
      display: flex;
      flex-direction: row-reverse;
      padding-left: 0px;
      padding-right: 0px;
    }

    & .container-price-currency > :nth-child(1) {
      margin-right: 0px;
    }

    & .container-body-book {
      display: flex;
      flex-direction: row-reverse;
      min-height:100vh;
    }

    & .container-body-book > .container-tradeview-history {
      width: 75%;
    }

    & .container-buy-trades-spread .container-buy-trades {
      margin-right: 0px;
    }

    & .container-body-book .container-buy-trades-spread {
      flex-direction: row-reverse;
      width: 25%;
      margin-left: 10px;
      height: 100%;
      & .bg-content{
        max-height: none;
        overflow: auto;
        min-height: 869px;
      }
    }

    & .container-body-book .container-buy-trades {
      flex-direction: column;
      margin-left: 10px;
      width: 100%;
    }
  }
`;
