import styled from 'styled-components';
import { Button as ButtonStyle } from '~/styles/components/buttons';

export const Container = styled.section`
  min-height: 500px;
  width: 100%;
  background-color: ${({ theme }) => theme.content_bg_color};
  padding: 20px;
  max-width:400px;
  border-radius:10px;
  overflow-x:hidden;

  & .flag_top_left {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-right-radius: 40px;
    background-color: ${({ theme }) => theme.buy_color};
    padding: 5px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  & header svg{
    margin-right:10px;
  }

  & header {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    min-height:80px;
    border-bottom:solid 1px #1115;
  }
  & strong{
    padding-top:5px;
  }
  & strong{
    font-size:12px;
  }
  & p{
    font-size:12px;
    padding-top:20px;
    width:90%;
    align-self:center;
  }
  & .btc-line-value strong:nth-child(2){
    text-align:right;
  }
  & .btc-line-value strong{
    min-width:100px;
  }
  & .btc-line-value{
    width:90%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    align-self:center;
  }
  & .container-info-swap{
    display:flex;
    flex:1;
  }

  @media(max-width:1000px){
    max-width:100%;
  }
`;

export const Button = styled(ButtonStyle)`
  margin-top:20px;
  background-color: ${({ theme }) => theme.primary_color};
  color: ${({ theme }) => theme.text_color};
  width:200px;
  opacity:${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  align-self:center;
  ${({ theme }) => theme.shadow_button};
`;
