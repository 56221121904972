import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & .link-to-book {
    display: inline-block;
    min-height: 30px;
    svg {
      margin-bottom: -2px;
    }
  }

  & header {
    align-items: center;
  }

  & h1 {
    margin-top: 10px;
  }

  & .container-card {
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    min-height: 450px;
    background-color: ${({ theme }) => theme.content_bg_color};
    border-radius: 10px;
    ${({ theme }) => theme.shadow};
  }

  & .container-success > svg {
    min-height: 120px;
    fill: ${({ theme }) => theme.buy_color};
  }

  & .container-success > .svg-error {
    fill: ${({ theme }) => theme.sell_color};
  }

  & .container-card svg {
    width: 150px;
    height: 20px;
  }

  & .container-input {
    min-height: 80px;
    margin-top: 20px;
    width: 100%;

    a {
      font-size: 10px;
      text-decoration: underline;
      color: #fff8;
    }

    .error-message {
      color: ${({ theme }) => theme.error_color};
      align-self: center;
    }
    .input-mask{
      background-color: ${({ theme }) => theme.default_bg_color};
      border:none;
      border-radius:10px;
      height:40px;
    }
  }
  & .container-input input{
    margin-top:10px;
    margin-bottom:10px;
  }

  & button.btn {
    width: 200px;
    font-size: 15px;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: bold;
    color: ${({ theme }) => theme.content_bg_color};
    background-color: ${({ theme }) => theme.register_color};
  }

  & button.btn-next {
    background-color: ${({ theme }) => theme.buy_color};
    margin-top: 20px;
  }

  & .container-step {
    width: 100%;
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 30px;
    padding-right: 30px;

    button {
      background-color: transparent;
      border: none;
      position: absolute;
      left: 0px;
    }
    button svg {
      max-width: 50px;
      font-size: 12px;
    }
  }

  @media (max-width: 600px) {
    & {
      justify-content: flex-start;
      align-items: center;
      padding-top: 15px;
    }
  }
`;
