export function mergeBidsAndAsksReceived({ changes, currentBids, currentAsks }) {
  let newBids = [];
  let newAsks = [];
  const BidChanges = [];
  const AskChanges = [];

  changes.forEach((c) => {
    if (c[0] === 'buy') {
      BidChanges.push(c);
    } else {
      AskChanges.push(c);
    }
  });

  function mergeChanges(change, array) {
    let hasValue = false;
    let values = [];
    values = array.map((bid) => {
      if (bid[0] === change[1]) {
        hasValue = true;
        return [change[1], change[2], bid[2] + 1];
      }
      return bid;
    });
    // when new bid is received
    if (!hasValue) {
      values = [...values, [change[1], change[2], 1]];
    }
    return values;
  }

  function clean(array) {
    return array.filter((a) => {
      const n = parseFloat(a[1]);
      return n !== 0;
    });
  }

  BidChanges.forEach((change) => {
    console.log(change);
    newBids = mergeChanges(change, currentBids, newBids);
  });
  AskChanges.forEach((change) => {
    console.log(change);
    newAsks = mergeChanges(change, currentAsks, newAsks);
  });

  if (!BidChanges.length) {
    newBids = currentBids;
  }
  if (!AskChanges.length) {
    newAsks = currentAsks;
  }
  return { newBids: clean(newBids), newAsks: clean(newAsks) };
}
