import styled from 'styled-components';

export const Container = styled.section`
  height:100vh;
  display:block;

  & .left-container{
    margin-left:-1px;
    margin-top:-2px;
    z-index:1;
    display:block;
  }

  & .container-boxes{
    display:block;
    padding-bottom:50px;
  }

  & .box-larger h1{
    margin-bottom:20px;
  }

  & .box-larger{
    display:block;
    /* width:60vw; */
    width:100%;
    border:solid 1px #174A71;
    padding:20px;
    border-top:0px;
  }
  & .box-right{
    display:block;
    /* width:40vw; */
    width:100%;
    border-left:0px;
  }
  & .box-right svg{
    align-self:center;
    width:100%;
  }
  & .box-right h2{
    width:100%;
    text-align:center;
    font-size:20px;
    margin-top:10px;
    color: ${({ theme }) => theme.primary_color2};
    align-self:center;
  }
  .inline{
    /* flex-direction:row; */
    display:block;
    /* height:30px; */
    width:100%;
    margin-top:20px;
    align-items:center;
    justify-content:space-around;
  }

  & a {
    margin-left:5px;
    color:'#47A4E8'!important;
    text-align:center;
  }

  & .inline p:nth-child(1){
    width:110px;
  }

  & .row{
    height:50px;
    flex-direction:row;
    align-items:center;
  }

  & .row svg{
    margin-right:10px;
  }

  & .link{
    cursor:pointer;
    text-decoration:underline;
  }

  @media(min-width:400px){
    .inline{
      display:flex;
      flex-direction:row;
      height:30px;
    }
  }

  @media(min-width:1100px){
    .box-right{
      display:flex;
      align-items:center;
      width:45vw;
    }
    .inline{
      display:flex;
      flex-direction:row;
      height:30px;
      width:380px;
    }
    .box-larger{
      width:55vw;
    }
    .container-boxes{
      display:flex;
      flex-direction:row;
    }
  }
`;
