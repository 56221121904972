import React, { useEffect } from 'react';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Md5 from 'blueimp-md5';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import * as registerActions from '~/store/modules/register/actions';
import { Container } from './styles';
import { Input } from '~/styles/components/inputs';
import { Button } from '~/styles/components/buttons';
import StratumIcon from '~/assets/png/mazzera-logo.png';
import { LoadingAnimation } from '~/components/animation';

const registerSchema = Yup.object().shape({
  code: Yup.string().required('required_field'),
  password: Yup.string().required('required_field'),
  password_confirmation: Yup.string()
    .required('required_field')
    .test('passwords-match', 'password_not_match', function (value) {
      return this.parent.password === value;
    }),
});

export default function PageChangePassword({ match, history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { error, message, loading } = useSelector((state) => state.register);

  const formik = useFormik({
    validationSchema: registerSchema,
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    initialErrors: {
      password: '-',
      password_confirmation: '-',
    },
    onSubmit: (values) => {
      const password = Md5(values.password);
      const { code } = match.params;
      if (!code) {
        return dispatch(registerActions.setError('invalid_request_code'));
      }
      dispatch(registerActions.requestChangePassword({ code, password }));
    },
  });

  useEffect(() => {
    if (error) {
      return history.push('/account/error');
    }
    if (message) {
      return history.push('/account/message');
    }
  }, [history, error, message]);

  useEffect(() => {
    if (!match.params.step) {
      return history.push('/account/password_change');
    }
  }, [match, dispatch, history]);

  function handleBackPress() {
    history.goBack();
  }

  if (loading || !match.params.step) {
    return (
      <Container>
        <section>
          <Link to="/trade" className="link-to-book">
            <AiOutlineArrowLeft />
            {'      '}
            Go back to book
          </Link>
          <div className="container-card" style={{ justifyContent: 'center' }}>
            <LoadingAnimation width={100} height={100} />
          </div>
        </section>
      </Container>
    );
  }

  return (
    <Container>
      <section>
        <Link to="/trade" className="link-to-book">
          <AiOutlineArrowLeft />
          {'      '}
          Go back to book
        </Link>
        <div className="container-card">
          <header>
          <img src={StratumIcon} height="45" />
            <h1>Change Password</h1>
          </header>
          <>
            {(match.params.step === 'password_change') && (
            <>
              <div className="container-input">
                <span>Password</span>
                <Input
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="error-message">
                  {' '}
                  {formik.touched.password && formik.errors.password && t(formik.errors.password)}
                </span>
              </div>
              <div className="container-input">
                <span>Confirm password</span>
                <Input
                  type="password"
                  name="password_confirmation"
                  value={formik.values.password_confirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="error-message">
                  {' '}
                  {formik.touched.password_confirmation
                    && formik.errors.password_confirmation
                      && t(formik.errors.password_confirmation)}
                </span>
              </div>
              <Button className="btn btn-next" onClick={formik.handleSubmit}>
                Next
              </Button>
            </>
            )}

            {match.params.step === 'message' && (
            <>
              <div className="container-success">
                <FaCheckCircle />
              </div>
              <h2 style={{ textAlign: 'center' }}>{t(message)}</h2>
            </>
            )}

            {match.params.step === 'error' && (
            <>
              <div className="container-success">
                <FaTimesCircle className="svg-error" />
              </div>
              <h2>{t(error)}</h2>
            </>
            )}
          </>
          <div className="container-step">
            {match.params.step !== 'password_change' && (
              <button type="button" onClick={handleBackPress}>
                <AiOutlineArrowLeft style={{ marginTop: -15 }} />
              </button>
            )}
          </div>
        </div>
      </section>
    </Container>
  );
}

PageChangePassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
      code: PropTypes.string,
    }),
  }).isRequired,
};
