import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  border-radius: 5px;
  min-width: 70px;
  min-height: 30px;
  background-color: ${({ theme }) => theme.header_bg_color};
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  justify-content: center;
  align-items: center;

  @media(min-width:400px){
    &{
      min-height:40px;
    }
  }
`;
