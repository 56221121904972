import React from 'react';
import { useDispatch } from 'react-redux';
import { HeaderBook } from '~/components/headers';
import {
  BookBuyComponent,
  BookTradesComponent,
  BookSpreadComponent,
  BookTradeview,
  BookHistory,
} from '~/components/direct';

import { Container } from './styles';

import * as FeeActions from '~/store/modules/fee/actions';
import * as AuthActions from '~/store/modules/auth/actions';

export default function PageBook() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(FeeActions.requestFee());
    dispatch(AuthActions.cleanUnecessary());
  }, [dispatch]);

  return (
    <>
      <Container name="book" className="container-book">
        <HeaderBook />
        <div className="container-body-book">
        <BookSpreadComponent />
        <BookHistory />
        </div>
        {/* <div className="book-maintance">
           Estamos em manutenção voltamos em breve
        </div> */}
      </Container>
    </>
  );
}
