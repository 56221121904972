import styled from 'styled-components';
import { Button } from '~/styles/components/buttons';

export const Container = styled.section`
  display: block;
  width: 100%;
  height:400px;
  margin-top: 10px;
  padding: 15px;
  font-size: 10px;

  & .container-animation {
    width: 98%;
    height: 100%;
    background-color: rgba(35,33,64,0.3);
    position: absolute;
    left:0;
    top:0;
    z-index: 100;
    justify-content:center;
    align-items:center;
    div[role="button"]{
        
    }
  }
`;

export const ContainerButtons = styled.header`
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

export const ButtonOrder = styled(Button)`
  background-color: ${({ theme }) => theme.content_bg_color};
  border: solid 1px #fff;
  color: ${({ theme, selected }) => (selected ? theme.content_bg_color : '#fff')};

  &.button-buy {
    border-width: ${({ selected }) => (selected ? 0 : 1)}px;
    background-color: ${({ theme, selected }) => (selected ? theme.buy_color : theme.content_bg_color)};
  }

  &.button-sell {
    border-width: ${({ selected }) => (selected ? 0 : 1)}px;
    background-color: ${({ theme, selected }) => (selected ? theme.sell_color : theme.content_bg_color)};
  }
`;

export const ContainerInputs = styled.div`
  & .container-input-book {
    min-height: 80px;
    position:relative;
    padding: 15px 0 15px 0;
      select {
        background-color: #0b0a16;
        color: #fff;
        border:none;
      }
    & .span-label {
      text-align: left;
      position: absolute;
      top: -1px;
      z-index: 9;
      background: #0b0a16;
      left:10px;
      padding: 0 10px 0 10px;
    }
  }
  & .container-display-book {
    min-height: 40px;
    padding: 0 10px 0 10px;
    
    & .span-label {
      text-align: left;
      &.strong {
        font-weight:bold;
      }
    }
  }


  & .container-input-book .span-label {
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: left;
  }

  & .span-input-currency {
    position: absolute;
    left: 10px;
    top: 14px;
    color: #fff;
  }

  & input {
    border: solid 1px #fff;
    text-align: right;
    color:  #fff;
  
  }

  & .button-buy {
    border: none;
    margin-top: 20px;
    background-color: ${({ theme, current }) => (current === 0 ? theme.buy_color : theme.sell_color)};
  }

  & .container-input-market {
    flex-direction: row;
  }

  & #input-market {
    min-width: 0px;
    flex: 1;
    width: auto;
    margin-right: 10px;
  }

  & #button-market {
    min-height: 0px;
    background-color: ${({ theme }) => theme.market_color};
    border-radius: 10px;
    min-height: 40px;
    color: ${({ theme, selected }) => (selected ? theme.sell_color : theme.content_bg_color)};
  }
`;
