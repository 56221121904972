import { call, put, all, takeLatest } from 'redux-saga/effects';
import * as DepositActions from './actions';
import * as DepositService from '~/services/api/deposit.api';

function* requestAddress(action) {
  try {
    const { currency } = action.payload;
    const { data } = yield call(DepositService.RequestAddress, { currency });
    return yield put(DepositActions.responseAddress({ success: true, error: '', address: data }));
  } catch (error) {
    return yield put(
      DepositActions.responseAddress({
        success: false,
        error: error?.response?.data?.message,
        address: null,
      }),
    );
  }
}

export function* requestDeposits(action) {
  try {
    const { currency } = action.payload;
    const query = {
      currency,
    };
    const { data } = yield call(DepositService.RequestDeposits, { page: 1, per_page: 100, query });
    return yield put(DepositActions.responseDeposits({
      success: true,
      deposits: data.data,
      error: '',
    }));
  } catch (error) {
    return yield put(DepositActions.responseDeposits({
      success: false,
      deposits: [],
      error: 'fail_load_deposits',
    }));
  }
}

function* requestCreateNewAddress(action) {
  try {
    const { currency } = action.payload;
    yield call(DepositService.CreateNewAddress, { currency });
    yield put(DepositActions.requestAddress({ currency }));
    yield put(DepositActions.responseCreateNewAddress({ success: true, error: '' }));
  } catch (error) {
    yield put(DepositActions.responseCreateNewAddress({
      success: false,
      error: error?.response?.data?.message,
    }));
  }
}

export default all([
  takeLatest('@deposit/requestDeposits', requestDeposits),
  takeLatest('@deposit/requestAddress', requestAddress),
  takeLatest('@deposit/requestCreateNewAddress', requestCreateNewAddress),
]);
