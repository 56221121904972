import React, { useState, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultWallets } from '~/config/environments';
import { ToastFailed } from '~/utils/toast';
import { CheckAddress } from '~/services/api/withdraw.api';
import * as WithdrawActions from '~/store/modules/withdraw/actions';
import * as FeeActions from '~/store/modules/fee/actions';

import { LoadingAnimation } from '~/components/animation';
import { Svg } from '~/components/iconSet';
import Swal from 'sweetalert2';
import xagoImage from '~/assets/png/mazzera-logo.png'

import { Container, Button, ContainerInput, ContainerButtons, LightButton, ContainerInputVertical } from './styles';
import { Input } from '~/styles/components/inputs';

import { ReactComponent as WithdrawIcon } from '~/assets/svg/withdraw.svg';


function WithdrawComponent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [open2faInput, setOpen2faInput] = useState(false);
  const [newFee, setNewFee] = useState(null);
  const [checkingFee, setCheckingFee] = useState(false);
  const [inputValues, setInputValues] = useState({
    value: '',
    address: '',
    percent: '',
    tag: '',
    code2fa: '',
  });
  const { currentWallet } = useSelector((state) => state.wallet);
  const { error, message, loading } = useSelector((state) => state.withdraw);
  const { fees } = useSelector((state) => state.fee);

  const conditionalWithdraw = useMemo(() => currentWallet?.Currency === 'BLU' || currentWallet?.Currency === 'ZAR' , [currentWallet]);

  const tagDiv = useMemo(() => {
    switch(currentWallet.Currency){
      case "XRP":
      case "ZAR":
        return "?dt="
      case "BLU":
        return "_"
      default:
        return ""
    }
  }, [currentWallet]);


  const currencyFee = useMemo(() => {
    if (typeof newFee === 'number') {
      return { fee: newFee };
    }
    if (fees.length) {
      return fees.find((f) => f.name === currentWallet.Currency);
    }
    dispatch(FeeActions.requestFee());
  }, [fees, currentWallet, dispatch, newFee]);

  function handlePressConfirm() {
    const amountWithFee = parseFloat(currencyFee?.fee) + parseFloat(inputValues.value);

    if (amountWithFee  == 0) {
      return;
    }

    if (amountWithFee <= parseFloat(currencyFee?.fee)*2) {

      return ToastFailed('withdraw_amount_minimum_required_modal', { min: parseFloat(currencyFee?.fee)*2, currency: currentWallet.Currency});
      
      return;
    }

    if (amountWithFee > currentWallet.Available) {
      setOpen2faInput(false);
      return ToastFailed('withdraw_check_your_available_amount_in', { crypto: currentWallet.Currency });
    }
    setOpen2faInput(true);
  }

  async function checkWithdrawAddress() {
    try {
      setCheckingFee(true);
      const { address } = inputValues;
      const currency = currentWallet.Currency;
      const { data } = await CheckAddress({ currency, address });
      setNewFee(data.fee);
      setCheckingFee(false);
    } catch (err) {
      setCheckingFee(false);
    }
  }

  function handleSubmitWithdrawWith2faCode({ code }) {
    const { value: amount, address, tag } = inputValues;
    const currency = currentWallet.Currency;
    const amountWithFee = parseFloat(currencyFee?.fee) + parseFloat(amount);
    let addressToSent = conditionalWithdraw ? `${defaultWallets[currency]}_${tag}` : address;
    
    if(currency == "XRP" && tag !== ""){
      addressToSent +=`_${tag}`
    }
    dispatch(WithdrawActions.requestNewWithdraw({ currency, amount: amountWithFee, address: addressToSent, otp: code }));
  }

  function handleChangeInputValue(event) {
    const { name, value } = event.target;
    const newValues = { ...inputValues };

    if (name === 'address') {
      newValues[name] = value;
      setInputValues(newValues);
      return true;
    }
    if (name === 'tag') {
      newValues[name] = value;
      setInputValues(newValues);
      return true;
    }
    if (name === 'value') {
      if (value !== '') {
        const regex = new RegExp(/^[\d.]+$/, 'g');
        if (!regex.test(value)) return true;
      }
      newValues[name] = value;
      newValues.percent = ((value * 100) / currentWallet.Available);
      setInputValues(newValues);
      return true;
    }
    if (name === 'percent') {
      newValues.value = currentWallet.Available * (value / 100);
    }

    if (name === 'code2fa' && value.length === 6) {
      handleSubmitWithdrawWith2faCode({ code: value });
    }

    

    newValues[name] = value;
    setInputValues(newValues);
  }

  return (
    <Container title="withdraw">
      <div className="flag_top_left">
        <WithdrawIcon width={30} />
      </div>
      <div className="header_qrcode">
        {!!currentWallet?.Currency && (
          <Svg name={currentWallet.Currency} size={40} />
        )}
        <div>
          <h1>{currentWallet.Currency}</h1>
          <h4>{t('withdraw')}</h4>
        </div>
      </div>
      <div className="body_qrcode">
        {loading && <LoadingAnimation width={100} height={100} />}
        {!loading && !open2faInput && (
          <>
            {currentWallet.Currency == "BLU" && (
              <img id="withdraw_blu_image_message" src={`${process.env.PUBLIC_URL}/withdraw_blu_message.png`} alt="mensagem carteira blu" />
            )}
            {currentWallet.Currency == "ZAR" && (
              <img id="withdraw_blu_image_message" src={xagoImage} alt="mensagem carteira blu" />
            )}
            <div className="withdraw_amount">
              <h2>
                {t('available_amount') }
              </h2>
              <h1>
                {parseFloat(currentWallet.Available).toFixed(currentWallet.quoteScale)}
              </h1>
            </div>
            {
              (currentWallet.Currency === "XRP") ? 
              (
               <>
                <ContainerInput>
                      <p>{t('withdraw_address')}</p>
                      <Input
                        name="address"
                        type="text"
                        value={inputValues.address}
                        onChange={handleChangeInputValue}
                        onBlur={() => checkWithdrawAddress()}
                      />
                    </ContainerInput>
                    <ContainerInput>
                    <p>TAG</p>
                    <Input
                      name="tag"
                      type="text"
                      value={inputValues.tag}
                      onChange={handleChangeInputValue}
                    />
                    <p id="withdraw_blu_message">
                      {currentWallet.Currency == "BLU" && t('smartwallet_address')}
                      {currentWallet.Currency == "BLU" && t('xago_address')}
                      <br />
                      {defaultWallets[currentWallet.Currency]}
                    </p>
                  </ContainerInput>
               </>
              ):
              (
                <>
                {conditionalWithdraw  ? (
                  <ContainerInput>
                    <p>TAG</p>
                    <Input
                      name="tag"
                      type="text"
                      value={inputValues.tag}
                      onChange={handleChangeInputValue}
                    />
                    <p id="withdraw_blu_message">
                      {currentWallet.Currency == "BLU" && t('smartwallet_address')}
                      {currentWallet.Currency == "BLU" && t('xago_address')}
                      <br />
                      {defaultWallets[currentWallet.Currency]}
                    </p>
                  </ContainerInput>
                ) : (
                  <ContainerInput>
                    <p>{t('withdraw_address')}</p>
                    <Input
                      name="address"
                      type="text"
                      value={inputValues.address}
                      onChange={handleChangeInputValue}
                      onBlur={() => checkWithdrawAddress()}
                    />
                  </ContainerInput>
                )}
                
                </>
              )
            }
            <ContainerInput inputValue>
              <div className={!inputValues.address ? 'disabled-input-withdraw' : ''}>
                <span className="span-input-currency">{currentWallet.Currency}</span>
                <Input
                  onChange={handleChangeInputValue}
                  value={inputValues.value}
                  autoComplete="off"
                  className="withdraw_value"
                  name="value"
                  type="text"
                  disabled={!inputValues.address && !conditionalWithdraw}
                />
              </div>
            </ContainerInput>
            <ContainerButtons>
              {[25, 50, 75, 100].map((value) => (
                <LightButton onClick={() => handleChangeInputValue({ target: { name: 'percent', value } })} key={value} selected={value === inputValues.percent}>
                  {`${value}%`}
                </LightButton>
              ))}
            </ContainerButtons>
            {checkingFee ? (
              <LoadingAnimation width={60} height={60} />
            ) : (
              <div className="withdraw_container_info">
                <p>{t('withdraw_amount_minimum_required')}</p>
                <p>
                  Min:
                  {' '}
                  {parseFloat(currencyFee?.fee)*2}
                </p>
                <p>
                  Fee:
                  {' '}
                  {currencyFee?.fee}
                </p>
                <p>
                  Total:
                  {' '}
                  {(parseFloat(currencyFee?.fee) + parseFloat(inputValues.value)) || currencyFee?.fee}
                </p>
              </div>
            )}
            <div style={{ color: '#00acde', marginTop: 10 }}>{t(message)}</div>
            <div style={{ color: 'red', marginTop: 10 }}>{t(error)}</div>
            <div className="footer-container-qr">
              <div style={{ width: 170 }} />
              {conditionalWithdraw ? (
                <Button disabled={(!inputValues.tag || !inputValues.value)} onClick={handlePressConfirm}>
                  {t('confirm')}
                </Button>
              ) : (
                <Button disabled={(!inputValues.address || !inputValues.value)} onClick={handlePressConfirm}>
                  {t('confirm')}
                </Button>
              )}
              {currentWallet?.Currency === 'RAS' ? (
                <div className="footer-qrcode-ras clickable" onClick={() => history.push('/ras/withdraw')}>
                  <p>{t('withdraw_ras_with_real')}</p>
                  <img alt="real deposit" src="/withdrawras.png" />
                </div>
              ) : (
                <div style={{ width: 170 }} />
              )}
            </div>
          </>
        )}
        {!loading && open2faInput && (
          <>
            <div className="withdraw_amount">
              <h2>
                {t('available_amount')}
              </h2>
              <h1>
                {currentWallet.Available}
              </h1>
            </div>
            <div className="withdraw_amount">
              <h2>
                {t('withdraw_amount')}
              </h2>
              <h1>
                {inputValues.value}
                {' '}
                {currentWallet.Currency}
              </h1>
            </div>
            <div className="withdraw_amount">
              <h2>
                {t('address')}
              </h2>
              <h1>
                {conditionalWithdraw ? `${defaultWallets[currentWallet.Currency]}${tagDiv}${inputValues.tag}` : `${inputValues.address}${tagDiv}${inputValues.tag}` }
              </h1>
            </div>
            {message ? (
              <div style={{ color: '#00acde', marginTop: 10 }}>{t(message)}</div>
            ) : (
              <ContainerInput>
                <p style={{ color: '#00acde', fontSize: 12 }}>
                  {t('withdraw_2fa_message')}
                </p>
                <Input
                  name="code2fa"
                  type="text"
                  value={inputValues.code2fa}
                  onChange={handleChangeInputValue}
                />
              </ContainerInput>
            )}

            <div className="withdraw_container_info">
              <p>Please note:</p>
              <p>
                -After making a withdrawal, you can track your progress on the history page.
              </p>
            </div>
            <div style={{ color: 'red', marginTop: 10 }}>{t(error)}</div>
          </>
        )}
      </div>
    </Container>
  );
}

export default WithdrawComponent;
