import React from 'react';
import Lottie from 'lottie-react-web';
// import { Container } from './styles';
import PropTypes from 'prop-types';
import loadingData from '~/assets/json/mazzera-loading.json';

export default function AnimationLoading({ height, width }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Lottie
      speed={0.5}
      width={width}
      height={height}
      options={defaultOptions}
      isStopped={false}
      isPaused={false}
    />
  );
}

AnimationLoading.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

AnimationLoading.defaultProps = {
  width: 500,
  height: 500,
};
