export function setHistory({ history }) {
  return { type: '@tradingView/setHistory', payload: { history } };
}

export function setSymbolConfig({ symbolConfig }) {
  return {
    type: '@tradingView/setSymbolConfig',
    payload: { symbolConfig },
  };
}

export function requestHistory() {
  return {
    type: '@tradingView/requestHistory',
  };
}

export function requestSymbolConfig({ symbol }) {
  return {
    type: '@tradingView/requestSymbolConfig',
    payload: { symbol },
  };
}
