import React from 'react';

import {
  Container,
  ContainerCryptoInfo,
} from './styles';

import { HeaderBook } from '~/components/headers';
import { WalletConfirmWithdraw } from '~/components/wallet';

export default function WalletPage() {
  return (
    <>
      <HeaderBook />
      <Container>
        <ContainerCryptoInfo>
          <WalletConfirmWithdraw />
        </ContainerCryptoInfo>
      </Container>
    </>
  );
}
