import styled from 'styled-components';

export const SignButtons = styled.div`
  flex-direction: row;
  align-items: center;

  & .button-signin {
    margin-right: 10px;
    background: linear-gradient(90deg, rgba(49,241,182,1) 0%, rgba(21,190,212,1) 35%, rgb(63 115 252) 100%);
    color: ${({ theme }) => theme.content_bg_color};
  }

  & .button-register {
    background-color: ${({ theme }) => theme.register_color};
    color: ${({ theme }) => theme.content_bg_color};
  }
`;

export const Container = styled.div`
  height: 80px;
  min-height: 80px;
  min-height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  & .stratumx-icon {
    width: 120px;
    vertical-align: middle;
    border-style: none;
    width: 100%;
    height: auto;
    max-width: 100px;
  }

  & .container-header-wallet-icon {
    flex-direction: row;
    align-items: center;
  }

  & .wallet-button {
    cursor: pointer;
    border-radius: 30px;
    padding: 20px;
    background-color: var(--content-light-bg-color);
    height: 60px;
    text-align: right;
    flex: 0;
    z-index: 13;
  }

  & .wallet-container-values.wallet-hide {
    display: none;
  }

  & .wallet-container-values {
    position: absolute;
    z-index: 2;
    right: 85%;
    top: 0px;
    width: 200px;
    height: 100%;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    background-color: ${({ theme }) => theme.default_bg_color};
    flex-direction: row;
    padding: 10px;
    padding-left: 15px;
    align-items: center;
  }

  & .wallet-container-values span {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    & p {
      font-size: 10px;
    }
  }

  & .wallet-content {
    flex: 1;
  }
  & .wallet-content div {
    width: 100%;
    font-size: 10px !important;
    flex-direction: row;
    padding: 0px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 20px;
  }

  & .wallet-content div p {
    font-size: 10px;
  }

  & .wallet-available {
    font-weight: bold;
  }

  & .wallet-available p:last-child {
    color: ${({ theme }) => theme.market_color};
  }

  @media (min-width: 400px) {
    & .stratumx-icon {
      width: 200px;
    }

    & button {
      width: 80px;
    }
  }
  @media (min-width: 500px) {
    & button {
      width: 120px;
    }
  }
  @media (min-width: 600px) {
    & button {
      width: 150px;
    }
    & .wallet-container-values.wallet-hide {
      display: flex;
    }
  }
  @media (min-width: 1100px) {
    & .container-header-wallet-icon > .stratumx-icon {
      display: none;
    }
    & {
      min-width: 300px;
    }
  }
`;
