import styled from 'styled-components';

export const Container = styled.section`
  display:block;
  width:100%;

  & .show-when-larger,
  & .hide-when-small {
    display: none;
  }

  & .container-header-informations {
    display: block;
    width: 100%;
  }

  & .container-price-currency {
    display: inline-block;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (min-width: 600px) {
    & .container-price-currency {
      display: flex;
      flex-direction: row;
    }
    & .container-price-currency > :nth-child(1) {
      margin-right: 10px;
    }
    & .container-buy-trades-spread {
      flex-direction: row;
    }
    & .container-buy-trades-spread .container-buy-trades {
      margin-right: 10px;
      width: 100%;
    }
  }
  @media (min-width: 1100px) {
    & .show-when-larger {
      display: flex;
    }

    & .hide-when-larger {
      display: none;
    }

    & .container-price-currency {
      display: flex;
      flex-direction: row-reverse;
      padding-left: 0px;
      padding-right: 0px;
    }

    & .container-price-currency > :nth-child(1) {
      margin-right: 0px;
    }

    & .container-buy-trades-spread .container-buy-trades {
      margin-right: 0px;
    }
  }
`;
