import React from 'react';

import {
  Container,
  ContainerCryptoInfo,
} from './styles';

import SwapInfo from '~/components/swap/Info';
import SwapHistory from '~/components/swap/History';

function SwapPage() {
  return (
    <Container>
      <ContainerCryptoInfo>
        <SwapInfo />
        <SwapHistory receivedCurrentWallet="RAS" />
      </ContainerCryptoInfo>
    </Container>
  );
}

export default SwapPage;
