import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;

  & .hide {
    width:80px;
    display:none;
  }

  & .menu > svg {
    align-self: center;
  }

  & .menu {
    height: 100vh;
    padding: 15px 0 15px 0;
    ${({ noShow }) => (noShow ? 'width:0px!important;overflow:hidden;' : '')};
    overflow-x: scroll;
  }

  & .menu li {
    align-items: center;
    padding: 7px 5px 7px 5px;
    position: relative;
    &.new {
      & .newItem{
        cursor: pointer;
        content: ' ';
        display: block;
        background: #f7921e;
        position: absolute;
        z-index: 30;
        right: 16px;
        border-radius: 50px;
        /* padding: 12px; */
        width: 14px;
        height: 14px;
        text-align: center;
        span{
          color: #f7921e;
          cursor: pointer;

        }
      }
    }
    & .title{
      font-size:11px;
    }
    & .item {
      background-color: var(--default-bg-color);
      width: 35px;
      height: 35px;
      min-height: 35px;
      border-radius: 35px;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin: 5px;
      
    }
  }

  & .menu li a.disabled, .menu li a.disabled svg{
    opacity:0.3;
    fill: #fff!important;
  }

  .stroke {
    transition: 0s;
  }

  & .menu li a:hover .fill, .menu-item-active .fill {
    fill: var(--primary-color) !important;
  }

  & .menu li a:hover  {
    & path {
      fill: var(--primary-color) !important;
    }
   
  }

  & .menu li a:hover .stroke, .menu-item-active .stroke {
    stroke: var(--primary-color);
    transition: 0s;
  }

  & .menu-container-content {
    height: 100vh;
    width: 100vw;
    overflow: auto;
  }

  & .menu-bg {
    background-color: #12111c;
  }

  @media (min-width:500px){
    & .hide{
      width:80px;
      display:flex;
    }
  }

  @media (max-width: 500px) {
    & .menu {
      position: absolute;
      z-index:200;
    }
  }
`;
