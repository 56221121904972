import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';
import { dateFormat } from '~/utils/format';
import { LoadingAnimation } from '~/components/animation';
import * as DepositActions from '~/store/modules/deposit/actions';

function WithdrawHistoryComponent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { deposits, loadingDeposits } = useSelector((state) => state.deposit);
  const currentWallet = useSelector((state) => state.wallet.currentWallet);

  React.useEffect(() => {
    dispatch(DepositActions.requestDeposits({ currency: currentWallet?.Currency }));
  }, [dispatch, currentWallet]);

  if (loadingDeposits) {
    return (
      <Container style={{ justifyContent: 'center' }} title={t('deposit_history')}>
        <LoadingAnimation width={100} height={100} />
      </Container>
    );
  }
  return (
    <Container title={t('deposit_history')}>
      <header>
        <h1>{t('deposit_history')}</h1>
      </header>
      <div className="history-item header-history">
        <p>{t('address')}</p>
        <p>{t('amount')}</p>
        <p>{t('date')}</p>
        <p>Txid</p>
        <p>Status</p>
      </div>
      {deposits.map((deposit) => (
        <div key={deposit.txId} className="history-item">
          <p>{deposit.address}</p>
          <p>{deposit.amount}</p>
          <p>{dateFormat({ date: deposit.createdAt })}</p>
          <p className="history_tolltip">
              {deposit.txId}
          </p>
          <p>{deposit.status}</p>
        </div>
      ))}
    </Container>
  );
}

export default WithdrawHistoryComponent;
