export function requestTrades({ pairId }) {
  return { type: '@trade/requestTrades', payload: { pairId } };
}

export function responseTrades({ success, trades, error }) {
  return { type: '@trade/responseTrades', payload: { success, trades, error } };
}

export function addNewTrade({ trade }) {
  return { type: '@trade/addNewTrade', payload: { trade } };
}
