import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
// import pt from "date-fns/locales/pt";

export function formatPrice({ number }) {
  return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'USD' }).format(number);
}

export function dateFormat({ date, format: stringFormat }) {
  const isoDate = parseISO(date);
  const zonedDate = utcToZonedTime(isoDate, 'Europe/Lisbon');
  return format(zonedDate, stringFormat || 'yyyy-MM-dd HH:mm:ss');
}
