import styled from 'styled-components';



export const Container = styled.section`
  width: 100%;
  display: block;
  background: #0b0a16;
  margin-top: 6px;
`;

export const ContainerBuySel = styled.div`
 @media (min-width: 200px) {
    width: 100%;
    margin:0;
  }
  @media (min-width: 1100px) {
    width: 30%;
    margin-left: 1%;
    margin-right: 1%;
  }
 
 
  height:406px;
  max-height: 406px;
  display: inline-flex;
  & .bg-content{
    margin-top:0;
    height: 406px;
    background:transparent;
    border: solid;
    border-radius: 25px;
    border-color: #25eaa3;
    
  }
`

export const ContainerHistory = styled.div`
  padding: 15px;
  background-color: ${({ theme }) => theme.content_bg_color};
  
  h2{
    text-align:center;
    span{
      color:#f7921e;
      cursor: pointer;
    }
  }
  @media (min-width: 200px) {
    width: 100%;
    margin:0;
  }
  @media (min-width: 1100px) {
    width: 33%;
    margin-left: 1%;
    margin-right: 0;
    &:first-child{
      margin-left:1%;
      margin-right: 1%;
    }
  }
 
  height:400px;
  max-height: 400px;
  overflow: auto;
  display: inline-flex;
  & h1 {
    font-size: 15px;
    align-self: center;
    text-transform:capitalize;
  }
 

  & .trade-item {
    min-height: 30px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.default_bg_color};
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    cursor:pointer;
   
  }
  & .trade-item p {
    width: 27.33%;
    text-align: center;
    justify-content: flex-end;
    text-align:right;
  }
  & .trade-item p:nth-child(1) {
    text-align: left;
    margin-left: 5px;
  }
  & .trade-item p:nth-child(3) {
    text-align: right;
    margin-right: 5px;
  }

  & .history-header {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    min-height: 30px;
  }

  & .history-header h1 {
    font-size: 12px;
    width: 33.33%;
    text-align: center;
  }
  & .history-header h1:nth-child(1) {
    text-align: left;
    padding-left: 15px;
  }
  & .history-header h1:nth-child(3) {
    text-align: right;
    padding-right: 12px;
  }
`;

export const MiddleContent = styled.div`
  width: 100%;
  min-height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & span {
    flex: 1;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    background-color: #fff;
  }

  & h1 {
    background-color: ${({ theme }) => theme.content_bg_color};
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
