import styled from 'styled-components';

export const Container = styled.section`
  height:100vh;
  display:block;

  & .left-container{
    margin-left:-1px;
    margin-top:-2px;
    z-index:1;
    display:block;
  }

  & .container-boxes{
    display:block;
    padding-bottom:50px;
  }

  & .box-larger h1{
    margin-bottom:20px;
  }

  & .container-boxes p{
    margin-top:12px;
    margin-bottom:12px;
    font-size: 18px;
    padding: 10px;
  }

  & .box-larger{
    display:block;
    /* width:60vw; */
    width:100%;
    border:solid 1px #174A71;
    padding:20px;
    border-top:0px;
    /* max-width:700px; */
  }
  & .box-right{
    display:block;
    /* width:40vw; */
    width:100%;
    border:none;
  }
  & .box-right svg{
    align-self:center;
    width:100%;
  }
  & .box-right h2{
    width:100%;
    text-align:center;
    font-size:20px;
    margin-top:10px;
    color: ${({ theme }) => theme.primary_color2};
    align-self:center;
  }
  .inline{
    display:flex;
    flex-direction:row;
    /* height:30px; */
    width:100%;
    margin-top:20px;
    align-items:center;
    justify-content:flex-start;
    flex-wrap:wrap;
  }
  & .inline .divisor {
    width:40px;
  }
  & .inline a{
    color: ${({ theme }) => theme.primary_color2};
  }
  & .inline p{
    width:120px;
    text-align:center;
  }

   @media(min-width:400px){
    .inline{
      display:flex;
      flex-direction:row;
    }
  }

   /* @media(min-width:1100px){
    .box-right{
      display:flex;
      align-items:center;
      width:40vw;
    }
    .box-larger{
      width:60vw;
    }
    .container-boxes{
      display:flex;
      flex-direction:row;
    } */
  }
`;
