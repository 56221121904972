import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import themes from './themes';

export default function ThemeChange({ children }) {
  const { themeName } = useSelector((state) => state.general);
  return (
    <ThemeProvider theme={themes[themeName]}>
      {children}
    </ThemeProvider>
  );
}

ThemeChange.propTypes = {
  children: PropTypes.element.isRequired,
};
