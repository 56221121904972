// ex. RAS
export function requestAddress({ currency }) {
  return { type: '@deposit/requestAddress', payload: { currency } };
}

export function responseAddress({ success, error, address }) {
  return { type: '@deposit/responseAddress', payload: { success, error, address } };
}

export function requestDeposits({ currency }) {
  return { type: '@deposit/requestDeposits', payload: { currency } };
}

export function responseDeposits({ success, deposits, error }) {
  return { type: '@deposit/responseDeposits', payload: { success, deposits, error } };
}

export function requestCreateNewAddress({ currency }) {
  return { type: '@deposit/requestCreateNewAddress', payload: { currency } };
}

export function responseCreateNewAddress({ success, error }) {
  return { type: '@deposit/responseCreateNewAddress', payload: { success, error } };
}
