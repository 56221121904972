import {
  call, put, take, select, delay, takeLatest, all,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as socketActions from './actions';
import * as generalActions from '../general/actions';
import endpoints from '~/config/endpoints';

let io = null;

/**
 * COMMANDOS
 * Todos os comandos estão em config/environments
 */
io = new WebSocket(endpoints.socket);

/**
* envia todos os comandos do socket, caso não esteja aberto a conexão aguarda 2 segundos e reexecuta
*
*/
export function* send(action) {
  if (io.readyState === WebSocket.OPEN) {
    const { data } = action.payload;
    if (typeof data !== 'string') {
      // const toSend = { type: 'subscribe', product_ids: ['BTC-RAS'], channels: ['candles', 'match', 'level2', 'order'], token: '' };
      const dataToSend = JSON.stringify(data);
      io.send(dataToSend);
    }
  } else {
    yield delay(2000);
    yield put(socketActions.send(action.payload));
  }
}
export function* reconnect() {
  const { token, customer } = yield select((state) => state.auth);
  if (token) {
    const action = {
      payload: {
        cmd: 23,
        data: token,
      },
    };
    yield call(send, action);
  }
  if (customer && customer.Nickname) {
    console.log('navegar até o login');
  }
}

// Todos os sockets passam por aqui, define que modulo deverá tratar a resposta
function* checkMessageReceived(message) {
  try {
    if (message.data && typeof message.data === 'string') {
      const messageObject = JSON.parse(message.data);
      yield put(socketActions.response({ data: messageObject }));
    }
  } catch (error) {
    console.log('error check data', error);
  }
}

function listenChannel() {
  return eventChannel((emitter) => {
    io.onopen = (d) => {
      // console.log('connection open', d);
      emitter({ ...d, code: 1001 });
    };
    io.onmessage = (d) => {
      // console.log('message', d);
      emitter(d);
    };
    io.onerror = (d) => {
      // console.log('connection error');
      emitter({ ...d, code: 1001 });
    };
    io.onclose = (d) => {
      // console.log('connection close');
      emitter({ ...d, code: 1001 });
    };
    return () => {
      // clearInterval(key);
    };
  });
}

export function* startListen() {
  yield put(generalActions.listenCurrentTabFocus());
  const channel = yield call(listenChannel);
  // fica aguardando receber uma nova ação do socket
  while (true) {
    const data = yield take(channel);
    yield call(checkMessageReceived, data);
  }
}

export default all([
  // takeEvery('@socket/response', listenSocketResponse),
  takeLatest('@socket/startListen', startListen),
  takeLatest('@socket/send', send),
]);

/**
 * UPDATE TRADE
 *
 * changes: [["buy", "10", "0.0335"]]
 * 0: ["buy", "10", "0.0335"]
 * productId: "BTC-RAS"
 * type: "l2update"
 *
 *
 */
